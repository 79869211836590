import "./home.css";
import Header from "../../atoms/NewHeader";
import HommePages from "../../components/HomePage";

const Home = () => {
  return (
    <>
      <div
        className="AppDiv"
        style={{
          backgroundImage: `url("/Images/Top Page With Girl.jpg" `,
        }}
      >
        <Header />
      </div>
      <HommePages />
    </>
  );
};

export default Home;
