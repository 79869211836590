import React from 'react'
import {
	Stepper,
	Step,
	StepLabel,
	StepContent,
	Typography,
	makeStyles
} from '@material-ui/core'
import StepConnector from '@material-ui/core/StepConnector';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	stepperContainer: {
		display: 'block',
		padding: '0',
		paddingTop: '24px',
		//background: '#f8f8f8',
		paddingLeft: ' 24px',
		paddingRight: ' 24px',
	},
	circle: {
		width: 20,
		border: '4px solid white',
		height: 20,
		borderRadius: 60,
		background: theme.palette.grey[300],
		boxShadow: '0px 0px 3px 0px'
	},
	completedCircle: {
		width: 20,
		border: '4px solid white',
		height: 20,
		borderRadius: 60,
		background: "#1B154E ",
		boxShadow: '0px 0px 3px 0px',
		cursor: 'pointer'
	}
}))

const QontoConnector = withStyles({
	active: {
		'& $line': {
			borderColor: '#1B154E ',
		},
	},
	completed: {
		'& $line': {
			borderColor: '#1B154E ',
		},
	},
	line: {
		height: '27px',
		'@media (max-width:350px)': {
			height: '26px',
			marginTop: -10,
		},
		borderWidth: 3,
		marginTop: -7,
		marginBottom: -7,
		marginLeft: -3,
		borderRadius: 1,
	},
})(StepConnector);

const CStepper = (props) => {
	const {
		steps = [],
		activeStep = 0,
		handleSteps,
		style
	} = props
	const classes = useStyles()

	const handleStep = (step) => {
		if (handleSteps) {
			handleSteps(step)
		}
	};

	const QontoStepIcon = (props) => {
		const { completed, icon } = props
		return (
			<div onClick={() => completed && handleStep(icon)}
				className={classes.active}
			>
				<div className={completed ? classes.completedCircle : classes.circle} />
			</div>
		);
	}

	return (
		<Stepper activeStep={activeStep} orientation="vertical" className={classes.stepperContainer + ' ' + style} connector={<QontoConnector />}>
			{steps.map((label, index) => (
				<Step key={index}>
					<StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
					<StepContent >
						<Typography>{label}</Typography>
					</StepContent>

				</Step>
			))}
		</Stepper>
	)
}

export default CStepper
