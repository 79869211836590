import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
import { CalendarToday, Phone } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";

import CImage from "./CImage";
import firebase from "../config/Firebase";

const useStyles = makeStyles((theme) => ({
  containerdata: {
    padding: "0px 30px",

    "@media (min-width:768px)": {
      padding: "0px 11px",
    },
    "@media (min-width:1024px)": {
      padding: "10px 20px",
    },
    "@media (min-width:1366px)": {
      padding: "0px 50px",
    },
    "@media (min-width:1920px)": {
      padding: "0px 40px",
      paddingLeft: 150,
    },

    "& .header": {
      height: 100,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      color: "#fff",
      zIndex: 1,
      position: "relative",
      "& a": {
        textDecoration: "none !important",
      },
      "& img": {
        height: 58,
        "@media (max-width:600px)": {
          height: 45,
        },
      },
      "& h1": {
        fontWeight: 700,
      },
      "& button": {
        padding: "12px 30px",
        fontSize: 18,
        background: "#1c1451",
        color: "#fff",
        fontWeight: 500,
        borderRadius: 10,
        marginTop: 25,
        border: "none",
        alignItems: "center",
        display: "flex",
        "@media (max-width:768px)": {
          padding: "6px 6px",
          fontSize: 13,
          position: "absolute",
          right: 0,
          top: 0,
        },
        "@media (min-width:1920px)": {
          fontSize: 27,
        },
      },
      "& .Login": {
        display: "none",
        "@media (max-width:600px)": {
          display: "inline-block",
          color: "#1c1451",
          fontWeight: 700,
          marginTop: 20,
          fontSize: 18,
        },
        "@media (max-width:320px)": {
          marginRight: "-5px",
        },
      },
      "& .LoginBtn": {
        "@media (max-width:600px)": {
          display: "none",
        },
      },
      "@media (max-width:600px)": {
        height: "61px",
      },
    },
    "& .content": {
      height: 350,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      color: "#fff",
      marginTop: "0rem !important",
      "& .headerLine": {
        marginTop: "190px",

        "@media (max-width:768px)": {
          marginTop: "0px",
        },
        "@media (max-width:600px)": {
          marginTop: "13vh",
        },
      },
      "@media (max-width:600px)": {
        justifyContent: "start",
        marginTop: 70,
      },
      "@media (max-width:1024px)": {
        height: 500,
      },

      "& h1": {
        fontSize: 64,
        fontWeight: 400,
        "@media (max-width:768px)": {
          fontSize: 42,
        },
        "@media (max-width:600px)": {
          paddingTop: "30px",
          fontSize: 19,
          fontWeight: 600,
        },
        "@media (min-width:1920px)": {
          fontSize: 85,
          marginTop: 170,
        },
      },
      "& button": {
        padding: "12px 30px",
        marginRight: 0,
        fontSize: "21px",
        border: "none",
        borderRadius: 10,
        alignItems: "center",
        display: "flex",
        "@media (max-width:1024px)": {
          padding: "12px 15px",
          fontSize: "17px",
          marginTop: "0 !important",
        },
        "@media (max-width:767px)": {
          fontSize: 15,
          padding: "8px 10px",
        },
        "@media (max-width:600px)": {
          fontSize: 11,
          padding: "6px 10px",
          display: "flex",
          borderRadius: 6,
          marginTop: "0rem !important",
        },
        "@media (min-width:1920px)": {
          fontSize: 30,
        },
      },
      "& #GetACall": {
        alignItems: "center",
        "@media (max-width:600px)": {
          marginTop: "0 !important",
        },
      },
    },
  },
  linkCss: {
    textDecoration: "none !important",
    marginTop: "1.5rem",
    marginRight: 10,
    "@media (max-width: 767px)": {
      marginTop: 15,
    },
  },
  userIcon: {
    marginTop: 35,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    "& img": {
      height: "47px !important",
      width: 60,
      cursor: "pointer",
    },
    "@media (max-width:1024px)": {
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      marginTop: "0px",
      padding: "10px",
      borderBottom: "1px solid #eee",
      "& img": {
        marginRight: 10,
        width: "auto",
      },
    },
  },
  logoutIon: {
    marginTop: "0",
    cursor: "pointer",
    color: "black",
    fontSize: "35px",
    height: 47,
    "@media (max-width:1024px)": {
      marginTop: "0px",
    },
  },
  userIcons: {
    "@media (max-width:1024px)": {
      marginTop: "-16px",
      marginRight: "0px",
      display: "none",
    },
  },
  iconeText: {
    color: "#000",
    cursor: "pointer",
    textAlign: "center",
    "@media (max-width:1024px)": {
      fontSize: 12,
    },
  },
  iconeTextLogout: {
    color: "#000",
    marginTop: 0,
    // marginLeft: 18,
    cursor: "pointer",
    "@media (max-width:1024px)": {
      fontSize: 12,
      marginTop: 0,
    },
  },
  logoutBox: {
    textAlign: "center",
    marginLeft: "20px",
    cursor: "pointer",
    marginTop: 35,
    "@media (max-width:1024px)": {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      marginLeft: 0,
      justifyContent: "flex-start",
      textAlign: "left",
      padding: "10px",
      marginTop: 0,
      "& svg": {
        marginRight: 10,
      },
    },
  },
  icon1: {
    marginRight: "4px",
    "@media (max-width:767px)": {
      width: 18,
      height: 18,
    },
  },
  icon2: {
    marginRight: "4px",
    "@media (max-width:767px)": {
      width: 18,
      height: 18,
    },
  },
  buttonContainer: {
    display: "flex",
    "@media (max-width:767px)": {
      flexDirection: "column",
      "& a": {
        width: 150,
        "& button": {
          width: "100%",
        },
      },
    },
  },
  humburgurIcon: {
    display: "none",
    "@media (max-width:1024px)": {
      display: "flex",
      color: "#1e1b50",
      float: "right",
      marginTop: 10,
      position: "relative",
      right: -19,
      alignItems: "center",
      height: 61,
      "& svg": {
        height: 28,
        width: 28,
      },
    },
  },
  userIconsOpen: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    background: "#fff",
    width: 200,
    alignItems: "flex-start",
    top: 72,
    right: -19,
    borderRadius: 3,
    zIndex: 9,
    "@media (min-width: 768px) and (max-width: 1024px)": {
      top: 90,
    },
    "&::before": {
      content: `''`,
      position: "absolute",
      right: 8,
      borderBottom: "7px solid #fff",
      borderLeft: "7px solid transparent",
      borderRight: "7px solid transparent",
      top: -7,
    },
  },
}));

const Header = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const [records, setRecords] = useState(null);
  const [, setNumber] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const login = localStorage.getItem("user");
    const phoneNumber = localStorage.getItem("phoneNumber");
    setRecords(login);
    setNumber(phoneNumber);
  }, []);

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.clear();
        setRecords(null);
        setNumber(null);
        history.push("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const manageAppointment = () => {
    history.push("/manage-appointment");
  };
  const openDropDown = () => {
    setOpen(!open);
  };
  return (
    <div className={classes.containerdata}>
      <div className="header">
        <Link to="/">
          <img src="./Images/logo 2.png" alt="logo" />
        </Link>

        {records ? (
          <>
            <span className={classes.humburgurIcon} onClick={openDropDown}>
              <MenuIcon />
            </span>
            <Box
              className={`${classes.userIcons} ${
                open ? classes.userIconsOpen : null
              }`}
              display="flex"
              justifyContent="flex-end"
              mt={-1}
            >
              <Box className={classes.userIcon} onClick={manageAppointment}>
                <CImage
                  imgSrc={"./assets/images/user.svg"}
                  style={classes.icon}
                />
                <Typography className={classes.iconeText}>
                  My Bookings
                </Typography>
              </Box>
              <Box className={classes.logoutBox}>
                <ExitToAppIcon className={classes.logoutIon} onClick={logout} />
                <Typography className={classes.iconeTextLogout}>
                  Logout
                </Typography>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Link to="/login">
              <button className="LoginBtn">Log in/Register</button>
              <p className="Login">Login</p>
            </Link>
          </>
        )}
      </div>
      <div className="content mt-5">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 headerLine">
            <h1>We Buy Your Car</h1>
            <div className={classes.buttonContainer}>
              <Link to="/support" className={classes.linkCss}>
                <button id="GetACall">
                  <Phone className={classes.icon1} /> Get a Call
                </button>
              </Link>

              <Link to="/booking" className={`${classes.linkCss} linktag`}>
                <button className={classes.buttons}>
                  <CalendarToday className={classes.icon2} /> Book Appointment
                </button>
              </Link>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6"></div>
        </div>
      </div>
    </div>
  );
};

export default Header;
