import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import LocationOnIcon from '@material-ui/icons/LocationOn';

const useStyles = makeStyles((theme) => ({
	root: {
		flexWrap: "wrap"
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: 0,
		marginBottom: -1
	},
	resize: {
		fontSize: 12,
	},
	buttonNewLetter: {
		color: "#1B154E "
	}
}));

const CustomInput = (props) => {
	const classes = useStyles();
	const { name, label, image, defaultValue, onChange, error, helperText, onKeyUp } = props;

	if (name !== "leadID")
		return (
			<div className={classes.root}>
				<div>
					<TextField
						error={error}
						helperText={helperText}
						display="flex"
						label={label}
						flexdirection="row"
						id="standard-full-width"
						fullWidth
						className={classes.textField}
						variant="standard"
						value={defaultValue}
						onChange={onChange}
						margin="normal"
						inputProps={{ style: { fontSize: 14, color: '#000000' } }} // font size of input text
						InputLabelProps={{ style: { fontSize: 11, color: '#1B154E ', fontFamily: 'Montserrat, SemiBold' } }} // font size of input label
						InputProps={{
							endAdornment: (
								<InputAdornment position="end" >
									{image ?
										<LocationOnIcon className={classes.buttonNewLetter} /> :
										null
									}
								</InputAdornment>
							)
						}}
						onKeyUp={onKeyUp}
					/>
				</div>
			</div>
		);
	else return <></>;
};
export default CustomInput;
