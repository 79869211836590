import { Box, Typography } from "@material-ui/core";
import React from "react";
import { KeyboardArrowLeft } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import CustomHeader from '../CustomHeader';

const useStyles = makeStyles((theme) => ({
	headingText: {
		color: "#1B154E ",
		marginLeft: 8,
		margin: "auto",
		position: "relative",
		fontSize: 20,
		"@media (max-width:600px)": {
			fontSize: 18,
		},
		"@media (max-width:320px)": {
			fontSize: 15,
			marginLeft: 0,
		},
		"&::before": {
			content: '""',
			width: "200px",
			height: "3px",
			background: "#FFD13B",
			position: "absolute",
			left: 25,
			bottom: "0",
			"@media (max-width:767px)": {
				width: "185px",
			},
		},
	},
	privacyPolicyBox: {
		width: "100%",
		height: "59vh",
		background: "transparent",
		boxShadow: "inherit",
		backgroundColor: "#F8F8F8",
	},
	listContainer: {
		// float: "left",
		height: "100%",
		overflow: "auto",
		paddingLeft: "20px",
		paddingTop: "30px",
		paddingRight: "20px",
		wordBreak: 'break-word'
	},
	head: {
		color: "#1B154E ",
		fontSize: 24,
		fontWeight: "500",
		marginBottom: 16,
		marginTop: 16,
	},
	content: {
		color: "#1B154E ",
		fontSize: 16,
		fontWeight: "500",
	},
	innerContent: {
		color: "#1B154E ",
		fontSize: 14,
		fontWeight: "500",
		marginTop: 12,
	},
	arrowIcon: {
		"&:hover": {
			color: "#0d6efd",
		},
	},
	policyContainer: {
		'@media (max-width: 320px)': {
			padding: '20px 14px'
		}
	},
	logoImg: {
		'@media (max-width: 767px)': {
			marginLeft: '17px'
		}
	}
}));

const PrivacyPolicy = () => {
	const theme = useTheme();
	const classes = useStyles(theme);
	const history = useHistory();

	return (
		<>
			<CustomHeader />
			<Box p={5} className={classes.policyContainer}>
				<Box mt={2} mb={2}>
					<Typography variant="h6" className={classes.headingText}>
						<Link to="/" className="linktag">
							<KeyboardArrowLeft
								className={classes.arrowIcon}
								onClick={() => {
									history.push("/");
								}}
								style={{ marginTop: "-3px", cursor: "pointer" }}
							/>
							<span>Home </span>
						</Link>
            / Terms & Conditions
          </Typography>
				</Box>
				<Box className={classes.privacyPolicyBox}>
					<Box className={classes.listContainer}>
						<Typography>
							<Typography>
								Carboli India Private Limited (the “Company”, “we”, “us” or
								“our”) owns and operates a website
								i.e. https://www.carboli.com including its mobile applications
								and Google assistant bot (the “Website”) that allows users to
								sell pre-owned cars. These terms and conditions (“Terms”) shall
								govern the use or access to the Website and Services (as defined
								below). These Terms constitute a binding and enforceable legal
								contract between the Company and the user of the website (“you,
								your or user”).
              </Typography>
							<Typography>
								Capitalised terms, unless defined herein, shall have the meaning
                ascribed to them under the Privacy Policy.{" "}
							</Typography>
							<Typography>
								No information provided on the Website shall be considered a
								substitute for your independent investigation. These Terms are
								collectively an electronic record for the purpose of the
								Information Technology Act, 2000 (“IT Act”) and the rules made
								thereunder. These Terms do not require any digital or electronic
								signature. You must not modify the paper or digital copies of
								any materials you have printed, or downloaded from, our Websites
								in any way, and you must not use any illustrations, photographs,
								video or audio sequences or any graphics separately from any
								accompanying text.
              </Typography>
							<Typography>
								By using our Website, you confirm that you accept these Terms
								and that you agree to comply with them. If you do not agree to
								the Terms, you must not access this Website
              </Typography>
						</Typography>
						<Typography className={classes.head}>
							Who are we and what do we do?
            </Typography>
						<Typography>
							<Typography>
								The Company is a private limited company incorporated under the
								Companies Act, 2013 and is engaged in the business of trading in
								pre-owned vehicle(s)and providing services incidental and
								ancillary thereto.
              </Typography>
							<Typography>
								The Website is owned and managed by the Company. Please read the
								Terms to acquaint yourself of the practices of the Company, with
								regard to your use of the domain and subdomains of our Website,
								which includes only the domains and subdomains being operated by
								the Company and does not include the domains or subdomains
								licensed out by the Company. It is acknowledged by you that upon
								being redirected to, or opening a domain which is not owned or
								operated by the Company, you are bound by the terms of use of
								that third party domain or subdomain irrespective of whether it
								is licensed by the Company to a third party.
              </Typography>
							<Typography>
								We endeavor to make the process of trading in pre-owned
								vehicle(s) an amazing and hassle-free experience for our
								customers. We hereby clarify that the Services mentioned on the
								Website are subject to availability. The online valuations of a
								vehicle provided by the Company are only prima facie quotations
								arrived on the basis of the information provided by you and are
								subject to change on further inspection of the vehicle. The
								Company reserves the right to modify, amend and/or alter the
								said Service(s) based on its sole discretion and no such
								Services shall be deemed to be any offer or acceptance by the
								Company unless the same are accepted by the Company separately
								in writing through its authorized representatives. For specific
								price information, terms applicable, program and product
								information, please contact our customer care helpline
								at 97950-97250 or e-mail us at info@carboli.com.
              </Typography>
						</Typography>
						<Typography className={classes.head}>
							Vehicle Valuation and Vehicle Purchase Services
            </Typography>
						<Typography>
							The Website allows you to book an appointment for the Company or
							its representatives to conduct a home inspection or a
							self-inspection by calling a representative of the Company or
							through the website (an ‘Appointment’). The actual sale price of
							the vehicle can only be evaluated and offered after a physical
							inspection of the vehicle by authorized evaluators of the Company.
							The Company always carries out a physical inspection of the
							vehicle before it conveys the intent to proceed with further
							transaction for arriving at a final sale price. Physical
							inspection of the vehicle involves a thorough inspection of the
							vehicle including all working components and features and which
							shall be the critical factors to arrive at a sale price of the
							vehicle, which may be offered by the Company accordingly
							(collectively, “Services”). Please note that ‘Services’ would
							include any other future services we may provide or propose to
							provide. You shall also be required to provide further details
							including the details of the Vehicle, which shall be done at the
							time of Appointment and before the carrying out of the physical
							inspection of the Vehicle. The price offered by the Company shall
							be non-negotiable and subject to change only at the Company’s
							discretion.
            </Typography>
						<Typography className={classes.head}>On-Boarding</Typography>
						<Typography>
							<Typography>
								a. To avail the Services, you would be required to create a
								profile/sign-up on the Website (“Profile”) using your email ID
								and phone number, among other details. You warrant that all
								information furnished in connection with your Profile is and
								shall remain accurate and true in all respects. You further
								agree and undertake to promptly update your details on the
								Website in the event of any change or modification of such
								details.
              </Typography>
							<Typography>
								b. You are solely responsible for maintaining the security and
								confidentiality of your username and password, and agree to
								immediately notify the Company in writing
								at info@carboli.com of any disclosure or unauthorized use of
								your Profile or any other breach of security with respect to
                your Profile.{" "}
							</Typography>
							<Typography>
								c. You expressly agree to be liable and accountable for all
								activities that take place through your Profile in furtherance
								of the use of the Website or the Service provided or otherwise.
								The Company expressly excludes any liability for any
								unauthorised access to your Profile.
              </Typography>
							<Typography>
								<Typography>
									d. You agree to receive communications from the Company
                  regarding:{" "}
								</Typography>
								<Typography>
									(i) information relating to transactions recorded on the
                  Website;{" "}
								</Typography>
								<Typography>(ii) requests for payment;</Typography>
								<Typography>
									(iii) information about the Company and the Services;{" "}
								</Typography>
								<Typography>
									(iv) promotional offers and services from the Company and its
									third party partners, and
                </Typography>
                (v) any other matter in relation to the Services.
              </Typography>
						</Typography>
						<Typography className={classes.head}>
							Third Party Services
            </Typography>
						<Typography>
							<Typography>
								a. The Services may include services, content, documents, and
								information owned by, licensed to, or otherwise made available
								by a third party (“Third Party Services”) or contain links to
								Third Party Services. You understand that Third Party Services
								are the responsibility of the third party that created or
								provided the services and acknowledge that use of such
								Third-Party Services is solely at your own risk.
              </Typography>
							<Typography>
								b. The Company makes no representations and hereby expressly
								excludes all warranties and liabilities arising out of or
								pertaining to such Third-Party Services, including the accuracy
								or completeness. Further, all intellectual property rights in
								and to Third Party Services are the property of the respective
								third parties.
              </Typography>
						</Typography>
						<Typography className={classes.head}>
							Limitation of Liability
            </Typography>
						<Typography>
							<Typography>
								The Company does not hold any liability for any occurrence of
								any mishap arising from your usage of our Services resulting in
								any financial, material or human damage. You understand and
								agree that Company shall not be liable to you for any direct,
								indirect, incidental, special, consequential or exemplary
								damages, including without limitation damages for loss of
								profits, goodwill, use, data or other intangible losses (even if
								Company had been advised of the possibility of such damages),
								resulting from or relating to the Websites or Mobile
								application, whether based on warranty, contract, tort, or any
								other legal theory.
              </Typography>
							<Typography>
								Notwithstanding anything to the contrary contained herein or
								elsewhere, Company’s total liability for any user’s claim which
								may arise out of availing our services through browsing
								websites/mobile app or elsewhere shall be limited up to the fees
								paid by such user at the time of availing the Services giving
								rise to such claim.
              </Typography>
							<Typography>
								All claims/complaints arising from and in connection with the
								use of our Services shall be promptly submitted or reported to
								the Company and or/ its Partners within thirty (30) days of the
								consumption of such Services. Any claim or complaint that is
								submitted / reported after the expiry of such 30 days may be
								rejected, and the claimant will forfeit the right to claim any
								damage, cost or compensation.
              </Typography>
						</Typography>
						<Typography className={classes.head}>
							TERM AND TERMINATION
            </Typography>
						<Typography>
							<Typography>
								a. hese Terms shall remain in effect unless terminated in
								accordance with the terms hereunder.
              </Typography>
							<Typography>
								b. The Company may terminate your access to or use of the
								Services, or any portion thereof, immediately and at any point,
								at its sole discretion, if the user violates or breaches any of
								its obligations, responsibilities, or covenants under these
								Terms.
              </Typography>
							<Typography>
								c. Upon termination these Terms shall terminate, except for
								those clauses that expressly or are intended to survive
								termination or expiry.
              </Typography>
							<Typography>
								d. Notwithstanding anything to the contrary contained in the
								Terms, upon termination of your access to or use of the
								Services, all amounts or outstanding monies due by you in
								relation to your use of or access to the Services shall become
								immediately payable.
              </Typography>
						</Typography>

						<Typography className={classes.head}>
							DISCLAIMERS AND WARRANTIES
            </Typography>
						<Typography>
							<Typography>
								a. The use of the Services is at your sole risk.
              </Typography>
							<Typography>
								b. To the extent permitted by applicable law, the Services are
								provided on an “as is” and “as available” basis. The Company
								does not warrant that operation of the Services will be
								uninterrupted or error free or that the functions contained in
								the Services will meet your requirements.
              </Typography>
							<Typography>
								c. To the fullest extent permissible under applicable law, the
								Company expressly disclaims all warranties of any kind, express
								or implied, arising out of the Services, including warranties of
								merchantability, fitness for a particular purpose, satisfactory
								quality, accuracy, title and non-infringement, compatibility,
								applicability, usability, appropriateness, and any warranty that
								may arise out of course of performance, course of dealing, or
								usage of trade.
              </Typography>
							<Typography>
								d. You hereby accept full responsibility for any consequences
								that may arise from your use of the Services, and expressly
								agree and acknowledge that the Company shall have absolutely no
								liability with respect to the same.
              </Typography>
							<Typography>
								<Typography>
									e. To the fullest extent permissible by law, the Company, its
									affiliates, and its related parties each disclaim all
									liability to you for any loss or damage arising out of or due
									to:
                </Typography>
								<Typography>
									i. your use of or inability to use, or availability or
									unavailability of the Services, including any Third Party
									Services;
                </Typography>
								<Typography>
									ii. the occurrence or existence of any defect, interruption,
									or delays in the operation or transmission of information to,
									from, or through the Services, communications failure, theft,
									destruction or unauthorised access to the Company’s records,
									programs, services, server, or other infrastructure relating
									to the Services; or
                </Typography>
                iii. the failure of the Services to remain operational for any
                period of time.
              </Typography>
						</Typography>
						<Typography>
							f. Notwithstanding anything to the contrary contained herein,
							neither the Company nor any of its affiliates or related parties
							shall have any liability to you or any third party for any
							indirect, incidental, special or consequential damages or any loss
							of revenue or profits arising under, directly or indirectly, or
							relating, in any manner whatsoever, to these Terms or the
							Services. To the maximum extent permitted by law, you agree to
							waive, release, discharge, and hold harmless the Company, its
							affiliated and subsidiary companies, its parent companies, and
							each of their directors, officers, employees, and agents, from any
							and all claims, losses, damages, liabilities, expenses and causes
							of action arising out of the Services.
            </Typography>
						<Typography className={classes.head}>INDEMNITY</Typography>
						<Typography>
							<Typography>
								You shall indemnify, defend at the Company’s option, and hold
								the Company, its parent companies, subsidiaries, affiliates, and
								their officers, associates successors, assigns, licensors,
								employees, directors, agents, and representatives, harmless from
								and against any claim, demand, lawsuits, judicial proceeding,
								losses, liabilities, damages and costs (including, without
								limitation, from all damages, liabilities, settlements, costs
								and attorneys’ fees) due to or arising out of your use of the
								Website or the Services provided, including any violation of
								these Terms or any infringement by you of any third party right
								or on account of any third party who may use your account with
								the Company.
              </Typography>
						</Typography>

						<Typography className={classes.head}>
							CONSENT TO USE DATA
            </Typography>
						<Typography>
							<Typography>
								a. You agree that the Company and any third-party service
								providers it engages, may, in accordance with its privacy
								policy, collect and use your information and technical data and
								related information.
              </Typography>
							<Typography>
								b. The Company may use information and data pertaining to your
								use of the Services for analytics, trends identification, and
								purposes of statistics to further enhance the effectiveness and
								efficiency of the Website and transfer the same to its group
								companies and service providers in furtherance of your access to
								these Services. You provide your consent to such use and sharing
								of your information.
              </Typography>
							<Typography>
								c. Subject to applicable laws, the Company may be directed by
								law enforcement agencies or the government and related bodies to
								disclose data in relation to users in connection with criminal
								proceedings. You understand and agree that in such instances,
								the Company shall have the right to share such data with
								relevant agencies or bodies.
              </Typography>
						</Typography>
						<Typography className={classes.head}>FEES/CHARGES</Typography>
						<Typography>
							The Company reserves the right to charge convenience fee for the
							Services and non-payment may result in denial of Services and/or
							action under applicable law.
            </Typography>
						<Typography className={classes.head}>MODIFICATION</Typography>

						<Typography>
							The Company reserves the right at any time to add, modify or
							discontinue, temporarily or permanently, the Services (or any part
							thereof) with or without cause. The Company shall not be liable
							for any such addition, modification, suspension or discontinuation
							of the Services.
            </Typography>
						<Typography className={classes.head}>
							JURISDICTION, GOVERNING LAWS, AND DISPUTE RESOLUTION
            </Typography>
						<Typography>
							<Typography>
								The Company reserves the right at any time to add, modify or
								discontinue, temporarily or permanently, the Services (or any
								part thereof) with or without cause. The Company shall not be
								liable for any such addition, modification, suspension or
								discontinuation of the Services.
              </Typography>
							<Typography>
								Any controversies, conflicts, disputes, or differences arising
								out of these Terms shall be resolved by arbitration in Delhi in
								accordance with the Arbitration and Conciliation Act, 1996 for
								the time being in force, which is deemed to be incorporated by
								reference in this Clause. The tribunal shall consist of 1 (one)
								arbitrator appointed by the Company. The language of the
								arbitration shall be English.
              </Typography>
							<Typography>
								The parties to the arbitration shall keep the arbitration
								confidential and not disclose to any person, other than on a
								need to basis or to legal advisors, unless required to do so by
								law. The decision of the arbitrator shall be final and binding
								on all the Parties hereto.
              </Typography>
							<Typography>
								Each party to the arbitration shall bear its own costs with
								respect to any dispute.
              </Typography>
						</Typography>

						<Typography className={classes.head}>
							MISCELLANEOUS PROVISIONS
            </Typography>
						<Typography>
							<Typography>
								a. Modification – The Company reserves the right at any time to
								modify these Terms and to add new or additional terms or
								conditions on use of the Services. Such modifications and
								additional terms and conditions will be communicated to you and,
								unless expressly rejected (in which these Terms shall
								terminate), will be effective immediately and will be
								incorporated into these Terms. In the event you refuse to accept
								such changes, these Terms will terminate.
              </Typography>
							<Typography>
								b. Severability - If any provision of these Terms is determined
								by any court or other competent authority to be unlawful or
								unenforceable, the other provisions of these Terms will continue
								in effect. If any unlawful or unenforceable provision would be
								lawful or enforceable if part of it were deleted, that part will
								be deemed to be deleted, and the rest of the provision will
								continue in effect (unless that would contradict the clear
								intention of the clause, in which case the entirety of the
								relevant provision will be deemed to be deleted).
              </Typography>
							<Typography>
								c. Assignment - You shall not license, sell, transfer or assign
								your rights, obligations, or covenants under these Terms in any
								manner without the Company’s prior written consent. The Company
								may grant or withhold this consent in its sole discretion and
								subject to any conditions it deems appropriate. The Company may
								assign its rights to any of its affiliates, subsidiaries, or
								parent companies, or to any successor in interest of any
								business associated with the Services without any prior notice
								to you.
              </Typography>
							<Typography>
								d. Notices - All notices, requests, demands, and determinations
								for the Company under these Terms (other than routine
								operational communications) shall be sent to Somesh Kumar
								at info@carboli.com
              </Typography>
							<Typography>
								e. Third Party Rights - No third party shall have any rights to
								enforce any terms contained herein.
              </Typography>
						</Typography>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default PrivacyPolicy;
