import React, { useState, useRef, useEffect } from "react";
import {
	Typography,
	Box,
	Card,
	makeStyles,
	useTheme,
	TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';

import CImage from "./CImage";
// import ViewAllImg from "../assets/images/View All Brands (3).png";

const useStyles = makeStyles((theme) => ({
	brandModule: {
		background: "transparent",
		textAlign: "center",
		width: "100%",
		border: "none",
		boxShadow: "none",
	},
	brancCard: {
		background: "transparent",
		width: "100%",
		backgroundColor: "#F8F8F8",
		boxShadow: "inherit",
	},
	brandImage: {
		width: "100px",
		height: " auto",
		'@media (max-width: 320px)': {
			width: 82,
		}
	},
	Image: {
		width: "100%",
		height: "auto",
	},
	brandContainer: {
		cursor: "pointer",
		maxWidth: "15%",
		minWidth: "15%",
		marginLeft: ".4vw",
		marginRight: ".4vw",
		display: "flex",
		flexDirection: "column",
		marginBottom: 0,
		paddingBottom: 16,
		paddingTop: 0,
		[theme.breakpoints.down("sm")]: {
			maxWidth: "46%",
			minWidth: "46%",
			marginLeft: "0px",
			marginRight: "0px",
			"& > div": {
				width: "100%",
				"@media (min-width:768px) and (max-width:1024px)": {
					height: "inherit",
				},
			},
			"& > p": {
				width: "100%",
			},
		},
	},
	brandLogoContainer: {
		background: theme.palette.grey[300],
		borderRadius: 5,
		width: "100%",
		display: "flex",
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		cursor: "pointer",
		minHeight: "120px",
		"&:hover": {
			backgroundColor: "#FFCF00",
			opacity: ".43",
		},
	},
	brandTitle: {
		color: "#1B154E ",
		fontSize: " 12px",
		fontWeight: "500",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		width: "100%",
	},
	listContainer: {
		overflow: "auto",
		height: '38vh',
		textAlign: (props) => (props.align ? "center" : "left"),
		float: "left",
		paddingLeft: "10px",
		width: "100%",
		marginBottom: 0,
		paddingBottom: 0,
		'@media (min-width: 1366px) and (max-width: 1500px)': {
			height: '46vh'
		},
		"& >div": {
			display: "flex",
			flexWrap: "wrap",
			width: "100%",
			justifyContent: "flex-start",
			[theme.breakpoints.down("sm")]: {
				display: "flex",
				flexWrap: "wrap",
				width: "100%",
				justifyContent: "space-around",
			},
		},
	},
	brandListTitle: {
		color: "#1B154E ",
		fontSize: "15px",
		fontWeight: "500",
	},
	searchIcon: {
		color: "#9B9B9B",
		marginRight: 8,
		"@media (max-width:300px)": {
			marginLeft: -8,
		},
	},
	searchTextBox: {
		"& .MuiOutlinedInput-input": {
			padding: "10px 14px 10px 0px",
			"@media (max-width:365px)": {
				padding: "10px 0px 10px 0px",
			},
		},
		"& .MuiInputBase-inputTypeSearch": {
			"&::placeholder": {
				"@media (max-width:365px)": {
					fontSize: 12,
				},
				"@media (max-width:300px)": {
					fontSize: 10,
				},
			},
		},
	},
	homeListContainer: {
		height: "auto",
		maxWidth: "930px",
		margin: "auto",
		float: "none",
		[theme.breakpoints.down("sm")]: {
			maxWidth: "100%",
		},
	},
	homeBox: {
		justifyContent: "space-around !important",
	},
	homeBrandContainer: {
		maxWidth: "15%",
		marginLeft: "0px",
		marginRight: "0px",
		minWidth: "15%",
		"& > div": {
			width: "100%",
		},
		"& > p": {
			width: "100%",
		},
		[theme.breakpoints.down("sm")]: {
			maxWidth: "46%",
			minWidth: "46%",
		},
	},
	noRecordFoundText: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		height: '15vh',
		alignItems: 'center',
		wordBreak: 'break-all',
		fontSize: 16,
		marginBottom: 0
	},
	autoCompleteSelectBox: {
		float: 'left',
		width: '100%',
		margin: '10px 0',
		padding: '10px 18px',
		'& label.Mui-focused': {
			backgroundColor: '#F8F8F8',
			top: -4,
			padding: '0 5px',
			zIndex: 9,
		}
	},
	removeContainerHeight: {
		height: 'auto !important'
	},
	viewAllBrandText: {
		marginTop: 18,
		fontSize: 22,
		"@media (max-width:1024px)": {
			fontSize: 20,
		},
		"@media (max-width:300px)": {
			fontSize: 16,
		},
	},
	othersText: {
		marginTop: 18,
		fontSize: 22,
		fontWeight:'800',
		"@media (max-width:1024px)": {
			fontSize: 20,
		},
		"@media (max-width:300px)": {
			fontSize: 16,
		},
	}
}));

const CarBrands = (props) => {
	const {
		handleClick,
		brandList = [],
		limit = Object.keys(brandList).length,
		showHeader = true,
		showViewMore = false,
		showSearchBox = true,
		styleHomepage = false,
		steps,
		removeHeight
	} = props;

	const theme = useTheme();
	const history = useHistory();
	const classes = useStyles({ theme, align: showViewMore });
	const [brandHeight, setBrandheight] = useState(306);
	const [searchTerm, setSearchTerm] = useState("");
	const getBrandHeight = useRef();


	useEffect(() => {
		setTimeout(() => {
			const brandImageHeight = getBrandHeight?.current?.clientHeight;
			setBrandheight(brandImageHeight);
		}, 1000)
	}, [getBrandHeight]);



	const viewAll = () => {
		history.push("/booking");
	};

	const handleBrandClick = (brand) => {
		setSearchTerm("");
		handleClick(brand);
	};
	const brandArray = Object.keys(brandList) || [];
	//inputProps={{ style: { padding: '10px 14px 10px 0px' } }}

	const getSearchPlaceholder = (stepNo) => {
		if (stepNo === 0) {
			return "Search Car Brand";
		}
		if (stepNo === 1) {
			return "Search Car Model";
		}
	}

	const getModelOptions = () => {
		return brandArray.map((brand) => {
			return { title: brandList[brand].name, value: brandList[brand].name };
		});
	}


	const getBrandArray = () => {
		let arr = brandArray;
		const arrangement = ['maruti-suzuki', 'hyundai', 'honda', 'tata', 'mahindra', 'renault',
			'ford', 'toyota', 'kia', 'mg', 'nissan'];
		const start = [];
		const end = [];
		brandArray.forEach((brand) => {
			if (arrangement.includes(brand)) {
				const index = arrangement.findIndex((arr) => arr === brand);
				start[index] = brand;
			} else {
				end.push(brand);
			}
		});
		arr = [...start, ...end];

		return arr &&
			arr.filter((brand) => {
				if (steps === 1) {
					// if (searchTerm === "" && brand?.isImageAvailable) return brand;
					return brandList[brand].name
						.toLowerCase()
						.includes(searchTerm.toLowerCase()) && brandList[brand].isImageAvailable;
				} else {
					if (searchTerm === "") return brand;
					else if (
						brandList[brand].name
							.toLowerCase()
							.includes(searchTerm.toLowerCase())
					) {
						return brand;
					}
					return null;
				}
			});
	};

	return (
		<Card className={styleHomepage ? classes.brandModule : classes.brancCard}>
			{showSearchBox && (
				<Box p={2}>
					<Box mb={1}>
						{steps === 0 && (
							<Typography align="left" className={classes.brandListTitle}>
								Select Your Car Brand
							</Typography>
						)}
						{steps === 1 && (
							<Typography align="left" className={classes.brandListTitle}>
								Select Your Car Models
							</Typography>
						)}
					</Box>

					{steps === 0 && <Box>
						<TextField
							id="outlined-search"
							placeholder={getSearchPlaceholder(steps)}
							type="search"
							onChange={(event) => {
								setSearchTerm(event.target.value);
							}}
							value={searchTerm}
							variant="outlined"
							fullWidth
							className={classes.searchTextBox}
							InputProps={{
								startAdornment: <SearchIcon className={classes.searchIcon} />,
							}}
						/>
					</Box>}
				</Box>
			)}
			<Box pl={2}>
				{showHeader && (
					<>
						{steps === 0 && (
							<Typography align="left" className={classes.brandTitle}>
								Top Brands
							</Typography>
						)}
						{steps === 1 && (
							<Typography align="left" className={classes.brandTitle}>
								Top Models
							</Typography>
						)}
					</>
				)}
			</Box>
			{(!getBrandArray() || !getBrandArray().length) && steps === 1 && <div className={classes.noRecordFoundText}>
				Please select the car model from drop down.
                </div>}
			{(!getBrandArray() || !getBrandArray().length) && steps === 0 &&
				<div
					// className={classes.noRecordFoundText}
					style={{ marginTop: 23, marginLeft:16 }}
				>
					<Box
						ml={1}
						mb={2}
						mr={1}
						justifyItems="center"
						display="inline-block"
						className={`${classes.brandContainer} ${styleHomepage ? classes.homeBrandContainer : null
							}`}
						onClick={() => { history.push('booking-form') }}
					>
						<Box p={2} className={classes.brandLogoContainer}
							style={{ minHeight: 80 }}
						>
							<p className={classes.othersText}>Others</p>
						</Box>
						<Typography
							variant="body2"
							align="center"
							color="secondary"
							className={
								styleHomepage ? classes.brandTitle : classes.brandTitle
							}
						>
							Others
						</Typography>
					</Box>
				</div>
			}
			{getBrandArray() && getBrandArray().length ? <Box
				mt={2}
				mb={2}
				pb={2}
				style={{ height: brandHeight ? brandHeight * 2 : 306 }}
				className={`${classes.listContainer} ${styleHomepage ? classes.homeListContainer : null
					} ${removeHeight ? classes.removeContainerHeight : null}`}
			>
				<Box className={styleHomepage ? classes.homeBox : null}>
					{
						getBrandArray().splice(0, limit)
							.map((brand, index) => (
								<Box
									ml={1}
									mb={2}
									mr={1}
									key={index}
									justifyItems="center"
									display="inline-block"
									ref={getBrandHeight}
									className={`${classes.brandContainer} ${styleHomepage ? classes.homeBrandContainer : null
										}`}
									onClick={() => handleBrandClick(brandList[brand])}
								>
									<Box p={2} className={classes.brandLogoContainer}>
										<CImage
											imgSrc={brandList[brand].img_url}
											style={styleHomepage ? classes.brandImage : classes.Image}
										/>
									</Box>
									<Typography
										variant="body2"
										align="center"
										color="secondary"
										className={
											styleHomepage ? classes.brandTitle : classes.brandTitle
										}
									>
										{brandList[brand].name}
									</Typography>
								</Box>
							))}

					{showViewMore && (
						<Box
							ml={1}
							mb={2}
							mr={1}
							justifyItems="center"
							display="inline-block"
							className={`${classes.brandContainer} ${styleHomepage ? classes.homeBrandContainer : null
								}`}
							onClick={viewAll}
						>
							<Box p={2} className={classes.brandLogoContainer}>
								<p className={classes.viewAllBrandText}>View All Brands</p>
							</Box>
							<Typography
								variant="body2"
								align="center"
								color="secondary"
								className={classes.brandTitle}
							>
								&nbsp;
              </Typography>
						</Box>
					)}
				</Box>
			</Box> : ''
			}
			{steps === 1 &&
				<Autocomplete
					id="combo-box-demo"
					options={getModelOptions()}
					getOptionLabel={(option) => option.title}
					className={classes.autoCompleteSelectBox}
					onChange={(e, value) => {
						handleBrandClick(brandList[value.title.toLowerCase()])
					}}
					renderInput={(params) => <TextField {...params} label="Select Car Model" variant="outlined" />}
				/>
			}
		</Card>
	);
};

export default CarBrands;
