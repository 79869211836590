import firebase from "../config/Firebase/index";

export const getLeadId = async () => {
	let _date = new Date().toLocaleString().split(",")[0];
	let details = _date.split("/");
	var ans = null;
	const dates = `${details[2]}${details[0].length === 1 ? `0${details[0]}` : details[0]
		}${details[1].length === 1 ? `0${details[1]}` : details[1]}`

	await firebase
		.database()
		.ref()
		.child("appointments")
		.orderByChild("leadID")
		.startAt(
			parseInt(dates)
		)
		.get()
		.then(function (data) {
			if (data.exists()) {
				ans = filterLatestLeadId(data.val(), dates);
			}
		});
	if (ans) return ans;
	else
		return `${details[2]}${details[0].length === 1 ? `0${details[0]}` : details[0]
			}${details[1].length === 1 ? `0${details[1]}` : details[1]}0001`;
};

export const filterLatestLeadId = (data, time) => {
	let arr = [];
	for (let key in data) {
		data[key].id = key;
		arr.push(data[key]);
	}
	let latestDateData = arr.filter(p => String(p.leadID).startsWith(time));
	const newId = leadIdNew(latestDateData);
	return newId;
}

export const leadIdNew = (data) => {
	if (data.length === 0) {
		return false;
	}
	let latestId = 0;
	data.forEach((e) => {
		if (parseInt(e.leadID) > latestId)
			latestId = parseInt(e.leadID);
	})
	return latestId + 1;
}


export const CheckIfNotEmpty = text => {
	return !(text == null || /^\s*$/.test(text));
};


export const CheckIfEmailIsValid = email => {
	const regex = new RegExp(/\S+@\S+\.\S+/, 'g');
	if (regex.test(email)) {
		return checkEmailLength(email);
	} else {
		return false;
	}
};

export const CheckIfValidName = text => {
	return (!(text == null || /^\s*$/.test(text)) && /^[a-zA-Z ]*$/.test(text))
};

function checkEmailLength(email) {
	let splitEmail = email.split('@');
	const username = splitEmail[0];
	const domain = splitEmail[1].split('.');
	return (
		username && username.length < 60 && (domain && domain[0].length < 30 && domain[1].length < 10)
	);
}