import React from "react";
import { Typography, Box } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CImage from "./CImage";

const useStyles = makeStyles((theme) => ({
  text: {
    color: "#1B154E ",
    lineHeight: 1.4,
    fontSize: 17,
  },
  text2: {
    color: "#1B154E ",
  },
  titleDesc: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mainContainer: {
    marginBottom: "15px",
  },
}));

const AdvantageBlock = (props) => {
  const {
    direction,
    width,
    heading,
    imageContainer,
    subHeading,
    subHeadStyl,
    textAline,
    image,
    style,
    headingStyle,
    imgStyle,
  } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Box>
      <Box
        display="flex"
        flexDirection={direction}
        className={classes.mainContainer}
      >
        <Box width={width} className={style}>
          <Box className={imageContainer}>
            <CImage style={style + " " + imgStyle} imgSrc={image} />
          </Box>
        </Box>
        <Box className={classes.titleDesc}>
          <Typography
            align={textAline}
            variant="h6"
            className={classes.text + " " + headingStyle}
          >
            {heading}
          </Typography>
          <Typography
            variant="subtitle2"
            align={textAline}
            className={classes.text2 + " " + subHeadStyl}
          >
            {subHeading}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AdvantageBlock;
