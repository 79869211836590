/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import moment from "moment";
import {
	TextField,
	Button,
	Divider,
	Typography,
	InputAdornment,
	Box,
	Checkbox,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import LoginRightSide from "../atoms/LoginRightSide";
import AppLogo from "../assets/images/App-logo.png";
import firebase from "../config/Firebase";
import { getLeadId } from "../firebase/method";
import { createEvent, mobileSMS, appointmentStatusMail, createUser } from '../firebase/sendMail';
import { fetchWhatsappUser, getWhatsappUsersDetails } from '../firebase/getWhatsappUsers';


const useStyles = makeStyles((theme) => ({
	loginText: {
		color: "#1B154E ",
		fontSize: 22,
	},
	loginButton: {
		background: "#1B154E",
		color: "#fff !important",
		"&:hover": {
			background: "#FFCF00",
		},
	},
	divider: {
		marginTop: 32,
		marginBottom: 32,
		[theme.breakpoints.down("md")]: {
			display: "none",
		},
	},
	loginLeftSide: {
		[theme.breakpoints.down("md")]: {
			width: "100%",
		},
		'@media (max-width: 320px)': {
			padding: '40px 10px 0'
		}
	},
	loginContainer: {
		height: "100%",
		[theme.breakpoints.down("md")]: {
			display: "flex",
			flexDirection: "column",
		},
	},
	loginRight: {
		[theme.breakpoints.down("md")]: {
			width: "100%",
		},
		"@media (min-width:321px) and (max-width:1024px)": {
			marginTop: '-55px'
		},
		'@media (max-width: 320px)': {
			padding: '40px 10px 0'
		}
	},
	bottomHeading: {
		fontSize: 13,
		fontWeight: "500",
	},
	backToHome: {
		display: "flex",
		background: "white",
		flex: 1,
		marginLeft: -10,
	},
	loginError: {
		color: "red",
		fontSize: 13,
		fontWeight: "500",
	},
	headTitle: {
		color: "#1B154E",
		marginLeft: 2,
		marginTop: 2,
		fontSize: 18,
		fontWeight: 600,
		fontFamily: "Montserrat, SemiBold",
	},
	iconButton: {
		fontSize: 30,
		color: "#1B154E",
		cursor: "pointer",
	},
	captua: {
		transform: "scale(0.8)",
		transformOrigin: "0 0",
		display: "none",
	},
	textField: {
		"& label": {
			"&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
				backgroundColor: "#fff",
				top: "-5px",
				padding: "0 5px",
			},
		},
	},
	checkBox: {
		marginLeft: -8,
		marginTop: -8,
	},
	errormesage: {
		color: "red",
		marginLeft: 33,
		marginBottom: 20,
		fontSize: 13,
		fontWeight: "500",
	},
	links: {
		cursor: 'pointer',
		textDecoration: 'underline',
		'&:hover': {
			color: '#0d6efd'
		},
	}
}));

const Login = (props) => {
	const { formData } = props;
	var mobileNo = formData && formData.mobile;
	const history = useHistory();
	const theme = useTheme();
	const classes = useStyles(theme);
	const [otp, setOtp] = useState("");
	const [phone, setPhone] = useState(mobileNo || null);
	const [mobileField, setMobileField] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loginError, setLoginError] = useState();
	const [checked, setChecked] = useState();
	const [whatsChecked, setWhatsChecked] = useState(true);
	const [checkError, setCheckError] = useState("");
	const [whatsappUser, setWhatsappUser] = useState([]);
	const [disabled, setDisabled] = useState(false)

	useEffect(() => {
		fetchWhatsappUser().then(userList => {
			if (userList) {
				setWhatsappUser(userList)
			}
		})
	}, [])

	useEffect(() => {
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				history.push("/");
			}
		});
	}, []);

	const goToHome = () => {
		history.push("/");
	};
	const onSignInSubmit = () => {
		setMobileField(true);
		const appVerifier = new firebase.auth.RecaptchaVerifier(
			"recaptcha-container",
			{
				size: "invisible",
			}
		);
		firebase
			.auth()
			.signInWithPhoneNumber("+91" + phone, appVerifier)
			.then((confirmationResult) => {
				window.confirmationResult = confirmationResult;
			})
			.catch((error) => {
				console.log("error");
			});
	};

	const isCheckValid = () => {
		if (!otp) {
			setLoginError('please enter OTP')
		} else {
			setLoginError()
		}
		if (!checked) {
			setCheckError("Check Should be selected");
		} else {
			setCheckError("");
		}
		if (!otp || !checked) {
			return false;
		}
		if (otp && checked) {
			return true;
		}
		return true;
	};

	const sendEmail = async (data, whatsapp) => {
		if (data) {
			if (data.email) {
				await appointmentStatusMail(data.email, "inspection_scheduled", data.firstName ? data.firstName : "");
			}
			if (!whatsChecked) {
				await mobileSMS(data.mobile, "inspection_scheduled", data);
			}
		}
	}

	const sendWhatsAppMsg = (whatsapp, data) => {
		if (whatsChecked) {
			createEvent({
				"userId": data.mobile,
				"phoneNumber": data.mobile,
				"countryCode": "+91",
				"event": "Inspection Scheduled",
				"traits": {
					"status": "ins_sch",
					"name": data.firstName,
					"car": `${data.make} ${data.model}`,
					"location": data.address,
					"time": `${data.appointmentDate} ${data.appointmentTime}`,
					"contact": "+919795097250"
				}
			});
		}
	}

	const signIn = async () => {
		const code = otp;
		var leadId = null;
		if (!isCheckValid()) {
			return;
		}
		setLoading(true);
		setDisabled(true);
		var selectitem = whatsappUser.filter(item => item.mobile === phone);
		var url = "whatsAppUpdate/" + selectitem[0]?.userId
		leadId = await getLeadId();
		var whatsapp = {};
		await getWhatsappUsersDetails(phone).then(data => {
			whatsapp = data;
		})
		window.confirmationResult
			.confirm(code)
			.then((result) => {
				const user = result.user.refreshToken;
				localStorage.setItem("user", user);
				localStorage.setItem("phoneNumber", result.user.phoneNumber);
				const mobileNumber = result?.user?.phoneNumber?.substring(3)
				let whatsappData = {
					mobile: mobileNumber,
					isEnable: whatsChecked,
				};
				if (whatsChecked && !selectitem.length) {
					createUser({
						"userId": mobileNumber,
						"phoneNumber": mobileNumber,
						"countryCode": "+91",
						"traits": {
							"phoneNumber": mobileNumber,
						},
					});
				}
				// if (whatsChecked && !selectitem.length) {
				// 	createEvent({
				// 		"userId": phone,
				// 		"phoneNumber": phone,
				// 		"countryCode": "+91",
				// 		"event": "User Registration",
				// 		"traits": {
				// 			"status": "user_reg"
				// 		}
				// 	});
				// }
				if (!selectitem.length) {
					firebase
						.database()
						.ref("whatsAppUpdate")
						.push(whatsappData)
						.then((res) => {
							console.log("sucessfully");
						})
						.catch((err) => {
							console.log(err);
						});
				}
				if (selectitem[0].mobile === phone) {
					firebase
						.database()
						.ref(url)
						.set(whatsappData)
						.then((res) => {
							console.log("sucessfully");
						})
						.catch((err) => {
							console.log(err);
						});
				}
				if (formData) {
					let data = { ...formData };
					if (data) {
						data.leadID = leadId;
						data.createdAt = moment().format();
						data.updatedAt = moment().format();
						data.leadDate = moment().format("YYYY-MM-DD");
						data.userId = result.user.uid;
						data.status = "inspection_scheduled"
					}
					firebase
						.database()
						.ref("appointments")
						.push({
							...data,
						})
						.then((res) => {
							console.log("sucessfully");
							sendEmail(formData, whatsapp);
							sendWhatsAppMsg(whatsapp.isEnable, data)
							history.push({
								pathname: '/confirmation',
								state: { form: formData },
							});
							setDisabled(false);
						})
						.catch((err) => {
							console.log(err);
							setDisabled(false);
						});
				}
				else {
					history.push("/");
					setDisabled(false);
				}
				setLoading(false);
				setDisabled(false);
			})
			.catch((error) => {
				console.log(error);
				clearForm();
				setLoading(false);
				setDisabled(false);
				setTimeout(() => {
					setLoginError("Otp is incorrect");
				}, 500);
			});
		if (!selectitem.length) {
			await mobileSMS(phone, "newUserRegidtration");
		}
	};

	const clearForm = () => {
		setOtp("");
	};

	const checkPhone = (value) => {
		if (value.charAt(0) === "0") {
			return false;
		} else if (value.length > 10) {
			return false;
		}
		setPhone(value);
	};

	const checkOTP = (value) => {
		if (value.length > 6) {
			return false;
		}
		setOtp(value);
	};

	const checkSinner = (e) => {
		if (e.keyCode === 38 || e.keyCode === 40) {
			e.preventDefault();
		}
	}

	return (
		<Box display="flex">
			<Box bgcolor="background.paper" width="100%">
				<Box display="flex" className={classes.loginContainer}>
					<Box p={5} width="50%" className={classes.loginLeftSide}>
						<Box className={classes.backToHome}>
							<ChevronLeftIcon
								className={classes.iconButton}
								onClick={goToHome}
							/>
							<Typography
								className={classes.headTitle}
								onClick={goToHome}
								style={{ cursor: "pointer" }}
							>
								Home
              </Typography>
						</Box>
						<Box display="flex" justifyContent="center" mb={2}>
							<span onClick={goToHome} style={{ cursor: "pointer" }}>
								<img src={AppLogo} alt="Carboli App Logo" title="test" />
							</span>
						</Box>
						<Typography
							align="left"
							component="h1"
							variant="h5"
							className={classes.loginText}
						>
							Log In/Sign Up
            </Typography>
						<Box className={classes.form}>
							<Box mb={4} mt={4}>
								<TextField
									required
									variant="outlined"
									label="Mobile Number"
									className={classes.textField}
									disabled={mobileField}
									type="number"
									fullWidth
									value={phone}
									onChange={(e) => checkPhone(e.target.value)}
									onKeyDown={(e) => checkSinner(e)}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Button
													disabled={!(phone && phone.length === 10)}
													onClick={onSignInSubmit}
												>
													{mobileField ? "Resend OTP" : "Get OTP"}
												</Button>
											</InputAdornment>
										),
									}}
								/>
							</Box>

							{mobileField ? (
								<>
									<Box mb={3}>
										<TextField
											fullWidth
											className={classes.textField}
											variant="outlined"
											label="OTP"
											type="number"
											value={otp}
											onKeyDown={(e) => checkSinner(e)}
											// disabled={otpField}
											onChange={(e) => checkOTP(e.target.value)}
										/>
										{loginError && (
											<Typography className={classes.loginError}>
												{loginError}
											</Typography>
										)}
									</Box>

									<Box display="flex">
										<Box className={classes.checkBox}>
											<Checkbox
												label=""
												onChange={(e) => {
													setChecked(e.target.checked);
												}}
												checked={checked}
											/>
										</Box>
										<Box>
											<Typography className={classes.bottomHeading}>
												By clicking on check, you acknowledge that you have read
												and accepted the {" "}
												<span className={classes.links}
													onClick={() => { history.push('condition') }}
												>
													Terms of Service</span> and the {" "}
												<span className={classes.links}
													onClick={() => { history.push('privacy') }}
												>Privacy
												Policy</span>.
                      </Typography>
										</Box>
									</Box>
									<Typography className={classes.errormesage}>
										{checkError}
									</Typography>

									<Box display="flex">
										<Box className={classes.checkBox}>
											<Checkbox
												label=""
												onChange={(e) => {
													setWhatsChecked(e.target.checked);
												}}
												checked={whatsChecked}
											/>
										</Box>
										<Box>
											<Typography className={classes.bottomHeading}
												style={{ marginTop: 3 }}
											>
												Send updates on WhatsApp
                    						</Typography>
										</Box>
									</Box>

									<Box mb={3}>
										<Button
											variant="outlined"
											fullWidth
											color="secondary"
											disabled={
												!(
													phone &&
													phone.length === 10
												) || disabled
											}
											onClick={signIn}
											className={classes.loginButton}
										>
											{loading ? (
												<CircularProgress size={22} style={{ color: "#FFCF00" }} />
											) : (
													"Login"
												)}
										</Button>
									</Box>
								</>
							) : null}
							<Box id="recaptcha-container" className={classes.captua}></Box>
						</Box>
					</Box>
					<Divider
						className={classes.divider}
						flexItem
						orientation="vertical"
						variant="middle"
					/>
					<Box p={5} width="50%" className={classes.loginRight}>
						<LoginRightSide />
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Login;
