import React from "react";
import Login from "../components/Login";
import { Box, Grid, Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import backgroundImage from "../assets/images/dummycar.jpeg";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  Paper: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
    height: "100vh",
    alignItems: "center",
    display: "flex",
  },
  dialog: {
    "& .MuiDialog-paper": {
      minHeight: '600px !important',
      "@media(max-width: 1024px)": {
        maxHeight: "100%",
        height: "100%",
        width: "100%",
        maxWidth: "100%",
        margin: "0px",
      },
    },
  },
}));

const LoginPage = (props) => {
  const location = useLocation();
  const classes = useStyles();
  return (
    <Grid container alignItems="center" direction="row">
      <Grid item xs={12}>
        <Box className={classes.Paper}>
          <Dialog
            open
            maxWidth="md"
            //fullScreen
            className={classes.dialog}
          >
            <Login formData={location.state} />
          </Dialog>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
