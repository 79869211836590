import React from 'react'
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	DatePicker
} from '@material-ui/pickers';
import { makeStyles } from "@material-ui/core/styles";
import Event from "@material-ui/icons/Event";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles((theme) => ({
	root: {
		'& MuiSvgIcon-root': {
			height: 200,
		},
		'& MuiInputBase-root': {
			fontSize: 14,
		},
		'& .MuiInputLabel-animated': {
			fontSize: 12,
			fontFamily: 'Montserrat, SemiBold',
			color: '#1B154E ',
		},
		'& label + .MuiInput-formControl': {
			fontSize: 14,
			fontFamily: 'Montserrat, SemiBold',
			color: '#000000',
		},
		'& MuiInputAdornment-root .MuiInputAdornment-positionEnd': {
			marginRight: -13
		}
	},
	container: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		minHeight: 50,
		top: -14
	},
}));

const AppointmentDate = (props) => {
	const classes = useStyles();
	const { defaultValue, onChange, dates, error, helperText, onKeyUp } = props;

	return (
		<>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<DatePicker
					error={error}
					helperText={helperText}
					format="dd/MM/yyyy"
					margin="normal"
					disablePast
					autoOk
					maxDate={moment().add(29, "days")}
					label="Appointment Date"
					inputVariant="standard"
					id="date-picker-dialog"
					fullWidth
					value={dates || defaultValue || null}
					onChange={date => onChange(date)}
					minDateMessage={false}
					className={classes.textField}
					InputProps={{
						endAdornment: (
							<InputAdornment>
								<Event style={{ fontSize: 20, color: '#1B154E ', position: 'absolute', right: 0, cursor: 'pointer' }} />
							</InputAdornment>
						)
					}}
					KeyboardButtonProps={{
						'aria-label': 'change date',
					}}
					inputProps={{ style: { fontSize: 14, color: '#1B154E ', marginRight: 10 } }} // font size of input text
					InputLabelProps={{ style: { fontSize: 12, color: '#1B154E ', fontFamily: 'Montserrat, SemiBold' } }} // font size of input label
					endAdornment={{ style: { marginRight: -13 } }}
					onBlur={onKeyUp}
				/>
			</MuiPickersUtilsProvider>
		</>
	);
}
export default AppointmentDate;
