import firebase from "../config/Firebase/index";

export const fetchPortfolioData = async () => {
	let portfolio=[];
	return await firebase
		.database()
		.ref()
		.child("portfolio")
		.get()
		.then(function (data) {
			if (data.exists()) {
				portfolio = extractPortfolioData(data.val());				
				return portfolio;
			}
			else {
				console.log("No data available");
			}
		})
		.catch(function (error) {
			console.error(error);
		});
}

export const extractPortfolioData = (data) => {
	let apps = [];
	for (let key in data) {
		data[key].companyId = key;
		apps.push(data[key]);
	}
	return apps || [];
};