import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { fetchPortfolioData } from "../firebase/getPortfoliData";
import { getCarData } from "../firebase/getCarData";
import CarBrands from "../atoms/CarBrands";
import roundImg from "../assets/images/round.svg";
import CarBoilWorks from "./HowCarBoilWorks/CarBoilWorks";
import Advantages from "./Advantages/Advantages";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
	advantageBlock: {
		paddingTop: 40,
		position: "relative",
		[theme.breakpoints.down("sm")]: {
			padding: "40px 25px 0px",
			backgroundColor: "#ffffff",
		},
		"@media (min-width:768px) and (max-width:1024px)": {
			padding: "104px 104px 32px",
		},
	},
	title: {
		color: "#1B154E ",
		fontSize: 33,
		position: "relative",
		float: "left",
		"&::before": {
			content: '""',
			width: "150px",
			height: "3px",
			background: "#FFD13B",
			position: "absolute",
			left: "65%",
			bottom: "0",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: 24,
		},
	},
	heading: {
		fontSize: 36,
		[theme.breakpoints.down("md")]: {
			fontSize: 30,
		},
	},
	advantageImg: {
		display: "flex",
		justifyContent: "center",
	},
	imageContainer: {
		boxShadow: "0px 3px 6px #00000029",
		borderRadius: 11,
		padding: 8,
	},
	Img: {
		height: 191,
		marginLeft: "0px !important",
		[theme.breakpoints.down("md")]: {
			height: 125,
		},
	},
	highText: {
		fontWeight: 600,
	},
	highlightText: {
		fontSize: "30px",
		fontWeight: 600,
		lineHeight: 1.235,
		paddingLeft: 8,
		paddingRight: 8,
	},
	roundBackground: {
		backgroundImage: `url(${roundImg})`,
		backgroundSize: "cover",
		width: " 593px",
		height: "800px",
		float: "right",
		position: "absolute",
		right: "0",
		zIndex: -1,
		bottom: "-58px",
		backgroundRepeat: "no-repeat",
	},
	caurosel: {
		height: "100vh",
		width: "100%",
	},
	imageSlider: {
		display: "flex",
		justifyContent: "center",
		"& img": {
			width: "50%",
		},
	},
	cauroselContainer: {
		display: "block",
		"@media (min-width:1680px) and (max-width:3000px) ": {
			marginBottom: 150,
		},
		"& .alice-carousel__next-btn-item span": {
			background: theme.palette.primary.main,
			padding: 10,
			borderRadius: 50,
		},
		"& .alice-carousel__prev-btn-item span": {
			background: theme.palette.primary.main,
			padding: 10,
			borderRadius: 50,
		},
	},
	advantage: {
		fontSize: 33,
		margin: "auto",
		marginTop: "10px",
		marginBottom: "30px",
		position: "relative",
		"&::before": {
			content: '""',
			width: "145px",
			height: "3px",
			background: "#FFD13B",
			position: "absolute",
			left: "58%",
			bottom: "0",
		},
	},
	happySeller: {
		fontSize: 33,
		margin: "auto",
		marginTop: "10px",
		marginBottom: "30px",
		position: "relative",
		"&::before": {
			content: '""',
			width: "145px",
			height: "3px",
			background: "#FFD13B",
			position: "absolute",
			left: "58%",
			bottom: "0",
		},
	},
	SelectTitleText: {
		width: "100%",
		display: "flex",
	},
	SelectBrand: {
		margin: "auto",
		marginTop: "0px",
		marginBottom: "30px",
		position: "relative",
		fontSize: "32px",
		fontWeight: "600",
		"@media (max-width:600px)": {
			fontSize: "25px",
		},
	},
	leftRoundBackground: {
		backgroundImage: `url(${roundImg})`,
		backgroundSize: "cover",
		width: "765px",
		height: "730px",
		float: "right",
		position: "absolute",
		zIndex: 0,
		backgroundRepeat: "no-repeat",
		right: "inherit",
		left: "-298px",
		bottom: 47,
	},
	subHeading: {
		marginLeft: 21,
	},
	loader: {
		display: 'flex',
		justifyContent: 'center'
	}
}));

const HomePage = () => {
	const theme = useTheme();
	const history = useHistory();
	const classes = useStyles(theme);
	const [brands, setBrands] = useState([]);
	const [portfolio, setPortfolio] = useState([]);
	const [loading, setLoading] = useState(true)
	const isLimit = useMediaQuery(theme.breakpoints.up("md"));

	useEffect(() => {
		fetchPortfolioData().then((portfolioList) => {
			if (portfolioList) {
				setPortfolio(portfolioList);
			}
		});
	}, []);

	useEffect(() => {
		setLoading(true);
		getCarData().then((brandsList) => {
			if (brandsList) {
				setLoading(false);
				setBrands(brandsList);
			}
		});
	}, []);

	const getBrand = (brands) => {
		history.push({
			pathname: "/booking",
			state: {
				brandName: {
					name: brands.name,
				},
				mode: "new",
				brand: { brands },
			},
		});
	};

	return (
		<Grid container>
			<Grid item xs={12}>
				<Box className={classes.advantageBlock}>
					<Box className={classes.highText}>
						<div className={classes.SelectTitleText}>
							<span className={classes.SelectBrand}>Select Your Car Brand</span>
						</div>
						{loading ?
							<Box className={classes.loader}>
								<CircularProgress  />
							</Box>
							:
							<CarBrands
								brandList={brands}
								handleClick={getBrand}
								limit={isLimit ? 11 : 5}
								showHeader={false}
								showViewMore={true}
								styleHomepage={true}
								showSearchBox={false}
								removeHeight={true}
							/>
						}
					</Box>
				</Box>
				<CarBoilWorks />
				<Advantages portfolio={portfolio} />
			</Grid>
		</Grid>
	);
};

export default HomePage;
