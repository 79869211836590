/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Typography,
	Card,
	makeStyles,
	useTheme,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { getCarData } from "../firebase/getCarData";
import CarBrands from "../atoms/CarBrands";
import CStepper from "../atoms/Stepper";
import YearList from "../atoms/YearList";
import CarVariant from "../atoms/CarVariant";
import CarDetails from "../atoms/CarDetails";
import CImage from "../atoms/CImage";
import Loader from "../atoms/Loader";
import Header from "../atoms/Header";

const useStyles = makeStyles((theme) => ({
	selectedItem: {
		background: "#FFCF00",
		margin: 2,
		padding: 8,
		borderRadius: 5,
		cursor: "pointer",
		"@media (max-width:600px)": {
			padding: 4,
			float: "left",
		},
	},
	leftBox: {
		"@media (max-width:1250px)": {
			paddingRight: "0px",
		},
	},
	bookingRightContainer: {
		display: "flex",
		background: "#fff",
		zIndex: -2,
		width: "35%",
		paddingTop: '100px',
		alignItems: 'flex-start',
		position: 'relative',
		"@media(max-width: 1220px)": {
			width: "100%",
			paddingTop: '40px',
		},
		"& img": {
			width: "250px",
			height: "auto",
			position: "relative",
			top: 0,
			"@media(max-width: 1024px)": {
				margin: 'auto'
			},
			"@media(max-width: 1220px)": {
				width: '180px'
			},
		},
	},
	detailsHeading: {
		color: "#1B154E ",
		margin: "auto",
		position: "relative",
		fontSize: 20,
		"&::before": {
			content: '""',
			width: "168px",
			height: "3px",
			background: "#FFD13B",
			position: "absolute",
			left: 0,
			bottom: "0",
		},
	},
	carBrandImages: {
		height: 270,
		width: '100%',
		top: '-50px',
		position: 'relative',
		alignItems: 'center',
		display: 'flex',
	},
	reviewHeading: {
		color: "#1B154E ",
		margin: "auto",
		position: "relative",
		fontSize: 20,
		fontWeight: "500",
		"&::before": {
			content: '""',
			width: "130px",
			height: "3px",
			background: "#FFD13B",
			position: "absolute",
			left: 0,
			bottom: "0",
		},
		"@media (max-width:600px)": {
			marginLeft: "-40px",
		},
	},
	markLines1: {
		width: "100%",
		backgroundImage: "url(./assets/images/greyLine.svg)",
		position: "absolute",
		height: 25,
		backgroundRepeat: "no-repeat",
		top: "26%",
		backgroundSize: "cover",
		zIndex: -1,
		"@media (max-width:600px)": {},
	},
	markLines2: {
		width: "100%",
		backgroundImage: "url(./assets/images/yellowLine.svg)",
		position: "absolute",
		height: 25,
		backgroundRepeat: "no-repeat",
		top: "42%",
		backgroundSize: "cover",
		zIndex: -1,
	},
	markline11: {
		width: "100%",
		backgroundImage: "url(./assets/images/greyLine.svg)",
		position: "absolute",
		height: 25,
		backgroundRepeat: "no-repeat",
		top: "26%",
		backgroundSize: "cover",
		zIndex: -1,
		'@media (max-width: 768px)': {
			top: "-5%",
		}
	},
	markline12: {
		width: "100%",
		backgroundImage: "url(./assets/images/yellowLine.svg)",
		position: "absolute",
		height: 25,
		backgroundRepeat: "no-repeat",
		top: "42%",
		backgroundSize: "cover",
		zIndex: -1,
		'@media (max-width: 768px)': {
			top: "33%",
		}
	},
	carBrand: {
		height: 270,
		width: '100%',
		top: '-50px',
		position: 'relative',
		alignItems: 'center',
		display: 'flex',
		'@media (max-width: 768px)': {
			height: 93,
		}
	},
	detailsSection: {
		marginBottom: 20,
		'@media (max-width: 320px)': {
			padding: '16px 14px 32px'
		}
	},
	submitDetails: {
		backgroundColor: "#F9F9F9",
		marginLeft: 40,
		width: "100%",
		height: "60vh !important",
		marginBottom: 20,
		minHeight: "60vh !important",
		"@media(max-width: 600px)": {
			marginLeft: "0px",
			width: "100%",
			paddingRight: "0px",
		},
	},
	stapper: {
		backgroundColor: "#F9F9F9",
		marginTop: 35,
	},
	bookingContainer: {
		"@media(max-width: 1220px)": {
			flexDirection: "column-reverse",
		},
		"@media (min-width:1680px) and (max-width:3000px) ": {
			marginBottom: 80,
		},
	},
	loader: {
		width: "100%",
		height: "70vh",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	bookingLeftContainer: {
		width: "65%",
		"@media(max-width: 1220px)": {
			width: "100%",
		},
	},
	heading: {
		paddingTop: "4px",
		marginLeft: "72px",
	},
	stepperStyle: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	breadcrumb: {
		paddingTop: "8px",
		marginLeft: "72px",
		marginTop: 15,
		display: "flex",
		"@media (max-width:600px)": {
			marginLeft: 72,
			display: "inline-block",
		},
	},
	SubmitBreadCrumbs: {
		marginLeft: "72px",
		display: "flex",
		marginTop: 15,
		"@media (max-width:600px)": {
			marginLeft: 32,
			display: "inline-block",
		},
	},
	rightBox: {
		position: 'relative',
		width: '100%'
	}
}));

const Booking = (props) => {
	const location = useLocation();
	const history = useHistory();
	const theme = useTheme();
	const classes = useStyles(theme);
	const [appointmentData] = useState(location.state);
	const [steps, setSteps] = useState(0);
	const [brandName, setBrandName] = useState();
	const [carName, setCarName] = useState();
	const [selectedYear, setSelectedYear] = useState();
	const [brands, setBrands] = useState();
	const [selectedOil, setSelectedOil] = useState();
	const [selectedVariant, setSelectedVariant] = useState();
	const [selectedBrandList, setSelectedBrandList] = useState(false);
	const [loading, setLoading] = useState(true);
	const [selectedTransmissionType, setSelectedTransmissionType] = useState('');

	useEffect(() => {
		if (location.state) {
			const { brandName, carName, selectedYear, selectedVariant } =
				location.state;
			brandName && setBrandName(brandName);
			carName && setCarName(carName);
			selectedYear && setSelectedYear(selectedYear);
			selectedVariant && setSelectedVariant(selectedVariant);
		}
	}, [location]);

	useEffect(() => {
		if (steps === 0) {
			//setBrandName(null)
			setCarName(null);
			setSelectedVariant(null);
			setSelectedYear(null);
			setSelectedOil(null);
		}
		if (steps === 1) {
			setCarName(null);
			setSelectedVariant(null);
			setSelectedYear(null);
			setSelectedOil(null);
		}
		if (steps === 2) {
			setSelectedYear(null);
			setSelectedVariant(null);
			setSelectedOil(null);
		}
		if (steps === 3) {
			setSelectedVariant(null);
			setSelectedOil(null);
		}
	}, [steps]);

	const onBrandName = () => {
		setSelectedBrandList(brands);
		setSteps(0);
		setBrandName(null);
	};

	useEffect(() => {
		getCarData().then((brands) => {
			setBrands(brands);
			setLoading(false);
			if (location.state && location.state.mode === "new") {
				const { brandName, carName, brand } = location.state;
				if (brandName) {
					setSelectedBrandList(brands[brandName.name.toLowerCase()].cars);
					setSteps(steps + 1);
					setBrandName(brand.brands);
					if (carName) {
						setSelectedBrandList(
							brands[brandName.name.toLowerCase()].cars[
							carName.name.toLowerCase()
							]
						);
						setSteps(steps + 2);
					}
				} else {
					setSelectedBrandList(brands);
				}
			} else if (location.state) {
				const { brandName, carName, selectedYear, selectedVariant } =
					location.state;
				if (brandName) {
					setSelectedBrandList(brands);
					setSteps(0);
					setBrandName(brandName);
					if (carName) {
						setSelectedBrandList(brands[brandName.name.toLowerCase()].cars);
						setSteps(steps + 1);
						setCarName(carName);
						if (selectedYear) {
							setSteps(steps + 2);
							setSelectedYear(selectedYear);
							if (selectedVariant) {
								setSteps(steps + 3);
								setSelectedVariant(selectedVariant);
								return;
							}
						}
					}
				} else {
					setSelectedBrandList(brands);
				}
			} else {
				if (brandName) {
					setSelectedBrandList(brands[brandName.name.toLowerCase()].cars);
					//setBrandName(brandName)
					if (carName) {
						setSelectedBrandList(
							brands[brandName.name.toLowerCase()].cars[
							carName.name.toLowerCase()
							]
						);
						//setCarName(carName)
					}
					setSteps(steps + 1);
				} else {
					setSelectedBrandList(brands);
				}
			}
		});
	}, []);

	const getBrand = (brand) => {
		if (steps === 0) {
			setBrandName(brand);
			setSelectedBrandList(brand.cars);
		} else if (steps === 1) {
			setCarName(brand);
		}
		setSteps(steps + 1);
	};

	const handleYearSelected = (year) => {
		setSelectedYear(year);
		setSteps(steps + 1);
	};

	const handleVariantSelection = (oil, transmissionTypeSelection) => {
		setSelectedVariant(oil);
		setSelectedTransmissionType(transmissionTypeSelection);
		if (oil === "Others") {
			history.push({
				pathname: "/booking-form",
				state: { make:brandName.name,
				model:carName.name,
				year:selectedYear,
				oilVariant:selectedOil,
				transMissionType:transmissionTypeSelection,
				},
			});
		} else {
			setSteps(steps + 1);
		}
	};

	const handleSteps = (step) => {
		if (step === 1) {
			setSelectedBrandList(brands);
		} else if (steps === 2) {
			setSelectedBrandList(brands[brandName.name.toLowerCase()].cars);
		}
		setSteps(step - 1);
	};
	const selectedImage = `./cars/${brandName && brandName.name}/img_url.png`;
	// steps === 1
	// 	? `./cars/${brandName && brandName.name}/img_url.png`
	// 	: steps >= 2
	// 		? carName && carName.img_url
	// 		: "logo";

	return (
		<>
			<Header />
			{loading ? (
				<Box className={classes.loader}>
					<Loader />
				</Box>
			) : (
					<Box>
						<Box width="100%" display="flex" className={classes.bookingContainer}>
							<Box width="65%" className={classes.bookingLeftContainer}>
								<Box
									bgcolor="background.paper"
									pr={2}
									className={classes.leftBox}
								>
									<Box className={classes.heading}>
										{steps <= 3 ? (
											<Typography variant="h6" className={classes.detailsHeading}>
												Enter Car Details
											</Typography>
										) : (
												<Typography variant="h6" className={classes.reviewHeading}>
													Review Details
												</Typography>
											)}
									</Box>
									<Box width="100%" pr={2}>
										<Box
											className={
												steps === 4
													? classes.SubmitBreadCrumbs
													: classes.breadcrumb
											}
										>
											{brandName && (
												<Typography
													onClick={onBrandName}
													className={classes.selectedItem}
												>
													{brandName.name}
												</Typography>
											)}
											{carName && (
												<Typography
													onClick={() => setSteps(1)}
													className={classes.selectedItem}
												>
													{carName.name}
												</Typography>
											)}
											{carName && selectedYear && (
												<Typography
													onClick={() => setSteps(2)}
													className={classes.selectedItem}
												>
													{selectedYear}
												</Typography>
											)}
											{carName && selectedYear && selectedOil && (
												<Typography
													onClick={() => setSteps(3)}
													className={classes.selectedItem}
												>
													{selectedOil}
												</Typography>
											)}
											{selectedVariant && (
												<Typography
													onClick={() => setSteps(3)}
													className={classes.selectedItem}
												>
													{selectedVariant}
												</Typography>
											)}
										</Box>
									</Box>
									<Box
										display="flex"
										p={4}
										pt={2}
										className={classes.detailsSection}
									>
										{brands ? (
											<>
												{" "}
												{steps === 5 ? null : (
													<CStepper
														steps={new Array(5).fill(" ")}
														activeStep={steps}
														setSteps={setSteps}
														style={classes.stepperStyle}
														handleSteps={handleSteps}
													/>
												)}
												{steps <= 4 ? (
													<>
														{steps < 2 ? (
															<CarBrands
																steps={steps}
																handleClick={getBrand}
																brandList={selectedBrandList}
																limit={
																	selectedBrandList
																		? Object.keys(selectedBrandList).length
																		: 0
																}
															/>
														) : steps === 2 ? (
															<Box width={"100%"}>
																<YearList
																	years={
																		brands[brandName.name.toLowerCase()].cars[
																			carName.name.toLowerCase()
																		].years
																	}
																	handleClick={handleYearSelected}
																/>
															</Box>
														) : (
																	<CarVariant
																		variantOil={
																			brands[brandName.name.toLowerCase()].cars[
																			carName.name.toLowerCase()
																			]
																		}
																		step={steps}
																		selectedOilVariant={selectedOil}
																		handleOil={(v) => {
																			setSelectedOil(v);
																			setSteps(steps + 1);
																		}}
																		selectedYear={selectedYear}
																		handleVariant={handleVariantSelection}
																	/>
																)}
													</>
												) : (
														<Card className={classes.submitDetails}>
															<Box display="flex">
																<Box>
																	<CStepper
																		steps={new Array(4).fill(" ")}
																		activeStep={steps}
																		setSteps={setSteps}
																		handleSteps={handleSteps}
																		style={classes.stapper}
																	/>
																</Box>
																<Box>
																	<CarDetails
																		brand={brandName.name}
																		model={carName ? carName.name : ""}
																		year={selectedYear}
																		variant={selectedVariant}
																		oilVariant={selectedOil}
																		appointmentData={appointmentData}
																		handleSteps={handleSteps}
																		selectedTransmissionType={selectedTransmissionType}
																	/>
																</Box>
															</Box>
														</Card>
													)}
											</>
										) : (
												<></>
											)}
									</Box>
								</Box>
							</Box>

							<Box width="35%" className={classes.bookingRightContainer}>
								<Box className={classes.rightBox}>
									<Box className={steps === 0 ? classes.markline11 : classes.markLines1} />
									<Box className={steps === 0 ? classes.markline12 : classes.markLines2} />
									<Box className={steps === 0 ? classes.carBrand : classes.carBrandImages}>
										{steps === 0 ? null :
											< CImage
												imgSrc={selectedImage}
												type={'logo'}
											/>
										}
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				)}
		</>
	);
};

export default Booking;
