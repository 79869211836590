import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  AdContainer: {
    padding: "30px 140px",
    "& h2": {
      padding: "20px 0 4px",
      lineHeight: "1.4",
    },
    "& .carousel-class li": {
      textAlign: "center",
      "& .advaContent": {
        right: 10,
        textAlign: "left",
      },
    },

    "@media (max-width:600px)": {
      padding: "10px",
    },
    "@media (max-width:768px)": {
      padding: "19px 22px",
    },
    "@media (min-width:1920px)": {
      padding: "30px 350px",
    },
    "& .AdContainer .title": {
      "@media (max-width:600px)": {
        fontSize: 21,
        fontWeight: 700,
        margin: "20px 0px",
      },
    },
    "& .advaContent": {
      position: "absolute",
      right: 30,
      background: "#fff",
      borderRadius: 6,
      bottom: 0,
      width: 224,
      height: 80,
      padding: 15,
      fontSize: 13,
      boxShadow: "2px 2px 8px 2px rgb(128 128 128 / 75%)",
      "@media (max-width:768px)": {
        right: 21,
        width: 195,
        height: 66,
        padding: 10,
        fontSize: 12,
      },
      "@media (max-width:600px)": {
        right: 10,
        width: 185,
        height: 70,
      },
      "@media (min-width:1920px)": {
        right: 118,
        width: 250,
        height: 85,
        padding: 12,
        fontSize: 16,
      },
    },
    "& .happySeller": {
      width: "100%",
      height: 595,
      backgroundColor: "#fff",
      boxShadow: "2px 2px 8px 2px rgb(128 128 128 / 32%)",
      padding: 30,
      position: "relative",
      "& .HSInerContent h2": {
        fontSize: 20,
        "@media (min-width:1920px)": {
          ontSize: 30,
        },
      },
      "& .HSInerContent p": {
        fontSize: 16,
        color: "gray",
        "@media (max-width:600px)": {
          fontSize: 13,
          padding: "0px 20px",
        },
        "@media (min-width:1920px)": {
          fontSize: 23,
        },
      },
      "& .clintImg": {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        "& img": {
          width: "100px",
          height: "100px",
        },
        "@media (max-width:600px)": {
          "& .clintImg": {
            marginTop: "1rem !important",
          },
          "& .SideImg": {
            display: "none",
          },
          "& img": {
            width: "85px",
          },
          "& .MiddelImg img": {
            height: "88px",
          },
        },
      },
      "& .MiddelImg": {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        "& span": {
          padding: "4px 0",
        },
        "& img": {
          width: "120px !important",
          height: "120px !important",
        },
      },
      "& .Address": {
        color: "#444",
        "@media (max-width:600px)": {
          textAlign: "center",

          fontSize: "14px",
        },
        "@media (min-width:1920px)": {
          padding: "10px 0px",
        },
      },
      "& .HSContentArrow": {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        "@media (max-width:600px)": {
          "& img": {
            display: "none",
          },
          "& .HSInerContent": {
            padding: " 0 10px",
          },
        },
        "@media (max-width:768px)": {
          "& .rightQutetion": {
            display: "none",
          },
          "& .LeftQutetion": {
            display: "none",
          },
        },
      },
      "& .StarContent": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        "& #starImg": {
          padding: "10px 0",
          "@media (max-width:600px)": {
            display: "inline-block",
          },
        },
        " & span": {
          padding: "0px 162px",
          color: "#000",
          fontWeight: 500,

          "@media (max-width:768px)": {
            padding: "0px 60px",
          },
          "@media (max-width:600px)": {
            padding: "0px 77px",
          },
          "@media (max-width:320px)": {
            fontSize: 12,
            padding: "0px 55px",
          },
        },
        "& .LeftQutetion": {
          position: "absolute",
          top: 0,
          left: 100,
        },
        "& .rightQutetion": {
          position: "absolute",
          bottom: -25,
          right: 100,
        },
        "@media (max-width:600px)": {
          "& span": {
            padding: 21,
            fontWeight: 400,
            fontSize: 13,
          },
        },
      },
      "& .yellowCircle": {
        position: "absolute",
        right: "44%",
        zIndex: -"1",
        bottom: "-60px",

        "@media (max-width:768px)": {
          right: "39%",
        },
        "@media (max-width:600px)": {
          right: "26%",
          bottom: "-45px",
        },
        "@media (max-width:320px)": {
          right: "20%",
        },
      },
      "@media (max-width:600px)": {
        padding: 0,
        paddingTop: "21px",
        width: "100%",
        height: "auto",
      },
      "@media (max-width:1920)": {
        width: "100%",
      },
    },

    "& .react-multi-carousel-list": {
      height: "405px",

      "@media (max-width:768px)": {
        padding: "0px",
        height: "520px",
      },
      "@media (max-width:600px)": {
        height: "475px",
      },

      "& .AsvantagesImg": {
        "@media (max-width:600px)": {
          "& .advaContent": {
            right: 0,
            bottom: 11,
            "@media (min-width:1920px)": {
              right: 126,
              width: 260,
              height: 90,
              padding: 14,
              fontSize: 16,
            },
          },
        },
        "@media (max-width:768px)": {
          width: "95%",
        },
      },
    },
  },
}));

const Advantages = ({ portfolio }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const AdvantageData = ({ img, title }) => {
    return (
      <>
        <img className="AsvantagesImg" src={img} alt="" />
        <div className="advaContent">
          <p>
            <b>{title.bold}</b> {title.titleDesc}
          </p>
        </div>
      </>
    );
  };

  const responsiveHappy = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className={classes.AdContainer}>
      <h2 className="text-center title">
        Why CarBoli? <br /> Ismein Mera Kya Fayda?
      </h2>
      <div className="container carousel-class ">
        <Carousel
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={9000}
          infinite={true}
          focusOnSelect={false}
        >
          <AdvantageData
            img={"/Images/Avtar image 1.png"}
            title={{
              bold: "Hassle Free Process!",
              titleDesc:
                "Get the inspection at your doorstep and rest is on us.",
            }}
          />
          <AdvantageData
            img={"/Images/Avtar image 3.png"}
            title={{
              bold: "At Your Rate!",
              titleDesc: "Get the best rates through an auction process.",
            }}
          />
          <AdvantageData
            img={"/Images/Avtar image 2.png"}
            title={{
              bold: "Instant Payment!",
              titleDesc: "Get the money in your bank account instantly.",
            }}
          />
        </Carousel>
      </div>

      <div className="happySeller mt-5">
        <div className="HSInerContent">
          <h2 className="text-center">Journey So Far….</h2>
          <p className="text-center mt-4">
            Our client sent us a bunch of smiles with our Services and we love
            them
          </p>
          <Carousel
            responsive={responsiveHappy}
            arrows={true}
            autoPlay={true}
            autoPlaySpeed={9000}
            infinite={true}
            focusOnSelect={false}
          >
            <div>
              <div className="clintImg">
                <img src="./Images/rajiv.png" alt="" className="SideImg" />
                <div className="MiddelImg">
                  <img src="./Images/amit.jpg" alt="" />
                  <span>Amit Malik</span>
                  <span className="Address">Gurgaon , India</span>
                </div>
                <img src="./Images/sanjula.png" alt="" className="SideImg" />
              </div>
              <div className="HSContentArrow">
                <div className="StarContent">
                  <img src="./Images/Stars.png" alt="" id="starImg" />
                  <span className="text-center">
                    From start to end it been a hassle-free experience with best
                    rates. Great going team CarBoli.
                  </span>
                  <img
                    src="./Images/Quotation Symbol 1.png"
                    alt=""
                    className="LeftQutetion"
                  />
                  <img
                    src="./Images/Quotation Symbol 2.png"
                    alt=""
                    className="rightQutetion"
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="clintImg">
                <img src="./Images/amit.jpg" alt="" className="SideImg" />
                <div className="MiddelImg">
                  <img src="./Images/sanjula.png" alt="" />
                  <span>Sanjula Kalra</span>
                  <span className="Address">Indrapuram, India</span>
                </div>
                <img src="./Images/samaksh.png" alt="" className="SideImg" />
              </div>
              <div className="HSContentArrow">
                <div className="StarContent">
                  <img src="./Images/Stars.png" alt="" id="starImg" />
                  <span className="text-center">
                    First time I have sold my car and it has been such a great
                    experience. Thanks for the patience, explanation and
                    personal efforts you have put to answers my queries, it made
                    me learn a lot and gain confidence in you. You were like a
                    guiding angel for me.
                  </span>
                  <img
                    src="./Images/Quotation Symbol 1.png"
                    alt=""
                    className="LeftQutetion"
                  />
                  <img
                    src="./Images/Quotation Symbol 2.png"
                    alt=""
                    className="rightQutetion"
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="clintImg">
                <img src="./Images/sanjula.png" alt="" className="SideImg" />
                <div className="MiddelImg">
                  <img src="./Images/samaksh.png" alt="" />
                  <span>Samaksh Ahuja</span>
                  <span className="Address">Delhi, India</span>
                </div>
                <img src="./Images/rachna.png" alt="" className="SideImg" />
              </div>
              <div className="HSContentArrow">
                <div className="StarContent">
                  <img src="./Images/Stars.png" alt="" id="starImg" />
                  <span className="text-center">
                    I am always on a time crunch, but I am specially taking out
                    time to write this. Your App is just great, specially the
                    manage appointment section. It not only helped me swiftly
                    book an appointment but change later on as well. It was
                    actually a “3 step deal set” as you promoted it.
                  </span>
                  <img
                    src="./Images/Quotation Symbol 1.png"
                    alt=""
                    className="LeftQutetion"
                  />
                  <img
                    src="./Images/Quotation Symbol 2.png"
                    alt=""
                    className="rightQutetion"
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="clintImg">
                <img src="./Images/samaksh.png" alt="" className="SideImg" />
                <div className="MiddelImg">
                  <img src="./Images/rachna.png" alt="" />
                  <span>Rachna Chhabra</span>
                  <span className="Address">Delhi, India</span>
                </div>
                <img src="./Images/rajiv.png" alt="" className="SideImg" />
              </div>
              <div className="HSContentArrow">
                <div className="StarContent">
                  <img src="./Images/Stars.png" alt="" id="starImg" />
                  <span className="text-center">
                    I got your referral from a family friend who spoke very
                    highly about you. I must say everything he said was true,
                    you not only fulfil your promises but lived up to my high
                    expectation as well.
                  </span>
                  <img
                    src="./Images/Quotation Symbol 1.png"
                    alt=""
                    className="LeftQutetion"
                  />
                  <img
                    src="./Images/Quotation Symbol 2.png"
                    alt=""
                    className="rightQutetion"
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="clintImg">
                <img src="./Images/rachna.png" alt="" className="SideImg" />
                <div className="MiddelImg">
                  <img src="./Images/rajiv.png" alt="" />
                  <span>Rajeev Juneja</span>
                  <span className="Address">Gurgaon, India</span>
                </div>
                <img src="./Images/amit.jpg" alt="" className="SideImg" />
              </div>
              <div className="HSContentArrow">
                <div className="StarContent">
                  <img src="./Images/Stars.png" alt="" id="starImg" />
                  <span className="text-center">
                    I really liked your get a call option on your site. For
                    someone like me, who is not so tech savvy it was very easy.
                    Needless to say your team and services are the best.
                  </span>
                  <img
                    src="./Images/Quotation Symbol 1.png"
                    alt=""
                    className="LeftQutetion"
                  />
                  <img
                    src="./Images/Quotation Symbol 2.png"
                    alt=""
                    className="rightQutetion"
                  />
                </div>
              </div>
            </div>
          </Carousel>
          <img
            src="./Images/yellow circle bg.png"
            alt=""
            className="yellowCircle"
          />
        </div>
      </div>
    </div>
  );
};

export default Advantages;
