/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { APPOINTMENT_TIME } from "../firebase/getTimeSlots";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, TextField } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
	root: {
		flexWrap: "wrap",
		"& .MuiSelect-selectMenu": {
			fontFamily: "Montserrat, Regular",
			color: "#000000",
		},
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		minHeight: 50,
		top: -1,
	},
	timeSlots: {
		width: "103px",
		textAlign: "center",
		"@media (max-width:767px)": {
		  textAlign: "left",
		  width: "auto !important",
		},
	  },
	time: {
		"@media (max-width:365px)": {
			fontSize: 12,
			width: "80px",
		},
	},
}));

const CustomTimePickers = (props) => {
	const classes = useStyles();
	const { defaultValue, appointmentDate, onChange, time, error, helperText, onKeyUp } = props;
	const [value] = React.useState(defaultValue || null);
	const [menuItems, setMenuIteams] = React.useState([]);

	useEffect(() => {
		APPOINTMENT_TIME().then((time) => {
			setMenuIteams(time);
		});
	}, []);

	const getSlots = () => menuItems?.map((e) => {
		let time_1 = e.value.split("-");
		let appointmentTime = moment(time_1[1], ["h:mm A"]).format("HH:mm"); //Appointmet

		let currntTime = moment().add(2, "hours").format("HH:mm"); // cuurent time

		if (appointmentDate === moment().format("YYYY-MM-DD")) {
			if (appointmentTime > currntTime) {
				return (
					<MenuItem value={e.value}>
						<div style={{ display: "flex", width: "100%" }}>
							<div className={classes.timeSlots}>
								{e.label.slice(0, e.label.length - 2)}
							</div>
							<div className={classes.time}>
								{e.label.slice(e.label.length - 2, e.label.length)}
							</div>
						</div>
					</MenuItem>
				);
			}
		} else {
			return (
				<MenuItem value={e.value}>
					<div style={{ display: "flex", width: "100%" }}>
						<div className={classes.timeSlots}>
							{e.label.slice(0, e.label.length - 2)}
						</div>
						<div className={classes.time}>
							{e.label.slice(e.label.length - 2, e.label.length)}
						</div>
					</div>
				</MenuItem>
			);
		}
	})
	const getLabel = () => {
		const slots = getSlots();
		if (slots && !slots[slots.length - 1]) {
			return "No Slot Available!";
		}
		return "Appointment Time";
	}
	return (
		<div className={classes.root}>
			<TextField
				error={error}
				helperText={helperText}
				select
				labelid="demo-simple-select-label"
				id="demo-simple-select"
				value={time || value}
				label={getLabel()}
				variant="standard"
				fullWidth
				className={classes.textField}
				inputProps={{ style: { textAlign: "left" } }} // font size of input text
				InputLabelProps={{
					style: {
						fontSize: 12,
						color: "#1B154E ",
						fontFamily: "Montserrat, SemiBold",
					},
				}} // font size of input label
				onChange={onChange}
				onKeyUp={onKeyUp}
			>
				{getSlots()}
			</TextField>
		</div>
	);
};
export default CustomTimePickers;
