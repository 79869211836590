import {
	TextField,
	Typography,
	Box,
	Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import { Email, Phone, KeyboardArrowLeft } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import firebase from "../../config/Firebase";
import { getLeadId } from "../../firebase/method";
import { contactMail, createEvent, appointmentStatusMail, mobileSMS } from "../../firebase/sendMail";
import {
	isEmailValid,
	isMobileNumberValid,
	isNameValid,
} from "../../utils/validation";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomHeader from '../CustomHeader';
import { getWhatsappUsersDetails } from '../../firebase/getWhatsappUsers';


const useStyles = makeStyles((theme) => ({
	headingText: {
		color: "#1B154E ",
		marginLeft: 8,
		margin: "auto",
		position: "relative",
		fontSize: 20,
		"@media (max-width:600px)": {
			fontSize: 18,
		},
		"@media (max-width:320px)": {
			fontSize: 15,
			marginLeft: 0,
		},
		"&::before": {
			content: '""',
			width: "100px",
			height: "3px",
			background: "#FFD13B",
			position: "absolute",
			left: 25,
			bottom: "0",
			"@media (max-width:767px)": {
				width: "87px",
			},
		},
	},
	container: {
		width: "100%",
		minHeight: "67vh",
		height: "100%",
		padding: "20px 20px",
		background: "transparent",
		boxShadow: "inherit",
		backgroundColor: "#F8F8F8",
	},
	rootBox: {
		display: "flex",
		justifyContent: "space-between",
		"@media (max-width:768px)": {
			flexDirection: "column",
		},
	},
	rightSide: {
		height: "auto",
		width: "60%",
		backgroundColor: "#fff",
		"@media (max-width:768px)": {
			width: "100%",
		},
	},
	button: {
		backgroundColor: "#1c1451",
		color: "#fff",
		padding: "6px 33px",
		fontSize: "17px",
		fontWeight: 600,
		minWidth: 130,
		minHeight: 41,
		'&:hover': {
			backgroundColor: '#1b154ed1',
		},
	},
	textFieldBox: {
		"@media (max-width:767px)": {
			flexDirection: "column",
			width: "100%",
			marginBottom: 0,
		},
	},
	fieldBox: {
		"@media (max-width:768px)": {
			width: "100%",
			marginBottom: 16,
		},
	},
	arrowIcon: {
		"&:hover": {
			color: "#0d6efd",
		},
	},
	textField: {
		"& .MuiFormHelperText-root.Mui-error": {
			marginTop: 0,
			marginLeft: "0px",
		},
		"& label": {
			"&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
				backgroundColor: "#fff",
				top: "-5px",
				padding: "0 5px",
			},
		},
		"& .MuiOutlinedInput-multiline": {
			paddingRight: "0px !important",
		},
	},
	icon: {
		marginRight: 5,
	},
	formBox: {
		"@media (max-width:768px)": {
			padding: "20px 14px",
		},
	},
	contectDetail: {
		cursor: "pointer",
	},
	textColor: {
		color: "#1B154E ",
	},
	policyContainer: {
		'@media (max-width: 320px)': {
			padding: '20px 14px'
		}
	},
	logoImg: {
		'@media (max-width: 767px)': {
			marginLeft: '17px'
		}
	}
}));

const Support = () => {
	const theme = useTheme();
	const history = useHistory();
	const classes = useStyles(theme);
	const [firstName, setFirstName] = useState({
		value: "",
		error: false,
		errorText: "",
	});
	const [lastName, setLastName] = useState({
		value: "",
		error: false,
		errorText: "",
	});
	const [email, setEmail] = useState({
		value: "",
		error: false,
		errorText: "",
	});
	const [phone, setPhone] = useState({
		value: "",
		error: false,
		errorText: "",
	});
	const [description, setDescription] = useState({
		value: "",
		error: false,
		errorText: "",
	});
	const [loading, setLoading] = useState(false);
	const [disabled, setDisabled] = useState(false)

	const firstNameError = () => {
		const fnameError = isNameValid("firstName", firstName.value);
		setFirstName({
			...firstName,
			error: fnameError.error,
			errorText: fnameError.errorKey,
		});
	}
	const emailError = () => {
		const emailError = isEmailValid("email", email.value);
		setEmail({
			...email,
			error: emailError.error,
			errorText: emailError.errorKey,
		});
	}
	const phoneError = () => {
		const phoneError = isMobileNumberValid("phone", phone.value);
		setPhone({
			...phone,
			error: phoneError.error,
			errorText: phoneError.errorKey,
		});
	}
	const lastNameError = () => {
		const lnameError = isNameValid("firstName", lastName.value);
		setLastName({
			...lastName,
			error: lnameError.error,
			errorText: lnameError.errorKey,
		});
	}
	const checkError = () => {
		const emailError = isEmailValid("email", email.value);
		setEmail({
			...email,
			error: emailError.error,
			errorText: emailError.errorKey,
		});
		const phoneError = isMobileNumberValid("phone", phone.value);
		setPhone({
			...phone,
			error: phoneError.error,
			errorText: phoneError.errorKey,
		});
		const fnameError = isNameValid("firstName", firstName.value);
		setFirstName({
			...firstName,
			error: fnameError.error,
			errorText: fnameError.errorKey,
		});
		const lnameError = isNameValid("firstName", lastName.value);
		setLastName({
			...lastName,
			error: lnameError.error,
			errorText: lnameError.errorKey,
		});
		if (
			!fnameError.error &&
			!lnameError.error &&
			!phoneError.error &&
			!emailError.error
		) {
			return true;
		}
	}

	const getEmailSMS = async (whatsapp, formData) => {
		await contactMail(email.value, firstName.value, lastName.value, phone.value, description.value);
		await appointmentStatusMail(email.value, "new_lead", lastName.value);
		await mobileSMS(phone.value, "new_lead", formData)
		if (whatsapp?.isEnable) {
			createEvent({
				"userId": phone.value,
				"phoneNumber": phone.value,
				"countryCode": "+91",
				"event": "Lead Generation",
				"traits": {
					"status": "new_lead",
					"name": firstName.value
				}
			});
		}
	}

	const submitForm = async () => {
		if (!checkError()) {
			return;
		}
		setLoading(true);
		setDisabled(true);
		var leadId = null;
		let arr = [];
		arr.push({
			comment: description.value,
			role: "",
			name: ""
		});
		leadId = await getLeadId();
		var whatsapp = {};
		await getWhatsappUsersDetails(phone.value).then(data => {
			whatsapp = data;
		})
		let formData = {
			firstName: firstName.value,
			lastName: lastName.value,
			mobile: phone.value,
			email: email.value,
			comment: arr,
			leadID: leadId,
			createdAt: moment().format(),
			updatedAt: moment().format(),
			leadDate: moment().format("YYYY-MM-DD"),
			status: "new_lead",
		};
		firebase
			.database()
			.ref("appointments")
			.push(formData)
			.then((res) => {
				getEmailSMS(whatsapp, formData);
				setDisabled(false)
				history.push('/contact-detail');
				clearForm();
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				setDisabled(false);
			});
		setLoading(false);
	};

	const clearForm = () => {
		setFirstName({ ...firstName, value: "" });
		setLastName({ ...lastName, value: "" });
		setPhone({ ...phone, value: "" });
		setEmail({ ...email, value: "" });
		setDescription({ ...description, value: "" });
	};

	const checkSinner = (e) => {
		if (e.keyCode === 38 || e.keyCode === 40) {
			e.preventDefault();
		}
	}

	return (
		<>
			<CustomHeader />
			<Box p={5} className={classes.policyContainer}>
				<Box mt={2} mb={2}>
					<Typography variant="h6" className={classes.headingText}>
						<Link to="/" className="linktag">
							<KeyboardArrowLeft
								className={classes.arrowIcon}
								onClick={() => {
									history.push("/");
								}}
								style={{ marginTop: "-3px", cursor: "pointer" }}
							/>
							<span>Home </span>
						</Link>
            / Support
          </Typography>
				</Box>
				<Box className={classes.container}>
					<div className={classes.rootBox}>
						<div className="LeftSide">
							<h5>Customer Support Service</h5>
							<p className="mt-3">
								Ask us anything and we'll help you find the answers. <br />{" "}
                Response time within 24 hours
              </p>
							<h5 className="mt-5">Help</h5>
							<p className="mt-3">
								Need help with The CarBoli ? Send us a message <br /> and we'll
                get back to you at the earliest.
              </p>
							<p
								className={classes.contectDetail}
								onClick={() => {
									window.open("tel:+91 9795097250");
								}}
							>
								<Phone className="icon" /> +91-97950-97250
              </p>
							<p
								className={classes.contectDetail}
								onClick={() => {
									window.location.href = "mailto:info@carboli.com";
								}}
							>
								<Email className={classes.icon} />
                info@carboli.com
              </p>
						</div>
						<Box className={classes.rightSide}>
							<h5 className="text-center mt-3">Contact Us</h5>
							<Box p={1} className={classes.formBox}>
								<Box
									display="flex"
									width="100%"
									mb={2}
									className={classes.textFieldBox}
								>
									<Box width="48%" className={classes.fieldBox}>
										<TextField
											type="text"
											label="First Name"
											value={firstName.value}
											className={classes.textField}
											error={firstName.error}
											fullWidth
											helperText={firstName.errorText || ""}
											name="firstName"
											variant="outlined"
											onChange={(e) =>
												setFirstName({ ...firstName, value: e.target.value })
											}
											onKeyUp={firstNameError}
										/>
									</Box>
									<Box width="4%" />
									<Box width="48%" className={classes.fieldBox}>
										<TextField
											type="text"
											value={lastName.value}
											error={lastName.error}
											label="Last Name"
											className={classes.textField}
											fullWidth
											helperText={lastName.errorText || ""}
											variant="outlined"
											name="lastName"
											onChange={(e) =>
												setLastName({ ...lastName, value: e.target.value })
											}
											onKeyUp={lastNameError}
										/>
									</Box>
								</Box>
								<Box
									display="flex"
									width="100%"
									mb={2}
									className={classes.textFieldBox}
								>
									<Box width="48%" className={classes.fieldBox}>
										<TextField
											type="number"
											fullWidth
											variant="outlined"
											className={classes.textField}
											helperText={phone.errorText || ""}
											value={phone.value}
											error={phone.error}
											label="Mobile Number"
											onKeyDown={(e) => checkSinner(e)}
											name="phone"
											onChange={(e) =>
												setPhone({ ...phone, value: e.target.value })
											}
											onKeyUp={phoneError}
										/>
									</Box>
									<Box width="4%" />
									<Box width="48%" className={classes.fieldBox}>
										<TextField
											type="email"
											value={email.value}
											error={email.error}
											className={classes.textField}
											helperText={email.errorText || ""}
											label="Email"
											fullWidth
											variant="outlined"
											name="email"
											onChange={(e) =>
												setEmail({ ...email, value: e.target.value })
											}
											onKeyUp={emailError}
										/>
									</Box>
								</Box>
								<Box width="100%" className={classes.textFieldBox}>
									<TextField
										type="text"
										label="Description"
										className={classes.textField}
										multiline
										value={description.value}
										rows={3}
										fullWidth
										variant="outlined"
										name="description"
										onChange={(e) =>
											setDescription({ ...description, value: e.target.value })
										}
										onKeyUp={firstNameError}
									/>
								</Box>
								<Box display="flex" mt={2} justifyContent="center">
									<Button onClick={submitForm} className={classes.button}
										disabled={disabled}
									>
										{loading ? (
											<CircularProgress size={22} style={{ color: "#FFCF00" }} />
										) : (
												"Submit"
											)}
									</Button>
								</Box>
							</Box>
						</Box>
					</div>
				</Box>
			</Box>
		</>
	);
};

export default Support;
