import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import themes from "./themes/themeProvider";
import ThemeProviderCustom from "./themes";
// import Home from "./molecules/Home";
import LoginPage from "./molecules/LoginPage";
import Booking from "./molecules/Booking";
import AppointmentForm from "./components/AppointmentForm";
import Confirmation from "./components/BookingConfirmation";
import ManageAppointment from "./components/ManageAppointment";
// import Header from "./atoms/Header";
// import NewHeader from "./atoms/NewHeader";
import Footer from "./atoms/Footer/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import Support from "./Pages/Support/Support";
import PrivacyPolicy from "./Pages/PolicyAndCondition/PrivacyPolicy";
import TermsConditions from "./Pages/PolicyAndCondition/TermsConditions";
import Address from "./Pages/Address/Address";
import Home from "./Pages/Home/Home";
import Faq from "./Pages/Faq/Faq";
import ScrollToTop from "./atoms/ScrollToTop";
import BookingForm from './components/BookingForm';
import ContactDetail from "./Pages/contact-page/Contact"

function App() {
	return (
		<ThemeProviderCustom theme={themes}>
			<Router>
				<ScrollToTop />
				<Switch>
					<Route exact path="/login" component={LoginPage} />
					<>
						<Route exact path="/" component={Home} />
						<Route exact path="/booking" component={Booking} />
						<Route exact path="/appointment" component={AppointmentForm} />
						<Route exact path="/confirmation" component={Confirmation} />
						<Route exact path="/support" component={Support} />
						<Route exact path="/privacy" component={PrivacyPolicy} />
						<Route exact path="/condition" component={TermsConditions} />
						<Route exact path="/address" component={Address} />
						<Route exact path="/faq" component={Faq} />
						<Route exact path="/booking-form" component={BookingForm} />
						<Route exact path="/contact-detail" component={ContactDetail} />
						<Route
							exact
							path="/manage-appointment"
							component={ManageAppointment}
						/>
						<Footer />
					</>
				</Switch>
			</Router>
		</ThemeProviderCustom>
	);
}

export default App;
