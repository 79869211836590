/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Typography, Box, Card, TextField, Grid } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { uniqBy } from "lodash";

import { getVariantData } from "../firebase/getCarData";

const useStyles = makeStyles((theme) => ({
  variants: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  listContainer: {
    maxHeight: "39vh",
    overflow: "auto",
    height: "39vh",
  },
  heading: {
    color: "#1B154E ",
    fontSize: 17,
    fontWeight: "500",
  },
  button: {
    backgroundColor: "#E4E4E4",
  },
  searchTextBox: {
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px 10px 0px",
      "@media (max-width:365px)": {
        padding: "10px 0px 10px 0px",
      },
    },
    "& .MuiInputBase-inputTypeSearch": {
      "&::placeholder": {
        "@media (max-width:365px)": {
          fontSize: 12,
        },
        "@media (max-width:300px)": {
          fontSize: 10,
        },
      },
    },
  },
  variantText: {
    //paddingBottom: '5px',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    color: "#1B154E ",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  detailCard: {
    backgroundColor: "#F8F8F8",
    width: "100%",
    height: "60vh",
  },
  cardBox: {
    width: "100%",
    "@media (max-width:330px)": {
      width: "90%",
    },
  },
  list: {
    paddingTop: 0,
    padding: "10px",
  },
  searchIcon: {
    color: "#9B9B9B",
    marginRight: 8,
    "@media (max-width:300px)": {
      marginLeft: -8,
    },
  },
  toggleButtonContainer: {
    "& button": {
      marginRight: 16,
      backgroundColor: theme.palette.grey[300],
      border: "1px solid transparent !important",
      color: "#1B154E ",
      padding: "0",
      borderRadius: '4px !important',
      "& .MuiToggleButton-label": {
        padding: "0px 11px",
      },
    },
    "& :hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .Mui-selected": {
      backgroundColor: theme.palette.primary.main,
    },
    "@media (max-width:600px)": {
      display: "block",
    },
  },
  buttonContainer: {
    display: "flex",
    "@media (max-width:600px)": {
      display: "block",
    },
  },
  toggleButton: {
    marginTop: "5px",
  },
}));

const CarDetails = (props) => {
  const { variantOil, handleOil, handleVariant, step, selectedOilVariant, selectedYear } =
    props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const variants = [
    "1.0 MPI Trendline",
    "1.0 TSI Comfortline Turbo Edition",
    "1.0 TSI Highline Plus",
    "1.0 TSI Highline Plus AT",
    "1.0 TSI Highline Plus",
  ];
  const [oilSelection, setOilSelection] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [variant, setVariant] = useState([]);
  const [oilVariant, setOilVariant] = useState([]);
  const [oilType, setOilType] = useState([]);
  const [transmissionType, setTransmissionType] = useState([]);
  const [transmissionTypeSelection, setTransmissionTypeSelection] =
    useState("");

  useEffect(() => {
    setOilSelection(selectedOilVariant);
  }, [selectedOilVariant]);

  useEffect(() => {
    if (step === 4) {
      let oil = variant
        .filter((variants) => variants.parentId === variantOil.id)
        .filter((variants) => variants.type === selectedOilVariant)
        .filter(
          (variants) => variants.transmissionType === transmissionType[0]
        ).filter((variants) => variants.year.includes(selectedYear.toString()));
      
      setOilVariant([...oil, { name: 'Others' }]);
    }
  }, [step]);

  useEffect(()=> {
    if(oilSelection){
      let transTypes = variant.filter(
        (variants) => variants.parentId === variantOil.id && variants.type === oilSelection
      );
      transTypes = uniqBy(transTypes, (data) => data.transmissionType)
          .map((d) => d.transmissionType)
          .sort();
          setTransmissionType(transTypes);
    }
  }, [oilSelection]);

  useEffect(() => {
    getVariantData().then((variantList) => {
      if (variantList) {
        setVariant(variantList);
        let oilTypes = variantList.filter(
          (variants) => variants.parentId === variantOil.id
        );
        oilTypes = uniqBy(oilTypes, (data) => data.type)
          .map((d) => d.type)
          .sort();
        // setOilSelection(oilTypes[0]);
        const types = variantList.filter(
          (variants) => variants.parentId === variantOil.id
        );
        let transTypes = uniqBy(types, (data) => data.transmissionType)
          .map((d) => d.transmissionType)
          .sort();
        setTransmissionType(transTypes);
        setTransmissionTypeSelection(transTypes[0]);
        let selectedOilType = oilTypes[0];
        let updatedOilTypes = ['Petrol', 'Diesel', 'Electric', 'CNG', 'LPG'];
        updatedOilTypes = updatedOilTypes.filter((type)=> {
         return oilTypes.includes(type);
        })
        setOilType(updatedOilTypes);
        let oil = variantList
          .filter((variants) => variants.parentId === variantOil.id)
          .filter(
            (variants) =>
              variants.type.toLowerCase() === selectedOilType.toLowerCase()
          );
        // setOilVariant(oil)
      }
    });
  }, []);

  const handleOilSelection = (event, newValue) => {
    // const fuleType = newValue;
    // let oil = variant.filter(variants => variants.parentId === variantOil.id).filter(variants => variants.type === fuleType)
    setOilSelection(newValue);
    if (handleOil) {
      handleOil(newValue);
    }
    // setOilVariant(oil)
  };

  const handleTransmissionTypeSelection = (event, newValue) => {
    if (newValue === transmissionTypeSelection || !newValue) {
      return;
    }
    let oil = variant
      .filter((variants) => variants.parentId === variantOil.id)
      .filter((variants) => variants.type === oilSelection)
      .filter((variants) => variants.transmissionType === newValue)
      .filter((variants) => variants.year.includes(selectedYear.toString()));
    setTransmissionTypeSelection(newValue);
    setOilVariant([...oil, { name: 'Others' }]);
  };

  return (
    <Box className={classes.cardBox}>
      <Card className={classes.detailCard}>
        <Box mt={1} p={2}>
          <Box mb={1}>
            {step === 3 ? (
              <Typography className={classes.heading}>Fuel Type</Typography>
            ) : (
              <Typography className={classes.heading}>Car Variant</Typography>
            )}
          </Box>
          {step === 4 && (
            <Box>
              <TextField
                id="outlined-search"
                placeholder="Search Car Variant"
                type="search"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
                variant="outlined"
                fullWidth
                className={classes.searchTextBox}
                InputProps={{
                  startAdornment: <SearchIcon className={classes.searchIcon} />,
                }}
              />
            </Box>
          )}
        </Box>
        <Box className={classes.buttonContainer} ml={2}>
          {step === 4 ? (
            <ToggleButtonGroup
              orientation="horizontal"
              value={transmissionTypeSelection}
              exclusive
              size="small"
              onChange={handleTransmissionTypeSelection}
              className={classes.toggleButtonContainer}
            >
              {transmissionType &&
                transmissionType.length &&
                transmissionType.map((type) => (
                  <ToggleButton
                    value={type}
                    aria-label={type}
                    className={classes.toggleButton}
                  >
                    {type}
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>
          ) : (
            <ToggleButtonGroup
              orientation="horizontal"
              value={oilSelection}
              exclusive
              size="small"
              onChange={handleOilSelection}
              className={classes.toggleButtonContainer}
            >
              {oilType &&
                oilType.length &&
                oilType.map((type) => (
                  <ToggleButton
                    value={type}
                    aria-label={type}
                    className={classes.toggleButton}
                  >
                    {type}
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>
          )}
        </Box>

        {step === 4 && (
          <Grid container className={classes.listContainer}>
            <Grid item xs={12} className={classes.list}>
              {variantOil && variantOil.id
                ? oilVariant &&
                  oilVariant
                    .filter((variant) => {
                      if (searchTerm === "") return variant;
                      else if (
                        variant.name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return variant.name;
                      }
                      return null;
                    })
                    .map((variant, index) => (
                      <Box
                        key={index}
                        m={2}
                        className={classes.variants}
                        onClick={() =>
                          handleVariant &&
                          handleVariant(variant.name, transmissionTypeSelection)
                        }
                      >
                        <Typography
                          className={
                            variants.length - 1 !== index
                              ? classes.variantText
                              : classes.variantText
                          }
                        >
                          {variant.name}
                        </Typography>
                      </Box>
                    ))
                : variants &&
                  variants
                    .filter((variant) => {
                      if (searchTerm === "") return variant;
                      else if (
                        variant.toLowerCase().includes(searchTerm.toLowerCase())
                      ) {
                        return variant;
                      }
                      return null;
                    })
                    .map((variant, index) => (
                      <Box
                        key={index}
                        m={2}
                        className={classes.variants}
                        onClick={() => handleVariant && handleVariant(variant)}
                      >
                        <Typography
                          className={
                            variants.length - 1 !== index
                              ? classes.variantText
                              : classes.variantText
                          }
                        >
                          {variant}
                        </Typography>
                      </Box>
                    ))}
            </Grid>
          </Grid>
        )}
      </Card>
    </Box>
  );
};

export default CarDetails;
