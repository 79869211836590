const error = {
	error: false,
	errorKey: "",
};

export function isRequiredValid(key, value, errorKey) {
	const errorObj = { ...error };
	if (!value) {
		errorObj.error = true;
		errorObj.errorKey = errorKey || `ERRORS.${key.toUpperCase()}_REQUIRED`;
	}
	return errorObj;
}

export function isEmailValid(key, value, errorKey) {
	const errorObj = { ...error };
	if (!value) {
		errorObj.error = true;
		errorObj.errorKey = errorKey || "This Field is Required !";
	} else if (
		!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
			value
		)
	) {
		errorObj.error = true;
		errorObj.errorKey = errorKey || "Please Enter Valid Email !";
	}
	return errorObj;
}

export function isNameValid(key, value, errorKey) {
	const errorObj = { ...error };
	if (!value) {
		errorObj.error = true;
		errorObj.errorKey = errorKey || "This Field is Required !";
	}
	else if (!(value == null || /^\s*$/.test(value)) && !(/^[a-zA-Z ]*$/.test(value))) {
		errorObj.error = true;
		errorObj.errorKey = errorKey || "Please Enter Valid Name !";
	}
	return errorObj;
}

export function isMobileNumberValid(key, value, errorKey) {
	const errorObj = { ...error };
	if (!value) {
		errorObj.error = true;
		errorObj.errorKey = errorKey || "This Field is Required !";
	} else if (
		!/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[1-9]\d{9}$/.test(value)
	) {
		errorObj.error = true;
		errorObj.errorKey = errorKey || "Please Enter Valid Number !";
	}
	return errorObj;
}

export function isEmailRequired(key, value, errorKey) {
	const errorObj = { ...error };
	if (!value) {
		errorObj.error = false;
		errorObj.errorKey = "";
	} else if (
		!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
			value
		)
	) {
		errorObj.error = true;
		errorObj.errorKey = errorKey || "Please Enter Valid Email !";
	}
	return errorObj;
}

export function isRequired(key, value, errorKey) {
	const errorObj = { ...error };
	if (!value) {
		errorObj.error = true;
		errorObj.errorKey = errorKey || "This Field is Required !";
	}
	return errorObj;
}

export function isYearValid(key, value, errorKey) {
	const errorObj = { ...error };
	if (!value) {
		errorObj.error = true;
		errorObj.errorKey = errorKey || "This Field is Required !";
	} else if (
		!/^(197[5-9]|19[8-9]\d|200\d|201\d|202[0-2])$/.test(value)
	) {
		errorObj.error = true;
		errorObj.errorKey = errorKey || "Please enter year in-between 1975 - 2022 !";
	}
	return errorObj;
}