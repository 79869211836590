/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Box, Grid, Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import Loader from "../atoms/Loader";
import Header from "../atoms/Header";
import firebase from "../config/Firebase";
import { fetchAppointments } from "../firebase/getAppointmentData";
import AppointmentCard from "../atoms/AppointMentCard";
import CImage from "../atoms/CImage";
import rightImg from "../assets/images/Plant.svg";
import { getWhatsappUsersDetails } from '../firebase/getWhatsappUsers';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#fff",
	},
	image: {
		marginTop: 50,
		height: 200,
		width: "100%",
		marginBottom: 40,
	},
	bookAppointmentButton: {
		background: "#1B154E",
		color: "#fff !important",
		borderRadius: 10,
		float: "right",
		padding: "8px 8px",
		marginRight: "10%",
		cursor: "pointer",
		"@media (max-width:768px)": {
			marginRight: "0px",
		},
	},
	bookButton:{
		"@media (max-width:768px)": {
			marginRight: "0px",
			display: 'flex',
			justifyContent: 'center',
		},
	},
	loader: {
		width: "100%",
		height: "70vh",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	manageAppointment: {
		"@media (max-width:959px)": {
			flexDirection: "column-reverse",
		},
		"@media (max-width:800px)": {
			marginBottom: 0,
		},
		"@media (min-width:1680px) and (max-width:3000px) ": {
			marginBottom: 80,
		},
	},
	manageContainer: {
		'@media (max-width: 320px)': {
			padding: '40px 14px'
		}
	}
}));

const ManageAppointment = () => {
	const theme = useTheme();
	const classes = useStyles(theme);
	const history = useHistory();
	const [records, setRecords] = useState(null);
	const [loading, setLoading] = useState(true);
	const [regWhatsappNo, setRegWhatsappNo] = useState("");

	useEffect(() => {
		firebase.auth().onAuthStateChanged((user) => {
			if (!user) {
				history.push("/");
			} else {
				setRecords(user);
				const mobile = user?.phoneNumber?.substring(3)
				getWhatsappUsersDetails(mobile).then(data => {
					if (data) {
						setRegWhatsappNo(data);
					}
				})
			}
		});
	}, []);

	const [totalAppointments, setTotalAppointments] = useState([]);

	useEffect(() => {
		fetchAppointments().then((appointmentList) => {
			if (appointmentList) {
				setTotalAppointments(appointmentList);
				setLoading(false);
			}
		});
	}, []);

	const goToHome = () => {
		history.push("/booking");
	};

	let arr = [];
	const mobileNo = records?.phoneNumber?.substring(3);
	totalAppointments.forEach(e => {
		if (e.mobile === mobileNo) {
			arr.push(e);
		}
	})

	return (
		<>
			<Header />
			{loading ? (
				<Box className={classes.loader}>
					<Loader />
				</Box>
			) : (
					<Box p={5} bgcolor="background.paper" mb={0} className={classes.manageContainer}>
						<Grid container spacing={3} className={classes.manageAppointment}>
							<Grid item xs={12} md={7}>
								<AppointmentCard appointments={arr} 
								regWhatsappNo={regWhatsappNo}
								/>
							</Grid>
							<Grid item xs={12} md={5}>
								<Box mt={-2} className={classes.bookButton}>
									<Button
										variant="contained"
										color="primary"
										onClick={goToHome}
										className={classes.bookAppointmentButton}
									>
										Book Appointment
                </Button>
								</Box>
								<CImage imgSrc={rightImg} style={classes.image} />
							</Grid>
						</Grid>
					</Box>
				)}
		</>
	);
};

export default ManageAppointment;
