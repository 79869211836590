/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import {
	Box,
	Dialog,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	DialogContentText,
} from "@material-ui/core";
import firebase from "../../config/Firebase";
import { fetchNewsLetters } from "../../firebase/getNewsLetter";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { CheckIfEmailIsValid } from "../../firebase/method";
import { sendMailToUsers } from "../../firebase/sendMail";
import {
	Apple,
	Facebook,
	Instagram,
	MailOutline,
	WhatsApp,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	footer: {
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		marginTop: "40px",
		padding: "0px",
		background: '#1e1b50 !important',
		"@media (max-width:600px)": {
			padding: "30px 0px",
		},

		"@media (min-width:1920px)": {
			padding: "0 150px",
		},
		"& .left": {
			padding: " 0 40px",
		},
		"& .innerFooter ": {
			padding: "40px 100px",
			color: "#fff",
			overflowX: "hidden",
			"@media (max-width:360px)": {
				marginTop: '15px !important',
			},
			"& .para": {
				fontSize: "20px",
				fontWeight: "600",
				"@media (max-width:768px)": {
					fontSize: "14px",
				},
				"@media (max-width:600px)": {
					fontSize: "15px",
					fontWeight: "500",
				},
				"@media (max-width:375px)": {
					fontSize: "12px",
				},
				"& a": {
					marginLeft: "12px",
					"@media (max-width:320px)": {
						display: "table-cell",
						marginLeft: 0,
						paddingRight: 10,
						paddingTop: 10,
					},
					"& .paraIcon": {
						marginLeft: "0",
						color: "#fff",
					},
				},
			},
			"@media (max-width:1024px)": {
				padding: "40px 10px",
			},
			"@media (max-width:768px)": {
				padding: 0,
			},
			"@media (max-width:600px)": {
				padding: 0,
			},
			"& .LogoImg": {
				height: 58,
				"@media (max-width:768px)": {
					marginTop: 20,
				},
				"@media (max-width:600px)": {
					height: 40,
					marginTop: 0,
				},
			},
			"& .socialIcon": {
				display: "flex",
				justifyContent: "start",
				alignItems: "center",
				width: "60%",
				marginTop: 42,
				"@media (max-width:600px)": {
					width: "100%",
				},
			},
			"& .right": {
				padding: "0 40px",
				"@media (max-width:1024px)": {
					padding: "0 25px 0 0",
					marginLeft: -25
				},
				"@media (max-width:768px)": {
					padding: "0 20px 0 0",
				},
				"@media (max-width:600px)": {
					padding: "0 40px",
					marginLeft: '0px',
					marginTop: '-12px'
				},
			},

			"& .footerLinks": {
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center,",
				"@media (max-width: 320px)": {
					flexDirection: "column",
					marginTop: '20px !important'
				},
				"& a": {
					textDecoration: "none",
					color: "#ffffffa6",
					fontSize: 16,
					"@media (max-width:768px)": {
						fontSize: 12,
					},
					"@media (max-width:600px)": {
						fontSize: 11,
						color: "#fff",
					},
					"@media (max-width:360px)": {
						fontSize: 9,
					},
					"@media (max-width: 320px)": {
						marginBottom: 10,
					},
					"&:hover": {
						color: "#fff",
					},
				},
			},
			"& .footerEmail": {
				textAlign: "center",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",

				"& input": {
					width: 375,
					height: 46,
					border: "1px solid #fff",
					background: "#1c1451",
					color: "#fff",
					padding: "0 30px",
					outline: "none !important",
					"@media (max-width:767px)": {
						width: "100%",
						padding: "0 15px",
					},
					"@media (max-width:1320px)": {
						width: "315px",
					},
					"@media (max-width:1024px)": {
						width: "250px",
					},

					"@media (min-width:1100px)": {
						width: "204px",
					},
					"@media (min-width:1266px)": {
						width: "230px",
					},

					"@media (max-width:600px)": {
						width: "100%",
					},
					"@media (max-width:768px)": {
						width: "195px",
						padding: "0 15px",
					},
					"&::placeholder": {
						color: "#fff",
					},
				},
				"& button": {
					height: 47,
					width: 178,
					background: "#ffcf00",
					marginLeft: "-2px",
					borderRadius: "0px 10px 10px 0px",
					border: "none",
					"@media (max-width:1920px)": {
						marginTop: "20px",
					},
					"@media (max-width:1320px)": {
						marginTop: "20px",
					},
					"@media (max-width:768px)": {
						marginTop: "20px",
						// borderRadius: "10px",
					},
					"@media (max-width:530px)": {
						borderRadius: "10px",
						marginTop: "20px",
					},
				},
			},
			"& .Copyright": {
				fontSize: 12,
				color: "#ffffffa6",
				"@media (max-width:768px)": {
					paddingBottom: '35px'
				},
				"@media (max-width:600px)": {
					marginTop: "2rem!important",
					textAlign: "center",
					paddingBottom: '0px'
				},
				"@media (max-width:360px)": {
					fontSize: 11,
				},
			},
		},
		"& .FAQLink": {
			"@media (max-width:320px)": {
				marginTop: "0 !important",
			},
		},
	},
	androidIcon: {
		height: 22,
	},
	contactInfo: {
		marginTop: 20,
		display: "flex",
		flexWrap: "wrap",
		"& span": {
			marginRight: 20,
			paddingBottom: 8,
			cursor: "pointer",
			color: "#ffffffa6",
			display: "flex",
			alignItems: "center",
			"& svg": {
				marginRight: 5,
			},
			"@media (max-width:767px)": {
				marginRight: 15,
			},
			"@media (max-width:325px)": {
				marginRight: 5,
				fontSize: 12,
			},
			"&:hover": {
				color: "#fff",
			},
		},
		"@media (max-width:768px)": {
			padding: "13px 0",
			fontSize: "13px",
		},
		"@media (max-width:600px)": {
			padding: "13px 0",
			fontSize: "13px",
		},
		"@media (max-width:320px)": {
			display: "flex",
			flexWrap: "wrap",
			"& span": {
				marginBottom: 10,
			},
		},
	},
	socialMediaIcon: {
		fontSize: 24,
		fill: "#fff",
	},
	socialLinks: {
		// marginRight: 10,
	},
	textColor: {
		color: "#1B154E ",
	},
	whatsappLink: {
		color: "none !important",
		textDecoration: "none !important",
	},
	storeIconsBox: {
		marginTop: "20px",
	},
	appleIcon: {
		color: "#fff",
	},
	expCarboli: {
		marginTop: "8px",
		fontSize: "20px",
		fontWeight: "600",
		"@media (max-width:600px)": {
			fontSize: "15px",
			fontWeight: "500",
		},
		"@media (max-width:375px)": {
			fontSize: "12px",
		},
	},
}));

const Footer = () => {
	const theme = useTheme();
	const classes = useStyles(theme);
	const [email, setEmail] = useState("");
	const [newsLetters, setNewsLetters] = useState([]);
	const [open, setOpen] = React.useState(false);
	const [emailErr, setemailErr] = useState(false);
	const [disabled, setDisabled] = useState(false)

	useEffect(() => {
		fetchNewsLetters().then((newsList) => {
			if (newsList) {
				setNewsLetters(newsList);
			}
		});
	}, [email]);

	const getMail = async (email) => {
		await sendMailToUsers(email);
	}

	const handleClickOpen = async () => {
		setDisabled(true);
		let data = { email: email };
		var selectitem = newsLetters.filter((item) => item.email === email);
		if (selectitem.length === 0) {
			if (email !== "" && CheckIfEmailIsValid(email)) {
				firebase
					.database()
					.ref("newsLetters")
					.push({
						...data,
					})
					.then((res) => {
						getMail(email);
						handleEmailError(
							"Welcome to CarBoli! We have sent a welcome message to your e-mail address"
						);
						setDisabled(false);
					})
					.catch((err) => {
						console.log(err);
						setDisabled(false);
					});
			} else {
				handleEmailError("Email is not valid please try again!");
				setDisabled(false);
			}
		} else {
			handleEmailError("you have already subscribed");
			setDisabled(false);
		}
	};

	const handleClose = () => {
		setOpen(false);
		setEmail("");
	};

	const handleEmailError = (data) => {
		setemailErr(data);
		setOpen(true);
		setTimeout(() => {
			handleClose();
		}, 60000);
	};

	return (
		<div
			className={classes.footer}
			style={{ backgroundImage: `url("/Images/Bottom bg.png" ` }}
		>
			<div className="innerFooter mt-5">
				<div className="row">
					<div className="col-sm-12 col-md-6 col-lg-6 left">
						<Link to="/">
							<img src="./Images/logo 2.png" alt="logo" className="LogoImg" />
						</Link>
						<p className="mt-4 para">
							Experience CarBoli App
              <a
								href="https://play.google.com/store/apps/details?id=com.carboli_application"
								target="_blank"
								className={classes.socialLinks}
							>
								<img
									src="./Images/android.png"
									alt=""
									className={classes.androidIcon}
								/>
							</a>
							<Link to="/">
								<Apple className={classes.appleIcon} />
							</Link>
						</p>
						<p className="mt-4 para">
							Follow Us and Stay Connected
              <a
								href="https://www.facebook.com/CarBoli-111399864643170/"
								target="_blank"
								className={classes.socialLinks}
							>
								<Facebook className={classes.socialMediaIcon} />
							</a>
							<a
								href="https://instagram.com/carbolilive?utm_medium=copy_link"
								target="_blank"
								className={classes.socialLinks}
							>
								<Instagram className={classes.socialMediaIcon} />
							</a>
						</p>
					</div>
					<div className="col-sm-12 col-md-6 col-lg-6 right">
						<div className="footerLinks mt-5">
							<Link to="/support">
								<span>Support</span>
							</Link>
							<Link to="/address">
								<span>Address</span>
							</Link>
							<Link to="/privacy">
								<span>Privacy Policy</span>
							</Link>
							<Link to="/condition">
								<span>Terms & Conditions</span>
							</Link>
							<Link to="/faq">
								<span>FAQ</span>
							</Link>
						</div>
						{/* <div className="footerLinks mt-2 FAQLink">
              
            </div> */}
						<div className={classes.contactInfo}>
							<span
								onClick={() => {
									window.location.href = "mailto:info@carboli.com";
								}}
							>
								<MailOutline /> info@carboli.com
              </span>
							<span
								onClick={() => {
									window.open(
										"//api.whatsapp.com/send?phone=919795097250&text&app_absent=0"
									);
								}}
							>
								<WhatsApp /> +91 9795097250
              </span>
						</div>
						<div className="footerEmail mt-2">
							<div>
								<input
									placeholder="Email Address"
									value={email}
									name="email"
									onChange={(e) => setEmail(e.target.value)}
									InputLabelProps={{ shrink: false }}
								/>
								<button onClick={handleClickOpen}
									disabled={disabled}
								>Subscribe</button>
							</div>
						</div>
						<p className="mt-5 Copyright">
							Copyright © <span>CarBoil</span> All Rights Reserved
            </p>
					</div>
				</div>
			</div>
			<Box>
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title" className={classes.text}>
						{"CarBoli"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							id="alert-dialog-description"
							className={classes.textColor}
						>
							{emailErr}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className={classes.text}>
							Ok
            </Button>
					</DialogActions>
				</Dialog>
			</Box>
		</div>
	);
};

export default Footer;
