import firebase from "../config/Firebase/index";

export const APPOINTMENT_TIME = async () => {
	let timeSlots = [];
	return await firebase
	.database()
	.ref()
	.child("appointmentTimeSlots")
	.get()
	.then(function (data) {
	  if (data.exists()) {
		timeSlots = data.val();
		return timeSlots;
	  }
	})
	.catch(function (error) {
		console.error('==error===', error);
	});
  }