export const sendMailToUsers = async (email) => {
	const apiUrl = `https://us-central1-car-boli.cloudfunctions.net/welcomeMail?dest=${email}`;
	try {
		const requestOptions = {
			method: 'GET',
		};
		return await fetch(apiUrl, requestOptions).then((response) => {
			console.log('response send email', response);
		});
	} catch (e) {
		console.log(e);
	}
};

export const contactMail = async (email, firstName, lastName, phone, desc) => {

	const apiUrl =
		`https://us-central1-car-boli.cloudfunctions.net/contactMail?dest=${email}&fName=${firstName}&lName=${lastName}&phone=${phone}&desc=${desc}`;
	try {
		const requestOptions = {
			method: 'GET',
		};
		return await fetch(apiUrl, requestOptions).then((response) => {
			console.log('response send email', response);
		});
	} catch (e) {
		console.log(e);
	}
};


export const createUser = async (payload) => {
	const apiUrl =
		`https://api.interakt.ai/v1/public/track/users/`;
	try {
		const requestOptions = {
			method: 'POST',
			headers: new Headers({
				'Authorization': 'Basic dTVHbUtMTG1YWTBpNXBXZm5Vemc4a0M0U1RsVkZucHVFUklrek9yLUE3NDo=',
				'Content-Type': 'application/json'
			}),
			body: JSON.stringify(payload),
		};
		return await fetch(apiUrl, requestOptions).then((response) => {
			console.log('response send email', response);
		});
	} catch (e) {
		console.log(e);
	}
}

export const createEvent = async (payload) => {
	const apiUrl =
		`https://api.interakt.ai/v1/public/track/events/`;
	try {
		const requestOptions = {
			method: 'POST',
			headers: new Headers({
				'Authorization': 'Basic dTVHbUtMTG1YWTBpNXBXZm5Vemc4a0M0U1RsVkZucHVFUklrek9yLUE3NDo=',
				'Content-Type': 'application/json'
			}),
			body: JSON.stringify(payload),
		};
		return await fetch(apiUrl, requestOptions).then((response) => {
			console.log('response send email', response);
		});
	} catch (e) {
		console.log(e);
	}
}

export const appointmentStatusMail = async (email, statusId, fName) => {
	const apiUrl = `https://us-central1-car-boli.cloudfunctions.net/appointmentStatusMail?dest=${email}&status=${statusId}&lastName=${fName}`;
	try {
		const requestOptions = {
			method: 'GET',
		};
		return await fetch(apiUrl, requestOptions).then((response) => {
			console.log('response send email', response);
		});
	} catch (e) {
		console.log(e);
	}
}

export const mobileSMS = async (phone, statusId, data) => {
	let message = "";
	if (statusId === "new_lead") {
		message = newLeadSms(data.firstName ? data.firstName : "");
	}
	if (statusId === "cancel") {
		message = inspectionCancelled(data.firstName ? data.firstName : "", data.make, data.model);
	}
	if (statusId === "inspection_rescheduled") {
		message = inspectionRescheduled(data.appointmentDate, data.appointmentTime, data.address, data.make, data.model, data.firstName ? data.firstName : "");
	}
	if (statusId === "newUserRegidtration") {
		message = newRegisteredUser();
	}
	if (statusId === "inspection_scheduled") {
		message = inspectionScheduled(data.appointmentDate, data.appointmentTime, data.address, data.make, data.model, data.firstName ? data.firstName : "");
	}


	const apiUrl = `https://us-central1-car-boli.cloudfunctions.net/sendTextMessage?phone=${phone}`;
	try {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ message: message })
		};
		return await fetch(apiUrl, requestOptions).then((response) => {
			console.log('response send sms', response);
		});
	} catch (e) {
		console.log(e);
	}
}

export const newLeadSms = (fname) => {
	const message = `Hi ${fname},
Greetings from Team Carboli! 
Thank you for reaching out to us. Our car consultant will contact you shortly to schedule a free inspection of your car at a date & time of your convenience. 

You may also use our Mobile Application or Website to book the inspection in just 6 easy steps by clicking the button below.

We look forward to selling your car instantly at the best price, from the comfort of your home.

Thank You,
Team CarBoli

https://carboli.com/

https://play.google.com/store/apps/details?id=com.carboli_application`;
	return message;
};

export const inspectionCancelled = (fname, make, model) => {
	const message = `Hi ${fname},

The evaluation of your ${make} ${model} has been cancelled.

To reschedule the evaluation to another time, click on the link below or contact us at 📞 9795097250 .

Thank You,
Team CarBoli

https://carboli.com/

https://play.google.com/store/apps/details?id=com.carboli_application`;
	return message;
};

export const inspectionRescheduled = (date, time, address, make, model, fname) => {
	const message = `Hi ${fname},

The evaluation of your ${make} ${model} has been rescheduled.

Date & Time : ${date} & ${time}
Location : ${address}

Kindly keep the following documents ready for a seamless evaluation:
1.RC/Registration Certificate
2.Car Insurance
3.PUC or Pollution Certificate
4.Duplicate Key (If Available)
5.warranty, service records, and invoices (where available)

For any queries, contact us at 📞 9795097250

Thank You,
Team CarBoli`;
	return message;
};

export const newRegisteredUser = () => {
	const message = `Hi,

Welcome to CarBoli and thank you for registering with us. With CarBoli get the best deal for your car and the convenience of being at your home. We are eagerly waiting to serve you!!

Thanks,

Team CarBoli`
	return message;
}

export const inspectionScheduled = (date, time, address, make, model, fname) => {
	const message = `Hi ${fname},
The evaluation of your ${make} ${model}  has been confirmed 
Date & Time : ${date} & ${time}

Location : ${address}

Kindly keep the following documents ready for a seamless evaluation:

1. RC/Registration Certificate
2. Car Insurance
3. PUC or Pollution Certificate
4. Duplicate Key (If Available)
5. Warranty, service records, and invoices (where available)

You can also manage (book, amend or cancel) your appointments through our Mobile Application or the Website.

For any queries, contact us at 📞 9795097250

Thank You,
Team CarBoli`;
	return message;
};