/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({

	zipzap: {
		fontSize: '30px',
		fontWeight: '500',
		lineHeight: '1.5'
	},

	linkColor: {
		color: 'white',
		cursor:'pointer',
		'&:hover': {
			color: '#ffd001'
		}
	},
	carBilWorksContainer: {
		height: "850px",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		"@media (max-width:768px)": {
			height: "740px",
		},
		"@media (max-width:600px)": {
			height: "820px",
			marginTop: "0 !important",
		},

		"& .InnerContent": {
			padding: "60px 30px",
			color: "#fff",
			"@media (max-width:1024px)": {
				padding: "24px 16px",
			},
			"@media (max-width:768px)": {
				padding: "24px 16px",
			},
			"@media (max-width:600px)": {
				padding: "30px 0px",
			},
			"& .title": {
				fontSize: 32,
				fontWeight: 500,
				padding: "15px 0px",
				"@media (max-width:600px)": {
					fontSize: 25,
				},
			},
			"& .desc": {
				fontSize: 20,
				fontWeight: 500,
				marginTop: 30,
				lineHeight: 1.4,

				"@media (max-width:1920px)": {
					fontSize: 22,
					marginTop: 13,
				},
				"@media (max-width:600px)": {
					fontSize: 16,
					marginTop: 13,
				},
			},
			"& .ImgAndPara": {
				width: 280,
				height: "auto",
				"@media (max-width:767px)": {
					'& .text-center': {
						textAlign: 'left !important'
					},
					width: 230,
				},
				"@media (max-width:600px)": {
					height: 155,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: "100%",
					marginTop: "2rem !important",
				},
        "@media (min-width: 768px) and (max-width:1024px)": {
					width: 200,
				},
				"& h2": {
					"@media (max-width:600px)": {
						display: "grid",
						placeItems: "center",
					},
				},
        '& h4': {
          "@media (max-width:320px)": {
						fontSize: "14px",
					},
        },
				"& p": {
					"@media (max-width:1920px)": {
						fontSize: "16px",
						textAlign: "center",
					},
					"@media (max-width:768px)": {
						textAlign: "inherit",
					},
					"@media (max-width:600px)": {
						margin: "0px",
						fontSize: "15px",
					},
					"@media (max-width:320px)": {
						fontSize: "12px",
					},
					"@media (min-width: 768px) and (max-width:1024px)": {
						textAlign: "left !important",
						fontSize: 14
					},
				},
				"& img": {
					"@media (max-width:768px)": {
						width: "inherit",
					},
					"@media (max-width:600px)": {
						width: "165px",
					},
          "@media (max-width: 320px)": {
						width: "135px",
					},
				},
			},
			"& .man2": {
				marginTop: "70px !important",
				"@media (max-width:600px)": {
					marginTop: "20px !important",
					"& .ImgAndPara": {
						"@media (max-width:600px)": {
							flexDirection: "row-reverse",
						},
					},
				},
			},
			"& .man3": {
				marginTop: "120px !important",
				"@media (max-width:600px)": {
					marginTop: "20px !important",
				},
			},
		},
	},
	bookAppLink: {
		color: "#fff",
		"&:hover": {
			color: "#ffd001",
		},
	},
	text:{
		textAlign:'left !important'
	}
}));

const CarBoilWorks = () => {
	const theme = useStyles();
	const classes = useStyles(theme);
	const history = useHistory();

	const WorkProcess = ({ img, no, className, title }) => {
		return (
			<div className={`col-sm-12 col-md-4 col-lg-4 ${className}`}>
				<div className="ImgAndPara mt-5">
					<img src={img} alt="" />
					<div className="text-center">
						<h4>{no}</h4>
						<p className={classes.text}>{title}</p>
					</div>
				</div>
			</div>
		);
	};
	return (
		<div
			className={classes.carBilWorksContainer}
			style={{ backgroundImage: `url("/Images/bg.jpg" ` }}
		>
			<div className="container">
				<div className="InnerContent mt-5">
					<h1 className="title text-center">How CarBoli Works</h1>
					<p className="desc text-center">
						Sell your car in 3 easy steps <br />
						<span className={classes.zipzap}>Zip! Zap! Zoom!</span>
						<br /> Your car is Sold!
          </p>
					<div className="row">
						<div className={`col-sm-12 col-md-4 col-lg-4 man1`}>
							<div className="ImgAndPara mt-5">
								<img src="/Images/Man 1.png" alt="" />
								<div className="text-center">
									<h4>Book An Appointment</h4>
									<p className={classes.text}>
										Use our{" "}
										<a
											className={classes.linkColor}
											href="https://play.google.com/store/apps/details?id=com.carboli_application"
											target="_blank"
											rel="noreferrer"
										>
											Mobile App
                    </a>{" "}
                    to book an appointment or just request for a{" "}
										<a 
											className={classes.linkColor}
											onClick={() => {
												history.push('/support');
											}}
										>
											Call Back
                    </a>
                    .
                  </p>
								</div>
							</div>
						</div>
						<WorkProcess
							className={"man2"}
							img={"/Images/man 2.png"}
							no={"Valuation Check"}
							title={
								"Our representative will visit your doorstep to inspect your car."
							}
						/>
						<WorkProcess
							className={"man3"}
							img={"/Images/man 3.png"}
							no={"Get Offers"}
							title={
								"Get the best price through our auction and get paid instantly."
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CarBoilWorks;
