/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button, Card, Checkbox } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import CustomSelect from "../atoms/CustomSelect";
import CustomInput from "../atoms/CustomInput";
import CustomNumber from "../atoms/CustomNumberInput";
import CImage from "../atoms/CImage";
import rightImg from "../assets/images/carManimg2.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Header from "../atoms/Header";
import {
	isRequired,
	isYearValid,
} from "../utils/validation";

const useStyles = makeStyles((theme) => ({
	text: {
		color: "#1B154E ",
		marginLeft: 8,
	},
	highlightText: {
		fontSize: "19px",
		paddingLeft: "6px",
		fontWeight: "600",
	},
	headingText: {
		color: "#1B154E ",
		marginLeft: 24,
		top: 20,
		margin: "auto",
		position: "relative",
		fontSize: 26,
		fontWeight: "600",
		"@media(max-width: 768px)": {
			marginLeft: "23px",
			fontSize: 20,
		},
		"&::before": {
			content: '""',
			width: "80px",
			height: "3px",
			background: "#FFD13B",
			position: "absolute",
			left: 0,
			bottom: "0",
		},
	},
	button: {
		background: "#1B154E",
		width: 200,
		borderRadius: 10,
		color: "#fff !important",
		"&:hover": {
			background: "#FFCF00",
		},
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "center",
		marginTop: 16,
	},
	imgMedia: {
		width: "100%",
		height: "auto",
		maxHeight: 445,
	},
	card: {
		backgroundColor: "#F8F8F8",
		position: "relative",
	},
	errormsg: {
		color: "red",
		marginLeft: 8,
		fontSize: 12,
	},
	appointmentSection: {
		padding: "20px",
		"@media(max-width: 768px)": {
			//flexDirection: 'column-reverse'
		},
	},
	formContainer: {
		"@media(max-width: 768px)": {
			padding: "0px",
			paddingBottom: "40px",
		},
		"@media (max-width:800px)": {
			marginBottom: 0,
		},
		"@media (min-width:1680px) and (max-width:3000px) ": {
			marginBottom: 180,
		},
	},
	dateContainer: {
		"@media(max-width: 767px)": {
			marginTop: -16,
			display: "flex",
			flexDirection: "column",
			"& div": {
				width: "100%",
				textAlign: "left",
			},
		},
	},
	locationBox: {
		marginTop: -8,
		marginBottom: 16,
		"@media(max-width: 650px)": {
			marginTop: 8,
		},
	},
	appTime: {
		"@media(max-width: 650px)": {
			marginTop: -8,
		},
	},
	appointmentDate: {
		"@media(max-width: 650px)": {
			marginTop: 16,
		},
	},
}));

const BookingForm = (props) => {
	const theme = useTheme();
	const history = useHistory();
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const location = useLocation();
	const classes = useStyles(theme);
	const [defaultValue] = useState(location && location.state);
	const [carDetail] = useState(location.state || null);
	const [carDetailsForm, setCarDetailsForm] = useState({
		make: "",
		model: "",
		year: '',
		oilVariant: "",
		variant: "",
		transMissionType: "",
		// isScrap: false,
		isOther: true,
		...defaultValue
	});
	const [brandName, setBrandName] = useState({
		value: "",
		error: false,
		errorText: "",
	});
	const [modelName, setModelName] = useState({
		value: "",
		error: false,
		errorText: "",
	});
	const [year, setYear] = useState({
		value: "",
		error: false,
		errorText: "",
	});
	const [fuleType, setFuleType] = useState({
		value: "",
		error: false,
		errorText: "",
	});
	const [transmissionType, setTransmissionType] = useState({
		value: "",
		error: false,
		errorText: "",
	});
	const [variant, setVariant] = useState({
		value: "",
		error: false,
		errorText: "",
	});

	const oilTypes = [
		{ value: 'Petrol', label: 'Petrol' },
		{ value: 'Diesel', label: 'Diesel' },
		{ value: 'Electric', label: 'Electric' },
		{ value: 'CNG', label: 'CNG' },
		{ value: 'LPG', label: 'LPG' }
	];

	const transmissionTypes = [
		{ value: 'Manual', label: 'Manual' },
		{ value: 'Automatic', label: 'Automatic' },
	]

	useEffect(() => {
		if (defaultValue) {
			setBrandName({ ...brandName, value: defaultValue?.make });
			setModelName({ ...modelName, value: defaultValue?.model });
			setYear({ ...year, value: defaultValue?.year });
			setVariant({
				...variant,
				value: defaultValue?.variant,
			});
			setTransmissionType({ ...transmissionType, value: defaultValue?.transMissionType });
			setFuleType({ ...fuleType, value: defaultValue?.oilVariant });
		}
	}, []);


	const brandNameError = () => {
		const bnameError = isRequired("brandName", brandName.value);
		setBrandName({
			...brandName,
			error: bnameError.error,
			errorText: bnameError.errorKey,
		});
	};

	const modelNameError = () => {
		const mnameError = isRequired("modelName", modelName.value);
		setModelName({
			...modelName,
			error: mnameError.error,
			errorText: mnameError.errorKey,
		});
	};

	const yearError = () => {
		const yearError = isYearValid("year", year.value);
		setYear({
			...year,
			error: yearError.error,
			errorText: yearError.errorKey,
		});
	};

	const variantError = () => {
		const variantError = isRequired("variant", variant.value);
		setVariant({
			...variant,
			error: variantError.error,
			errorText: variantError.errorKey,
		});
	};
	const fuleTypeError = (value) => {
		const fuleTypeError = isRequired("fuleType", value);
		setFuleType({
			...fuleType,
			value: value,
			error: fuleTypeError.error,
			errorText: fuleTypeError.errorKey,
		});
		setCarDetailsForm({ ...carDetailsForm, oilVariant: value });
	};

	const transTypeError = (value) => {
		const transTypeError = isRequired("transmissionType", value);
		setTransmissionType({
			...transmissionType,
			value: value,
			error: transTypeError.error,
			errorText: transTypeError.errorKey,
		});
		setCarDetailsForm({ ...carDetailsForm, transMissionType: value });
	}

	const checkError = () => {
		const bnameError = isRequired("brandName", brandName.value);
		setBrandName({
			...brandName,
			error: bnameError.error,
			errorText: bnameError.errorKey,
		});
		const mnameError = isRequired("modelName", modelName.value);
		setModelName({
			...modelName,
			error: mnameError.error,
			errorText: mnameError.errorKey,
		});
		const yearError = isYearValid("year", year.value);
		setYear({
			...year,
			error: yearError.error,
			errorText: yearError.errorKey,
		});

		const variantError = isRequired("variant", variant.value);
		setVariant({
			...variant,
			error: variantError.error,
			errorText: variantError.errorKey,
		});

		const fuleTypeError = isRequired("fuleType", fuleType.value);
		setFuleType({
			...fuleType,
			error: fuleTypeError.error,
			errorText: fuleTypeError.errorKey,
		});

		const transTypeError = isRequired("transmissionType", transmissionType.value);
		setTransmissionType({
			...transmissionType,
			error: transTypeError.error,
			errorText: transTypeError.errorKey,
		});
		if (
			!bnameError.error &&
			!mnameError.error &&
			!yearError.error &&
			!variantError.error &&
			!fuleTypeError.error &&
			!transTypeError.error
		) {
			return true;
		}
	};

	const submitDetails = () => {
		if (!checkError()) {
			return;
		}
		history.push({
			pathname: '/appointment',
			state: {
				...carDetailsForm,
			}
		})
	}

	const onYearChange = (e) => {
		if (e.target?.value?.length > 4) {
			return false;
		}
		setYear({ ...year, value: e.target.value });
		setCarDetailsForm({ ...carDetailsForm, year: e.target.value });
	}
	return (
		<>
			<Header />
			<Box
				p={5}
				pl={10}
				bgcolor="background.paper"
				mb={3}
				className={classes.formContainer}
			>
				<Box mb={2}>
					<Typography variant="h6" className={classes.headingText}>
						Car Details
          </Typography>
				</Box>
				<Grid
					container
					className={classes.appointmentSection}
					spacing={isSmall ? 0 : 4}
				>
					<Grid item xs={12} sm={12} md={6}>
						<Card className={classes.card}>
							<Box p={3}>
								<Box mb={2}>
									<CustomInput
										name="Brand Name"
										label="Brand Name"
										error={brandName.error}
										helperText={brandName.errorText || ""}
										onChange={(e) => {
											setBrandName({ ...brandName, value: e.target.value });
											setCarDetailsForm({ ...carDetailsForm, make: e.target.value });
										}}
										defaultValue={brandName.value}
										onKeyUp={brandNameError}
									/>
								</Box>
								<Box mb={2}>
									<CustomInput
										name="Model Name"
										label="Model Name"
										error={modelName.error}
										helperText={modelName.errorText || ""}
										onChange={(e) => {
											setModelName({ ...modelName, value: e.target.value });
											setCarDetailsForm({ ...carDetailsForm, model: e.target.value });
										}}
										defaultValue={modelName.value}
										onKeyUp={modelNameError}
									/>
								</Box>
								<Box mb={1}>
									<CustomNumber
										name="year"
										label="Year"
										error={year.error}
										helperText={year.errorText || ""}
										onChange={onYearChange}
										defaultValue={year.value}
										onKeyUp={yearError}
									/>
								</Box>
								<Box>
									<CustomSelect
										name="Fule Type"
										onChange={(e) => fuleTypeError(e.target.value)}
										label="Fule Type"
										menuItems={oilTypes}
										defaultValue={fuleType.value}
										error={fuleType.error}
										helperText={fuleType.errorText || ""}
									/>
								</Box>
								<Box mt={-1}>
									<CustomSelect
										name="variant"
										onChange={(e) => transTypeError(e.target.value)}
										label="Transmission Type"
										menuItems={transmissionTypes}
										defaultValue={transmissionType.value}
										error={transmissionType.error}
										helperText={transmissionType.errorText || ""}
									/>
								</Box>
								<Box>
									<CustomInput
										name="variant"
										error={variant.error}
										helperText={variant.errorText || ""}
										onChange={(e) => {
											setVariant({
												...variant,
												value: e.target.value,
											});
											setCarDetailsForm({ ...carDetailsForm, variant: e.target.value });
										}}
										label="Variant"
										defaultValue={variant.value}
										onKeyUp={variantError}
									/>
								</Box>
								{/* <Box display="flex" mt={2}>
									<Box className={classes.checkBox}>
										<Checkbox
											label=""
											onChange={(e) => {
												setCarDetailsForm(
													{ ...carDetailsForm, isScrap: e.target.checked }
												);
											}}
											checked={carDetailsForm.isScrap}
										/>
									</Box>
									<Box display="flex" alignItems="center">
										<Typography className={classes.bottomHeading}
											style={{ marginTop: 3 }}
										>
											Select if your car is scrapped model
                    						</Typography>
									</Box>
								</Box> */}
								<Box className={classes.buttonContainer}>
									<Button
										onClick={() => submitDetails()}
										className={classes.button}
									>{
											location.state && location.state.mode === "edit" ?
												"Update Details" : "Submit Details"
										}
									</Button>
								</Box>
							</Box>
						</Card>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<CImage imgSrc={rightImg} style={classes.imgMedia} />
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default BookingForm;
