/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Typography, Box } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import Plant from "../assets/images/Plant.svg";
import Computer from "../assets/images/image 1.svg";
import Car from "../assets/images/image 3.svg";
import AdvantageBlock from "./AdvantageBlock";

const useStyles = makeStyles((theme) => ({
  text: {
    color: "#1B154E ",
    "& .MuiTypography-caption": {
      fontSize: 24,
    },
    "& .titleData": {
      fontSize: 19,
    },
  },
  img: {
    width: "90%",
    height: 125,
  },
  bookAppLink: {
    color: "#1B154E",
	cursor:'pointer'
  },
}));

const LoginRightSide = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const car = Car;
  const plant = Plant;

  const Data = () => {
    return (
      <>
        <p>
          Use our{" "}
          <a
            href="https://play.google.com/store/apps/details?id=com.carboli_application"
            target="_blank"
            className={classes.bookAppLink}
            rel="noreferrer"
          >
            Mobile App
          </a>{" "}
          to book an appointment or just request for a{" "}
          <a
            onClick={() => {
				history.push('/support');
			}}
            className={classes.bookAppLink}
          >
            Call Back
          </a>
          .
        </p>
      </>
    );
  };

  return (
    <Box>
      <Box mb={2}>
        <Typography align="center" variant="h6" className={classes.text}>
          <span className="titleData">
            You are one step closer to sell your car
          </span>
          <br />
          <span>
            <b>Zip! Zap! Zoom!</b>
          </span>
        </Typography>
      </Box>
      <AdvantageBlock
        direction="row"
        textAline="left"
        image={Computer}
        style={classes.img}
        width="50%"
        heading="Book An Appointment"
        subHeading={<Data />}
      />
      <AdvantageBlock
        direction="row-reverse"
        image={plant}
        style={classes.img}
        textAline="left"
        width="50%"
        heading="Valuation Check"
        subHeading="Our representive will inspect you car."
      />
      <AdvantageBlock
        direction="row"
        image={car}
        style={classes.img}
        textAline="left"
        width="50%"
        heading="Get Offers"
        subHeading="Get the best price through our auction and get paid instantly."
      />
    </Box>
  );
};

export default LoginRightSide;
