import React from "react";
import {
	makeStyles,
	useTheme,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	logoImg: {
		marginLeft: '60px',
		'@media (max-width: 767px)': {
			marginLeft: '17px !important'
		}
	},
	support: {
		backgroundColor: '#1c1451',
		width: '100%',
		height: 110,
	},
	logoImgContainer:{
		display: 'grid',
		placeItems: 'center start',
		height: 'inherit'
	},
	img:{
		height:45
	}
}));

const CustomHeader = () => {
	const theme = useTheme();
	const classes = useStyles(theme);
	const history = useHistory();

	const goToHome = () => {
		history.push("/");
	};

	return (
			<div className={classes.support}>
				<div className={classes.logoImgContainer}>
					<div onClick={goToHome} style={{ cursor: "pointer" }} className={classes.logoImg}>
						<img src="./Images/logo 2.png" alt="logo" className={classes.img} />
					</div>
				</div>
			</div>
	);
};

export default CustomHeader;
