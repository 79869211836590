import { Box, Typography } from "@material-ui/core";
import React from "react";
import { KeyboardArrowLeft } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";

import CustomHeader from "../CustomHeader";

const useStyles = makeStyles((theme) => ({
  headingText: {
    color: "#1B154E ",
    marginLeft: 8,
    margin: "auto",
    position: "relative",
    fontSize: 20,
    "@media (max-width:600px)": {
      fontSize: 18,
    },
    "@media (max-width:320px)": {
      fontSize: 15,
      marginLeft: 0,
    },
    "&::before": {
      content: '""',
      width: "95px",
      height: "3px",
      background: "#FFD13B",
      position: "absolute",
      left: 25,
      bottom: "0",
    },
  },
  faqBox: {
    width: "100%",
    height: "59vh",
    background: "transparent",
    boxShadow: "inherit",
    backgroundColor: "#F8F8F8",
  },
  listContainer: {
    // float: "left",
    height: "100%",
    overflowY: "scroll",
    paddingLeft: "20px",
    paddingTop: "30px",
    paddingRight: "20px",
    wordBreak: "break-word",
	"@media (max-width:600px)": {
		paddingLeft: "0px",
	  },
  },
  head: {
    color: "#1B154E ",
    fontSize: 24,
    fontWeight: "500",
    marginBottom: 16,
    marginTop: 16,
    listStyle: "none",
    display: "flex",
  },
  content: {
    color: "#1B154E ",
    fontSize: 16,
    fontWeight: "500",
  },
  innerContent: {
    color: "#1B154E ",
    fontSize: 14,
    fontWeight: "500",
    marginTop: 12,
  },
  arrowIcon: {
    "&:hover": {
      color: "#0d6efd",
    },
  },
  faqContainer: {
    "@media (max-width: 320px)": {
      padding: "20px 14px",
    },
  },
  logoImg: {
    "@media (max-width: 767px)": {
      marginLeft: "17px",
    },
  },
  lists: {
    "& li": {
      marginTop: 8,
      listStyle: "none",
    },
  },
  subHead: {
    fontWeight: "600",
    marginTop: 16,
  },
  listsIteams: {
    "& li": {
      marginTop: 6,
      listStyle: "inside",
    },
  },
  breakLineMargin: {
    marginLeft: 27,
    "@media (max-width: 767px)": {
      marginLeft: 0,
    },
  },
  breakLineMargin35: {
    marginLeft: 39,
    "@media (max-width: 767px)": {
      marginLeft: 0,
    },
  },
  spanContent: {
    flex: "1",
    paddingLeft: 5,
  },
}));

const Faq = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  return (
    <>
      <CustomHeader />
      <Box p={5} className={classes.faqContainer}>
        <Box mt={2} mb={2}>
          <Typography variant="h6" className={classes.headingText}>
            <Link to="/" className="linktag">
              <KeyboardArrowLeft
                className={classes.arrowIcon}
                onClick={() => {
                  history.push("/");
                }}
                style={{ marginTop: "-3px", cursor: "pointer" }}
              />
              <span>Home </span>
            </Link>
            / FAQ
          </Typography>
        </Box>
        <Box className={classes.faqBox}>
          <Box className={classes.listContainer}>
            <ul>
              <li className={classes.head}>
                <span>1.</span>
                <span className={classes.spanContent}>
                  What are the benefits of selling your car to Carboli?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  <b>a. Sell your car in a single visit: </b>
                  No more tedious negotiations with multiple buyers over a
                  period of several weeks. Book a home inspection on Carboli.com
                  and sell your car in precisely one visit!
                </li>
                <li>
                  <b>b. Get instant money transfer: </b>
                  Carboli transfers your money straight to your bank account on
                  the same day.
                </li>
                <li>
                  <b>c. Get free RC transfer: </b>
                  We handle all the necessary documentation needed to get your
                  RC transferred, that too free of cost. Plus, every seller is
                  protected by the Carboli Seller Shield until the RC transfer.
                </li>
                <li>
                  <b>d. Get the best price: </b>
                  It’s the aggregate of research and on-ground experience that
                  have made us an expert when it comes to used car pricing.
                </li>
              </ul>
            </ul>

            <ul>
              <li className={classes.head}>
                <span>2.</span>
                <span className={classes.spanContent}>
                  How does Carboli’s Evaluation Process work?
                </span>
              </li>

              <ul className={classes.lists}>
                <p>The Evaluation process consists of two steps.</p>
                <li>
                  <b>a. Physical Inspection: </b>
                  Vehicle is inspected on various aspects to check physical
                  condition.
                </li>
                <li>
                  <b>b. Test Drive: </b>A small test drive is taken to check
                  roadworthiness and performance of engine and gearbox.
                </li>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>
                <span>3.</span>
                <span className={classes.spanContent}>
                  Do you Offer Home Inspection?
                </span>
              </li>

              <ul className={classes.lists}>
                <li>
                  Yes, at present we are available in both Delhi and NCR. Please
                  schedule a inspection from the comfort of your home. We will
                  visit at a time convenient to you, inspect the car, get you
                  the best price, and take it forward.
                </li>
                <li>
                  Please go to carboli.com and book a home inspection visit or
                  call us on 97950-97250.
                </li>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>
                <span>4.</span>
                <span className={classes.spanContent}>
                  What is the process for Home Inspection?
                </span>
              </li>

              <ul className={classes.lists}>
                <li>
                  After the appointment is booked, you will get an SMS/ Whatsapp
                  and on the day of the inspection, a confirmation call. Our
                  trained evaluator will visit you and will inspect your car
                  which usually takes 30-45 minutes. You will likely get a price
                  quote within 1-2 hours of that. If you agree to the price, the
                  documentation can be completed at your house during a separate
                  visit.
                </li>
                <li>
                  If you want to reschedule, you could call the number provided
                  to you on your Whatsapp / SMS. Please note that while we will
                  always try to accommodate a reschedule, a reschedule on the
                  same day may not always be possible, depending on prior
                  appointments of our evaluator, in which case the appointment
                  may have to shift to a subsequent day. It is therefore
                  requested that in case of a reschedule, you do it a day in
                  advance.
                </li>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>5. What are your timings?</li>
              <ul className={classes.lists}>
                <li>
                  We usually operate from 8 am to 6:00 pm. While scheduling
                  appointments on the website, you will be able to see the
                  operating dates and timings.
                </li>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>
                <span>6.</span>{" "}
                <span className={classes.spanContent}>
                  What should I do to reschedule my appointment?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  It's simple, give us a call on 97950-97250 and one of our
                  dedicated customer care executives will help you reschedule.
                  Or you could simply login to your account and reschedule.
                </li>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>
                <span>7.</span>
                <span className={classes.spanContent}>
                  What documents do I need to bring to sell my car?
                </span>
              </li>
              <ul className={classes.listsIteams}>
                <p>Here’s a list of documents you need to keep handy</p>
                <p className={classes.subHead}>
                  Basic Car Documents to have with you:
                </p>
                <li>
                  RC/Registration Certificate (this is essential to start
                  inspection)
                </li>
                <li>Car Insurance</li>
                <li>PUC or Pollution Certificate</li>
                <li>Duplicate Key (if available)</li>
                <li>
                  Warranty, service records, and invoices (where available)
                </li>
                <p className={classes.subHead}>Situational documents:</p>
                <li>
                  If your car is on loan, please keep loan documents handy
                </li>
                <li>
                  If your car has been transferred interstate, keep the RTO NOCs
                  with you
                </li>
                <li>
                  Clear documentation does have a big role to play in car
                  valuation!
                </li>
                <p className={classes.subHead}>Personal Documents:</p>
                <li>PAN Card</li>
                <li>Government-issued address proof (eg Aadhar)</li>
                <li>2 passport size photos</li>
                <li>Cancelled cheque (to allow us to make the payments)</li>
              </ul>
              <p style={{ marginTop: 8, paddingLeft: '2rem' }}>
                Note- these documents including the cancelled cheque should be
                of the car owner i.e., whose name is on the RC.
              </p>
              <ul className={classes.listsIteams}>
                <p className={classes.subHead}>
                  In case it’s a Company Owned Car:
                </p>
                <li>An authorization letter from the company</li>
                <li>Company’s address proof</li>
                <li>
                  Company’s PAN card, as well as the PAN card of the authorized
                  person signing the letter
                </li>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>
                <span>8.</span>
                <span className={classes.spanContent}>
                  How long will my car inspection take?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  Our evaluators are trained to check around 200 points of car
                  condition and record them in very quick time. On average, we
                  tend to finish a car inspection within 30-45 minutes.
                </li>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>
                <span>9.</span>
                <span className={classes.spanContent}>
                  Does the car owner need to be present for the inspection?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  It is possible to do an inspection without your personal
                  presence. However, you’ll have to be there to sign off the
                  documents, also, the final payment will be made only in the
                  name and account of the RC holder.
                </li>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>
                <span>10.</span>
                <span className={classes.spanContent}>
                  What is the process post deal finalization?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  Once the token is paid to you, you must complete all the
                  formalities within 1 day and handover the car to us. Failing
                  this, you will be liable to refund us the token amount + an
                  additional amount of INR 2,500 (can be waived in case of minor
                  delay not exceeding a day). A processing charge of INR 500/day
                  will be levied, if the stockin is delayed beyond 3 days
                  (excluding cases wherein it is delayed for NOC/LFC/Challan
                  etc.).
                </li>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>
                <span>11.</span>
                <span className={classes.spanContent}>
                  Does the amount offered and the final payment amount differ?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  The selling price you agree upon and the final payment you
                  receive will differ in the following cases:
                </li>
                <li>
                  When there are some documents missing or are not available at
                  the time of sale, we withhold certain amounts (which are
                  refundable when the documents are made available within a
                  TAT). Examples include:
                  <ul className={classes.listsIteams}>
                    <li>
                      Holdbacks for party presence requirements (only for some
                      RTOs)
                    </li>
                    <li>
                      Bank NOC in case of hypothecation on the car (only for
                      cars where there is a loan)
                    </li>
                    <li>
                      Holdbacks in case of inter-state vehicles (till documents
                      are verified)
                    </li>
                  </ul>
                </li>
                <li>
                  We also charge some fees. Examples include:
                  <ul className={classes.listsIteams}>
                    <li>
                      Nominal service charges depending on the value of your car
                    </li>
                    <li>RC not available /missing/ faded/ mismatch</li>
                    <li>Bank NOC collection charges</li>
                    <li>Payment of pending challan/s</li>
                    <li>Duplicate key missing</li>
                    <li>No/expired car insurance</li>
                  </ul>
                </li>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>
                <span>12.</span>
                <span className={classes.spanContent}>
                  What are your modes of payment?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  At present, we have 3 modes of payment IMPS, NEFT, and RTGS
                  (Real Time Gross Settlement).
                </li>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>
                <span>13.</span>
                <span className={classes.spanContent}>
                  When is the payment made to me?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  The entire process should get complete within the same day. In
                  a few cases, it may get extended to 1-2 days. We do not
                  collect the car before the payment is made.
                </li>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>
                <span>14.</span>
                <span className={classes.spanContent}>
                  Is the payment done in parts?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  No. At Carboli, we transfer the entire amount in one go via
                  IMPS, NEFT or RTGS (Real Time Gross Settlement).
                </li>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>
                <span>15.</span>
                <span className={classes.spanContent}>
                  How long does it take for the RC of the car to be transferred?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  The timeline of RC transfer might vary depending on the
                  availability of the prospective buyer and the RTO
                  requirements. However, you don't need to worry about it at
                  all. All our customers are covered under the Carboli Seller
                  Shield, which means until your car's RC transfer is complete,
                  we take full responsibility for the safety of your vehicle.
                  Once the ownership has been transferred, we will send you an
                  email with the proof of RC transfer.
                </li>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>
                <span>16.</span>
                <span className={classes.spanContent}>
                  Is my physical presence required at RTO during RC transfer
                  process?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  Yes, wherever required, the Customer must cooperate and
                  support for all requisite formalities for the smooth transfer
                  of ownership of the Vehicle which may include without
                  limitation personal appearances before government authorities
                  including the RTO (Party Peshi).
                </li>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>
                <span>17.</span>
                <span className={classes.spanContent}>
                  What make of cars do you purchase?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  At Carboli, we help sell cars of all makes and models aside
                  from commercial cars.
                </li>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>
                <span>18.</span>
                <span className={classes.spanContent}>
                  Which vehicles are considered as Scrap?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>The following vehicles come under the scrap category:</li>
                <ul className={classes.listsIteams}>
                  <li>Registration certificate has expired</li>
                  <li>Vehicle not in running condition</li>
                  <ul>
                    <li>
                      a) Vehicle damaged beyond repairs/Burnt (Accidental /Total
                      Loss)
                    </li>
                    <li>
                      b) Engine, Chassis number not traceable, rusted beyond
                      recognition.
                    </li>
                  </ul>
                </ul>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>
                <span>19.</span>
                <span className={classes.spanContent}>
                  Why should I sell at Carboli.com? Why shouldn’t I directly
                  contact the dealer?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>Here are some of the reasons why:</li>
                <ul className={classes.listsIteams}>
                  <li>No listings, no annoying phone calls</li>
                  <li>Unbeatable resale value for your car</li>
                  <li>Instant money transfer</li>
                  <li>Hassle-free RC transfer</li>
                  <li>Loan closure assistance</li>
                  <li>Delivery receipt on company letterhead</li>
                </ul>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>
                <span>20.</span>
                <span className={classes.spanContent}>
                  What will happen if my car is financed by a bank and I want to
                  sell it to Carboli?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  If the car you want to sell is financed, simply bring along a
                  current dated foreclosure letter issued by the bank and we
                  will pay the loan on your car. The settlement amount will be
                  deducted from the Carboli’s quote for your car. Alternatively,
                  you can settle the loan yourself before selling the car to us.
                  Carboli may hold back a certain amount until the bank loan
                  clearance NOC is submitted by you.
                </li>
              </ul>
            </ul>
            <ul>
              <li className={classes.head}>
                <span>21.</span>
                <span className={classes.spanContent}>
                  Will you charge me for my car's inspection?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>No, Carboli inspection process is absolutely free.</li>
              </ul>
            </ul>

            <ul>
              <li className={classes.head}>
                <span>22.</span>
                <span className={classes.spanContent}>
                  What documents should do I need for my car's inspection?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  It is important that you bring the following documents with
                  you to proceed with the inspection of your car:
                </li>
                <ul className={classes.listsIteams}>
                  <li>Original RC (Mandatory)</li>
                  <li>Original Invoice</li>
                  <li>Duplicate Keys</li>
                  <li>Service Manual</li>
                  <li>Last Service Receipt</li>
                  <li>Extended Warranty Certificate (if applicable)</li>
                </ul>
              </ul>
            </ul>

            <ul>
              <li className={classes.head}>
                <span>23.</span>
                <span className={classes.spanContent}>
                  Can I get a better price later on?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  The price we offer for your car depends on the current market
                  condition for that particular make and model. The inspection
                  report we provide after assessing your car is only valid for
                  24 hours. After 24 hours, we re-initiate the inspection
                  process of your car and try to offer the best possible price.
                </li>
              </ul>
            </ul>

            <ul>
              <li className={classes.head}>
                <span>24.</span>
                <span className={classes.spanContent}>
                  What all factors impact the price of my car?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  Car price is impacted by car condition as well as market
                  demand for that model.
                </li>
              </ul>
            </ul>

            <ul>
              <li className={classes.head}>
                <span>25.</span>
                <span className={classes.spanContent}>
                  What happens if I cancel the deal after signing the documents?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  We strive to offer a hassle-free experience to our customers
                  as well as our channel partners. If you wish to cancel the
                  deal after the token amount has been paid, you will have to
                  return the token amount and pay a cancellation charge of Rs.
                  5000. This charge is to compensate for the overhead cost of
                  the entire transaction and any monetary loss that our channel
                  partner who booked this car incurred due to this cancellation.
                  Please remember, no cancellation is possible once the delivery
                  amount has been paid.
                </li>
              </ul>
            </ul>

            <ul>
              <li className={classes.head}>
                <span>26.</span>
                <span className={classes.spanContent}>
                  What if I don't get the amount within the stipulated time?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  We make sure to process the total amount once all the
                  documentation is complete. Please note that some amount could
                  be held back if all the requirements are not met. If you wish
                  to understand any hold back amount on your invoice, please
                  reach out to us at 97950-97250.
                </li>
              </ul>
            </ul>

            <ul>
              <li className={classes.head}>
                <span>27.</span>
                <span className={classes.spanContent}>
                  Where can I check my current RC transfer status of my car?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  We will keep you updated with the current status of the
                  ownership of your car.You can also verify the same at
                  www.parivahan.gov.in
                </li>
              </ul>
            </ul>

            <ul>
              <li className={classes.head}>
                <span>28.</span>
                <span className={classes.spanContent}>
                  What happens to my car after you buy it?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  Carboli works on a customer to business model wherein, after
                  we buy your car, we sell it to one of our channel partners
                  across India. Our channel partners further sell it to an end
                  user.
                </li>
              </ul>
            </ul>

            <ul>
              <li className={classes.head}>
                <span>29.</span>
                <span className={classes.spanContent}>
                  What is the Carboli Seller Shield?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  Carboli’s Seller Shield is a programme offered by Carboli to
                  protect the customers selling their car from any unwarranted
                  post-sale situations. This means that till the car’s
                  Registration Certificate (RC) is transferred in the name of
                  the buyer, we will take care of any hassles related to the
                  car, for sellers covered under the policy.
                </li>
                <li>
                  f there is any unforeseen incident with your car while the RC
                  transfer is in process, our legal experts will extend full
                  support, and all legal and financial expenses* are covered by
                  us. All you have to do is call on 97950-97250. We take full
                  responsibility for safeguarding the interests of our sellers
                  before the ownership of their cars is transferred.
                </li>
              </ul>
            </ul>

            <ul>
              <li className={classes.head}>
                <span>30.</span>
                <span className={classes.spanContent}>
                  Who is covered under the Carboli Seller Shield?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  The Customers (Registered Owners of the Vehicle) who have
                  provided the physical delivery of the vehicle to Carboli or
                  have taken the RC transfer facility from Carboli are covered
                  under this policy.
                </li>
              </ul>
            </ul>

            <ul>
              <li className={classes.head}>
                <span>31.</span>
                <span className={classes.spanContent}>
                  What is covered under Carboli Seller Shield?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  The Carboli Seller Shield provides you with threefold benefits
                  once you sell your car with us.
                </li>
              </ul>
              <ul className={classes.lists}>
                <li>
                  <b>Ready assistance and information </b>
                  at every step till the RC of the Vehicle is transferred to the
                  new owner
                </li>
                <li>
                  <b>Full Legal support </b>
                  in the rarest of the rare scenario where there is an issue
                  with the Vehicle while the transfer is in the process without
                  any lawyer/counsel expenses or charges
                </li>
                <li>
                  <b>Full financial coverage </b>
                  for any expenses incurred due to challan or an
                  accident/untoward incident with the car.
                </li>
              </ul>
            </ul>

            <ul>
              <li className={classes.head}>
                <span>32.</span>
                <span className={classes.spanContent}>
                  Is the Carboli Seller Shield an insurance programme for my
                  car?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  No, the Seller Shield is to protect and safeguard the concerns
                  or issues of our Sellers against any legal troubles that they
                  might have to bear till the RC of the respective Vehicle is
                  transferred to the Buyer.
                </li>
              </ul>
            </ul>

            <ul>
              <li className={classes.head}>
                <span>33.</span>
                <span className={classes.spanContent}>
                  Does the Seller Shield cost anything? What is the coverage
                  period?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  You are automatically covered under our Seller Shield once you
                  sell your car to Carboli or opt for RC Transfer Facility from
                  Carboli. The coverage is applicable from the date of physical
                  delivery of Vehicle to Carboli or the date on the RC Transfer
                  Facility Form, whichever is applicable, till the RC ownership
                  is transferred in name of Buyer. You don’t have to pay any
                  premium for the same.
                </li>
              </ul>
            </ul>

            <ul>
              <li className={classes.head}>
                <span>34.</span>
                <span className={classes.spanContent}>
                  Whom do I reach out if I have any queries regarding ownership
                  transfer after selling my car?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  We understand your concern and we are there to assist you in
                  every way till your car’s RC is transferred in the name of the
                  buyer. You simply call us at 97950-97250 or send an email at
                  info@carboli.com and we will take care of everything for you.
                </li>
              </ul>
            </ul>

            <ul>
              <li className={classes.head}>
                <span>35.</span>
                <span className={classes.spanContent}>
                  What happens if there is any mishap while the car's RC is not
                  transferred? How can I avail the benefits of this Shield?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  In case of any occurrence of any untoward incident, while the
                  RC is in your name, Carboli will provide you full legal
                  support and financial support* for the same. You can raise
                  your concern with us at our helpline number. Our Legal Support
                  team will get in touch with you and get the issue resolved for
                  you as soon as possible.
                </li>
              </ul>
            </ul>

            <ul>
              <li className={classes.head}>
                <span>36.</span>
                <span className={classes.spanContent}>
                  What all will the Shield protect me from?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  Carboli Seller Shield assures to protect you (Registered
                  Owner) from
                </li>
                <ul className={classes.listsIteams}>
                  <li>
                    Any issue(s) which may arise against you from any misuse of
                    the Vehicle
                  </li>
                  <li>
                    Any case which may be lodged against you in case of any
                    mishap/ accident involving the Vehicle
                  </li>
                  <li>
                    Carboli will engage a lawyer at its own cost (s) to
                    represent you before and with any judicial / quasi-judicial
                    / law enforcement agencies, wherever required
                  </li>
                </ul>
              </ul>
            </ul>

            <ul>
              <li className={classes.head}>
                <span>37.</span>
                <span className={classes.spanContent}>
                  My query is not listed, need help further!
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  No worries, Kindly call us on 97950-97250 and our team will be
                  happy to help you.
                </li>
              </ul>
            </ul>

            <ul>
              <li className={classes.head}>
                <span>38.</span>
                <span className={classes.spanContent}>
                  How do you ensure my private information remains confidential?
                </span>
              </li>
              <ul className={classes.lists}>
                <li>
                  We value our customers’ confidentiality and ensure that no
                  information is leaked to any third party under any
                  circumstance.
                </li>
              </ul>
            </ul>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Faq;
