import React, { useState } from 'react'
import { Box, Typography, makeStyles, useTheme, Grid, Card, TextField } from "@material-ui/core"
import moment from "moment"
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
	yearListContainer: {
		cursor: 'pointer',
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		margin: '0px 4px',
	},
	listContainer: {
		maxHeight: '38vh',
		overflow: 'auto',
		height: '38vh'
	},
	detailCard: {
		backgroundColor: "#F8F8F8",
		width: '100%',
		height: '60vh'
	},
	cardBox: {
		width: '100%'
	},
	searchIcon: {
		color: "#9B9B9B",
		marginRight: 8,
		'@media (max-width:300px)': {
			marginLeft:-8
		}
	},
	heading: {
		color: "#1B154E ",
		fontSize: 17,
		fontWeight: '500'
	},
	yearText: {
		color: '#1B154E ',
		fontSize: ' 15px',
		fontWeight: '500',
		'&:hover': {
			color: theme.palette.primary.main
		}
	},
	list: {
		paddingTop: 0,
		padding: '10px'
	},
	searchTextBox: {
		'& .MuiOutlinedInput-input': {
			padding: '10px 14px 10px 0px',
			'@media (max-width:365px)': {
				padding: '10px 0px 10px 0px',
			}
		},
		'& .MuiInputBase-inputTypeSearch': {
			'&::placeholder': {
				'@media (max-width:365px)': {
					fontSize: 12,
				},
				'@media (max-width:300px)': {
					fontSize:10
				}
			}
		}
	},
}))

const YearList = (props) => {
	const {
		years = [],
		startYear = 2000,
		endYear = moment().year(),
		handleClick
	} = props

	const [yearList,] = useState(Array.from(Array(endYear - startYear),
		(x, index) => (startYear + index + 1)))
	const theme = useTheme()
	const classes = useStyles(theme)
	const [searchTerm, setSearchTerm] = useState('')

	return (
		<Box className={classes.cardBox}>
			<Card className={classes.detailCard}>
				<Box mt={1} p={2}>
					<Box mb={1}>
						<Typography className={classes.heading}>Car Registration Year (as per RC)</Typography>
					</Box>
					<Box>
						<TextField id="outlined-search"
							placeholder="Search Car Year"
							type="search"
							className={classes.searchTextBox}
							onChange={(event) => { setSearchTerm(event.target.value) }}
							variant="outlined"
							fullWidth
							InputProps={{
								startAdornment: (
									<SearchIcon
										className={classes.searchIcon}
									/>
								),
							}}
						/>
					</Box>
				</Box>
				<Grid container className={classes.listContainer}>
					<Grid item xs={12} className={classes.list}>
						{
							years.length !== 0 ?
								(years && years.filter((years) => {
									if (searchTerm) {
										return ('' + years).startsWith(searchTerm)
									}
									return years
								}).sort()
									.map((year, index) => (
										<Box
											p={1}
											key={index}
											className={classes.yearListContainer}
											onClick={() => handleClick && handleClick(year)}
										>
											<Typography variant="h5"
												className={((yearList.length - 1) !== index ? classes.yearText : classes.yearText)}

											>
												{year}
											</Typography>
										</Box>
									))
								)
								: (yearList && yearList.filter((years) => {
									if (searchTerm) {
										return ('' + years).startsWith(searchTerm)
									}
									return years
								})
									.map((year, index) => (
										<Box
											p={1}
											key={index}
											className={classes.yearListContainer}
											onClick={() => handleClick && handleClick(year)}
										>
											<Typography variant="h5"
												className={((yearList.length - 1) !== index ? classes.yearText : classes.yearText)}

											>
												{year}
											</Typography>
										</Box>
									))
								)
						}

					</Grid>
				</Grid>
			</Card>
		</Box>
	)
}

export default YearList
