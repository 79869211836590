import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  media: {
    height: "auto",
  },
}));

const CImage = (props) => {
	const {
        imgSrc = '',
        style = '',
        type = 'logo'
    } = props;

  const classes = useStyles()
  const [src, setSrcPath] = useState(imgSrc)
  useEffect(() => {
	  setSrcPath(imgSrc)
  }, [imgSrc])

  return (
	  <img 
		  src={src} 
		  className={classes.media + ' ' + style} 
		  alt="not selected"
		  onError={() => setSrcPath(`./assets/images/${type}.png`)}
	  />
  )
};

export default CImage;
