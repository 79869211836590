import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
	root: {
		flexWrap: "wrap"
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: 0,
		marginBottom: -1
	},
	resize: {
		fontSize: 12,
	},
}));

const CustomPhoneNumberInput = (props) => {
	const classes = useStyles();
	const { name, label, defaultValue, onChange, helperText, error, onKeyUp, onKeyDown } = props;

	if (name !== "leadID")
		return (
			<div className={classes.root}>
				<div>
					<TextField
						error={error}
						helperText={helperText}
						display="flex"
						flexdirection="row"
						label={label}
						id="standard-full-width"
						fullWidth
						type="number"
						className={classes.textField}
						variant="standard"
						value={defaultValue}
						onChange={onChange}
						margin="normal"
						inputProps={{ style: { fontSize: 14, color: '#1B154E ' } }} // font size of input text
						InputLabelProps={{ style: { fontSize: 11, color: '#1B154E ', fontFamily: 'Montserrat, SemiBold' } }} // font size of input label
						endAdornment={<InputAdornment position="end"></InputAdornment>}
						onKeyUp={onKeyUp}
						onKeyDown={onKeyDown}
					/>
				</div>
			</div>
		);
	else return <></>;
};
export default CustomPhoneNumberInput;
