import React, { useEffect, useState } from "react";
import {
	Box,
	Typography,
	Button,
	Grid,
} from "@material-ui/core";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";

import AppLogo from "../assets/images/logo.png";
import firebase from "../config/Firebase";
import CImage from "./CImage";

const useStyles = makeStyles((theme) => ({
	root: {
		background: "#ced6dc",
	},
	divider: {
		top: "32vh",
		height: 3,
	},
	appLogo: {
		width: "170px",
		height: "auto",
		display: "inline-flex",
		marginLeft: " 10px",
		cursor: "pointer",
	},
	number: {
		color: "#1B154E ",
	},
	media: {
		backgroundImage: "url(./images/bg_header.png)",
		float: "left",
		width: "100%",
		backgroundSize: "cover",
		height: "100%",
		backgroundRepeat: "no-repeat",
	},
	headerMedia: {
		backgroundImage: "url(./images/bg_header.png)",
		float: "left",
		width: "100%",
		height: "29vh",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		position: 'relative',
	},
	LogoutButton: {
		float: "right",
		padding: "8px 8px",
		marginTop: "20px",
		marginRight: "10%",
		cursor: "pointer",
		borderRadius: "10px",
		'@media (max-width: 767px)': {
			marginRight: 10,
			marginTop: 15
		}
	},
	logoSection: {
		float: "left",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: " center",
		position: 'absolute',
		top: '50%',
		marginTop: -33,
		"& span": {
			width: "100%",
			height: "2px",
			background: "#1B154E ",
			float: "left",
			marginTop: "23px",
		},
	},
	logoutIcon: {
		minWidth: "auto",
	},
	userIcon: {
		marginTop: 35,
		display: "flex",
		flexDirection: "column",
		alignItems: 'center',
		"@media (max-width:1024px)": {
			flexDirection: "row",
			width: "100%",
			alignItems: "center",
			marginTop: "0px",
			padding: "10px",
			borderBottom: "1px solid #eee",
			"& img": {
				marginRight: 10,
			},
		},
	},
	icon: {
		height: 47,
		cursor: "pointer",
		width: 60,
		"@media (max-width:1024px)": {
			width: 'auto'
		},
	},
	logoutIon: {
		marginTop: "0",
		cursor: "pointer",
		color: "black",
		height: 47,
		fontSize: "35px",
		"@media (max-width:1024px)": {
			marginTop: "0px",
		},
	},
	iconeText: {
		color: "#000",
		cursor: "pointer",
		textAlign: "center",
		"@media (max-width:1024px)": {
			fontSize: 12,
		},
	},
	logoutBox: {
		textAlign: "center",
		marginLeft: "20px",
		// marginTop:'-12px',
		marginRight: 50,
		cursor: 'pointer',
		marginTop: 35,
		"@media (max-width:1024px)": {
			display: "flex",
			flexDirection: "row",
			width: "100%",
			alignItems: "center",
			marginLeft: 0,
			marginTop: 0,
			justifyContent: "flex-start",
			textAlign: "left",
			padding: "10px",
			marginRight: 0,
			"& svg": {
				marginRight: 10,
			},
		},
	},
	iconeTextLogout: {
		color: "#000",
		cursor: "pointer",
		"@media (max-width:1024px)": {
			fontSize: 12,
			marginTop: 0,
		},
	},
	userIcons: {
		"@media (max-width:1024px)": {
			marginTop: "-16px",
			marginRight: "0px",
			display: "none",
		},
	},
	humburgurIcon: {
		display: "none",
		"@media (max-width:1024px)": {
			display: "flex",
			color: "#1e1b50",
			float: "right",
			paddingRight: 10,
			marginTop: 10,
			alignItems: 'center',
			height: 61,
			"& svg": {
				height: 28,
				width: 28,
			},
		},
		"@media (min-width: 768px) and (max-width:1024px)": {
			alignItems: 'center',
			height: 100
		},
	},
	userIconsOpen: {
		display: "flex",
		flexDirection: "column",
		position: "absolute",
		background: "#fff",
		width: 200,
		alignItems: "flex-start",
		top: 72,
		right: 9,
		borderRadius: 3,
		zIndex: 9,
		'@media (min-width: 768px) and (max-width: 1024px)': {
			top: 90
		},
		'&::before': {
			content: `''`,
			position: 'absolute',
			right: 8,
			borderBottom: '7px solid #fff',
			borderLeft: '7px solid transparent',
			borderRight: '7px solid transparent',
			top: -7
		}
	},
}));

const Header = () => {
	const history = useHistory();
	const theme = useTheme();
	const classes = useStyles(theme);
	const [records, setRecords] = useState(null);
	const [, setNumber] = useState(null);
	const [open, setOpen] = useState(false);

	const logIn = () => {
		history.push("/login");
	};

	useEffect(() => {
		const login = localStorage.getItem("user");
		const phoneNumber = localStorage.getItem("phoneNumber");
		setRecords(login);
		setNumber(phoneNumber);
	}, []);

	const logout = () => {
		firebase
			.auth()
			.signOut()
			.then(() => {
				localStorage.clear();
				setRecords(null);
				setNumber(null);
				history.push("/");
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const manageAppointment = () => {
		history.push("/manage-appointment");
	};
	const openDropDown = () => {
		setOpen(!open);
	};
	return (
		<Grid container className={classes.root}>
			<Grid item xs={12}>
				<Box
					className={
						history.location.pathname === "/"
							? classes.media
							: classes.headerMedia
					}
				>

					{records ? (
						<>
							<span className={classes.humburgurIcon} onClick={openDropDown}>
								<MenuIcon />
							</span>
							<Box display="flex" justifyContent="flex-end"
								className={`${classes.userIcons} ${open ? classes.userIconsOpen : null
									}`}
								mt={-1}>
								<Box className={classes.userIcon} onClick={manageAppointment}>
									<CImage
										imgSrc={"./assets/images/user.svg"}
										style={classes.icon}
									/>
									<Typography className={classes.iconeText}>
										My Bookings
                                </Typography>
								</Box>
								<Box className={classes.logoutBox}>
									<ExitToAppIcon className={classes.logoutIon} onClick={logout} />
									<Typography
										onClick={logout}
										className={classes.iconeTextLogout}
									>
										Logout
                                </Typography>
								</Box>
							</Box>
						</>
					) : (
							<Button
								variant="contained"
								color="primary"
								onClick={logIn}
								className={classes.LogoutButton}
							>
								Login / Register
							</Button>
						)}
					<Box className={classes.logoSection}>
						<span></span>
						<Box
							onClick={() => {
								history.push("/");
							}}
						>
							<CImage imgSrc={AppLogo} style={classes.appLogo} />
						</Box>
						<span></span>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};

export default withRouter(Header);
