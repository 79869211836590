import { Box, Typography } from "@material-ui/core";
import React from "react";
import { KeyboardArrowLeft } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";

import CustomHeader from '../CustomHeader';

const useStyles = makeStyles((theme) => ({
	headingText: {
		color: "#1B154E ",
		marginLeft: 8,
		margin: "auto",
		position: "relative",
		fontSize: 20,
		"@media (max-width:600px)": {
			fontSize: 18,
		},
		"@media (max-width:320px)": {
			fontSize: 15,
			marginLeft: 0,
		},
		"&::before": {
			content: '""',
			width: "140px",
			height: "3px",
			background: "#FFD13B",
			position: "absolute",
			left: 25,
			bottom: "0",
			"@media (max-width:767px)": {
				width: "135px",
			},
		},
	},
	privacyPolicyBox: {
		width: "100%",
		height: "59vh",
		background: "transparent",
		boxShadow: "inherit",
		backgroundColor: "#F8F8F8",
	},
	listContainer: {
		// float: "left",
		height: "100%",
		overflowY: "scroll",
		paddingLeft: "20px",
		paddingTop: "30px",
		paddingRight: "20px",
		wordBreak: 'break-word'
	},
	head: {
		color: "#1B154E ",
		fontSize: 24,
		fontWeight: "500",
		marginBottom: 16,
		marginTop: 16,
	},
	content: {
		color: "#1B154E ",
		fontSize: 16,
		fontWeight: "500",
	},
	innerContent: {
		color: "#1B154E ",
		fontSize: 14,
		fontWeight: "500",
		marginTop: 12,
	},
	arrowIcon: {
		"&:hover": {
			color: "#0d6efd",
		},
	},
	policyContainer: {
		'@media (max-width: 320px)': {
			padding: '20px 14px'
		}
	},
	logoImg: {
		'@media (max-width: 767px)': {
			marginLeft: '17px'
		}
	}
}));

const PrivacyPolicy = () => {
	const theme = useTheme();
	const classes = useStyles(theme);
	const history = useHistory();

	return (
		<>
			<CustomHeader />
			<Box p={5} className={classes.policyContainer}>
				<Box mt={2} mb={2}>
					<Typography variant="h6" className={classes.headingText}>
						<Link to="/" className="linktag">
							<KeyboardArrowLeft
								className={classes.arrowIcon}
								onClick={() => {
									history.push("/");
								}}
								style={{ marginTop: "-3px", cursor: "pointer" }}
							/>
							<span>Home </span>
						</Link>
            / Privacy Policy
          </Typography>
				</Box>
				<Box className={classes.privacyPolicyBox}>
					<Box className={classes.listContainer}>
						<Typography>
							<Typography>
								This privacy policy ("Policy") explains our policy regarding the
								collection, use, disclosure and transfer of your information by
								Carboli India Private Limited or any of its affiliate(s)
								("Company" "we" "us" or "our"), which operates the.
								website https://www.carboli.com/ including its mobile
								applications, Google assistant bot ("Website"). This Policy
								forms part and parcel of the Terms of Use. Capitalized terms
								which have been used here but are undefined shall have the same
								meaning as attributed to them in the Terms of Use. You (as
								defined hereinafter) are requested to read this Policy in
								conjunction with the Terms of Use..
              </Typography>
							<Typography>
								The Company firmly believes in the highest standards of
								integrity in relation to the information provided by its
								customers/users (you or yours or user ) at the time of
								interaction with the Company, and otherwise.
              </Typography>
						</Typography>
						<Typography className={classes.head}>General</Typography>
						<Typography>
							<Typography>
								The Website is owned by the Company and aims to value your
								privacy and commits to protect information disclosed by you to
								the Company by means of using the Website. Please read the
								Policy to understand the practices of the Company, and
								specifically with regards, as to how Company treats the
								information disclosed by you by using the domain and subdomains
								of our Website, which includes only the domains and subdomains
								being operated by the Company and will not include the domains
								or subdomains licensed out by the Company. It is acknowledged by
								you that upon being redirected to or opening a licensed domain
								which is not owned or operated by the Company, you are bound by
								the privacy policy and terms of that third party domain or
								subdomain irrespective of whether it is licensed by the Company
								to a third party. The Company shall not be liable to any claims
								arising out of your use or access of said domain or subdomain.
								You agree that you have accepted the terms of the present
								Policy. In the event you do not wish to be bound by these terms,
								you have a choice, which is free from any form of coercion or
								undue influence, to not access the Website.
              </Typography>
              The App allows Users to book an appointment for the Company or its
              representatives to conduct a home inspection or a self-inspection
              by calling a representative of the Company or through the website
              (an ‘Appointment’). The actual sale price of the Vehicle (as
              defined below) can only be evaluated and offered after a physical
              inspection of the Vehicle by authorized evaluators of the Company.
              The Company always carries out a physical inspection of the
              Vehicle before it conveys the intent to proceed with further
              transaction for arriving at a final sale price. Physical
              inspection of the Vehicle involves a thorough inspection of the
              Vehicle including all working components and features and which
              shall be the critical factors to arrive at a sale price of the
              Vehicle, which may be offered by the Company accordingly
              (collectively, “Services”). Please note that ‘Services’ would
              include any other future services we may provide or propose to
              provide.
            </Typography>

						<Typography className={classes.head}>Your Consent</Typography>
						<Typography>
							We request you to carefully read this Privacy Policy and Terms of
							Use under the different tabs before sharing your personal
							information with us. By browsing, visiting, accessing and/or using
							the Services on the Website (or searching for any of the pages on
							the Website), you explicitly consent and agree to our Privacy
							Policy laid out herein. You also agree that the information
							furnished by you is lawful, true and correct and does not violate
							or infringe any laws. No liability pertaining to the authenticity,
							genuineness, misrepresentation, fraud and/or negligence, etc. of
							the information disclosed shall lie on the Company nor will the
							Company in any way be responsible to verify any information
							obtained from you.
            </Typography>
						<Typography className={classes.head}>Information</Typography>
						<Typography>This Privacy Policy applies to:</Typography>
						<Typography>Personal information:</Typography>
						<Typography>
							<Typography>
								To avail certain Services on the Website, you will be required
								to provide certain personal information for the registration
								process which may include:
              </Typography>
							<Typography>a) their name,</Typography>
							<Typography>b) email address,</Typography>
							<Typography>c) phone number</Typography>
							<Typography>(d) account number of the Users,</Typography>
							<Typography>
								(f) KYC details and any other such information as required.
              </Typography>
              The Information as supplied by you enables us to provide the
              Services and improve the Website Services and provide you the most
              user-friendly experience. In some cases, for the provision of
              certain service(s) or utility(ies), we may require your contact
              address as well.
            </Typography>
						<Typography>
							<Typography>
								In an attempt to improve our Services, we may, from time to
								time, collect any information (other than what is mentioned
								above) from you through the Website. You may be assured that we
								do not share this data with any third party except in accordance
								with the terms of this Policy or applicable law.
              </Typography>
							<Typography>
								All Information is service dependent, and we may use the
								information only to provide the Services, maintain, protect, and
								improve the Website, and for developing new services. In the
								ordinary course of the business, we may also use your email
								address and phone number without further consent for marketing,
								non-marketing or administrative purposes (such as notifying you
								of major changes, for customer service purposes, providing
								information about updates to our Services, billing, etc.). Any
								Information provided by you will not be considered as personal
								information if it is freely available and / or accessible in the
								public domain or is not deemed as a personal information under
								the applicable law. Further, any reviews, comments, messages,
								blogs posted / uploaded / conveyed / communicated by users on
								the public sections of the Website or an application store (like
								the App Store or Play Store) becomes published content and is
								not considered as personal information which is subject to this
								Policy.
              </Typography>
							<Typography>
								In case you choose to decline to submit any information on the
								Website, we may not be able to provide certain Services to you.
								We will make reasonable efforts to notify you of the same at the
								appropriate time. In any case, we will not be liable and or
								responsible for the denial of certain Services to you for due to
								not receiving necessary information from you.
              </Typography>
							<Typography>
								When you register with the Website, we may (at our discretion)
								contact you from time to time about updation of your
								information.
              </Typography>
						</Typography>
						<Typography className={classes.head}>
							Non- Personal information:
            </Typography>
						<Typography>
							<Typography>
								Non-Personal information, viz. type of internet browser and
								operating system used, domain name of the website from which you
								came, number of visits, average time spent on the site/mobile
								application, pages viewed etc. may be picked up automatically
								and without explicitly being provided by you, but during your
								interaction with the Website by use of certain technologies,
								such as Cookies and Web Beacons. Additionally, we collect your
								device's connection to the internet, including your IP address,
								when you visit our Website. We automatically receive and log
								Information from the Website and/or your browser including but
								not limited to IP address, your device or computer's name, and
								your operating system. We may also collect log Information from
								your device, including but not limited to your location, IP
								address, your device's name, device's serial number or unique
								identification number (e.g. UDID on your iOS device, Android ID
								or ADID on your Android Device), browser type and version, CPU
								speed, and connection speed etc.
              </Typography>
							<Typography>
								To improve the responsiveness of the Website for you, we may use
								"cookies", or similar electronic tools to collect information to
								assign each visitor a unique, random number as a user
								identification (User ID) to understand the user's individual
								interests using the identified computer. By continuing to visit
								the Website, you are agreeing to the use of cookies and similar
								technologies for the purposes we describe in this Policy. If you
								prefer not to receive cookies or web beacons, then you should
								consider not using our Services, or consult your browsing and
								third-party cookie settings. We use cookies and other
								technologies for analysing and optimising our websites as well
								as for marketing purposes. Our partners may also assign their
								own cookies to your browser, a process that we do not control.
								However, you may set or amend your web browsers to delete or
								disable cookies. Disabling cookies on a computer or mobile
								telecommunication device may impair, degrade or restrict access
								to certain areas of the Site. Temporary cookies may be cleared
								by quitting the browser. However, you shall have to use the
								“clear cookies” functionality of your browser to ensure deletion
								of your profile.
              </Typography>
						</Typography>
						<Typography className={classes.head}>
							Types of information received by the Company
            </Typography>
						<Typography>
							The following type of information about you may be collected and
							processed by the Company:
              <Typography>
								<Typography>
									(i) information about your internet connection and mobile
									device;
                </Typography>
								<Typography>
									(ii) The equipment used by you to browse the Website;
                </Typography>
								<Typography>
									(iii) your usage details in respect of the Website;
                </Typography>
								<Typography>
									(iv) Internet Protocol (IP) details/ address;
                </Typography>
								<Typography>
									(v) your current location, device location, device
									information, communication information to provide customized
									offerings;
                </Typography>
								<Typography>(vi) computer operating system; </Typography>
								<Typography>
									(vii) preferences and settings such as time zone and language;
                </Typography>
								<Typography>
									(viii) account / user Information – as mentioned in the
									personal information section of this this Policy, gender, and
									any other information sought by the Website at the time of
									your making an account with the Company, or obtaining a quote
									from the Website;
                </Typography>
								<Typography>
									(ix) your searches, the results you selected from the search
									and other activities;
                </Typography>
								<Typography>
									(x) your browsing information (browsing length, details of
									online ads that you agreed on on);
                </Typography>
                (xi) your transactional information; (xii) details in respect of
                your existing bike(s) (hereinafter referred as “Vehicle”),
                including make, model, identification details of the Vehicle,
                prior use, history, insurance details, registration certificate
                details, etc.
              </Typography>
              Please note that the above-mentioned list is only illustrative,
              and is by no means, exhaustive. Further, any user-generated
              content such as reviews, blogs, testimonials etc. shall be treated
              by the Company as its own.
            </Typography>
						<Typography className={classes.head}>
							Third Party Integration
            </Typography>
						<Typography>
							<Typography>
								You may access the Website through third-party websites over
								which the Company has no control. You may optionally provide the
								Company with the information through third-party sign-in
								services such as Facebook, Pinterest, Twitter, Instagram, and
								Google Plus. In such cases, the Company fetches and stores
								whatever information is made available to the Company by you
								through these sign-in services. Please also note that the
								Company reserves its rights to use information provided by you,
								in consonance with all relevant laws and guidelines.
              </Typography>
							<Typography>
								Further, it is clarified that while your usage of the Website,
								you might see the links to the third-party websites /
								advertisements / electronic communication service, which are
								provided by the third parties. As the operations of the third
								party is not under control of the Company, therefore the Company
								does not make any endorsement / guarantee of any service or
								product offered through such third party websites nor make any
								representation related to the privacy policy or other policies
								of such third party. Any usage of such third party website or
								availing any service or product through such third parties shall
								be at your risk and the Company is not responsible for any loss
								/ damage or otherwise. It is recommended that you should review
								the Policy of such third-party websites prior to using such
								websites.
              </Typography>
						</Typography>

						<Typography>
							The information provided by you is used by the Company to enhance
							the functionality and improve the quality of your interaction with
							the Website and to personalize your experience while using our
							services.
            </Typography>

						<Typography className={classes.head}>Opting Out</Typography>
						<Typography>
							<Typography>
								You may choose to opt out of receiving promotional emails from
								the Company by following the instructions in those emails. If
								you opt out, the Company may still send you non-promotional
								emails, such as emails about your account.
              </Typography>
							<Typography>
								Additionally, you have the right to withdraw the consent
								provided by you at any time, in accordance with the terms of
								this Policy. However, please note that withdrawal of consent
								will not be retroactive. If you’d like us to delete information
								that you have provided to us, please contact our grievance
								redressal officer, Gaurav Grover at info@carboli.com and we
								will respond in a reasonable time. Please note that the Website
								has no responsibility to take down, remove, or edit any of your
								public activities or any submissions that are a result of your
								public activities. Please note that withdrawing consent does not
								affect our right to continue to collect, use and disclose
								personal information where such collection, use and disclose
								without consent is permitted or required under applicable laws.
              </Typography>
						</Typography>

						<Typography className={classes.head}>
							How your information helps us in providing you with a more
							optimized interface and experience
            </Typography>

						<Typography className={classes.head}>
							How the Company uses and shares the information collected
            </Typography>
						<Typography>
							The Company uses the information collected from and about you for
							a variety of purposes, including:
              <Typography>
								(i) Processing and responding to your queries;
              </Typography>
							<Typography>
								(ii) to protect and improve the services that the Company
								offers;
              </Typography>
							<Typography>
								(iii) to understand you (and what you do on our Website, what
								features you like, how you use them, etc.), and to improve
								content and features of the services offered by the Company
								(such as by personalizing content to your interests), processing
								and completing your transactions, and making special offers;
              </Typography>
							<Typography>
								(iv) Sending you communications that you have requested or that
								may be of interest to you;
              </Typography>
							<Typography>
								(v) sending you questions that you may be able to answer if you
								are a registered user, or have scheduled an appointment at a
								Company location or branch or for self- inspection opted by you
								or a home inspection at an address specified by you;
              </Typography>
							<Typography>
								(vi) sending you information about your Vehicle pursuant to your
								visit to a Company’s branch or location or a self-Inspection
								opted by You or a home inspection at an address specified by
								you;
              </Typography>
							<Typography>
								(vii) enabling and filtering advertisements that are relevant to
								you;
              </Typography>
							<Typography>
								(viii) Generating and reviewing reports and data about, and to
								conduct research on, Company user base and service usage
								patterns;
              </Typography>
							<Typography>
								(ix) administering contests and sweepstakes;
              </Typography>
							<Typography>(x) providing you with customer support;</Typography>
							<Typography>
								(xi) providing you with notices about your account;
              </Typography>
							<Typography>
								(xii) Internal purposes such as auditing, data analysis and
								research relating to Company’s services;
              </Typography>
							<Typography>
								(xiii) Carrying out our obligations and enforcing rights arising
								from any contracts entered into between you and the Company,
								including for billing and collection;
              </Typography>
							<Typography>
								(xiv) carrying out our obligations that arise out of all
								relevant statute in relation to your Vehicle, and in relation to
								your transaction and interaction with the Company;
              </Typography>
							<Typography>
								(xv) allowing you to participate in interactive features offered
								by the Company;
              </Typography>
							<Typography>
								(xvi) granting you opportunities to avail special
								offers/vouchers being offered by third parties for you, being a
								customer of the Company and/or showing your interest for the
								services of the Company;
              </Typography>
							<Typography>
								(xvii) the Company is allowed to share the information, which
								you have shared on our Website, with our alliance partners /
								channel / business associates or affiliates (“Partners”). These
								alliances/ partnerships are done to provide perks and
								convenience to you. By agreeing to this Policy, the Company and
								the Partners thereof, if any, will consider you as an interested
								user and will use the details submitted by you to contact you
								and give you a customized experience of our services;
              </Typography>
							<Typography>
								(xviii) the Company may use the information collected from you
								to enable the Company to display advertisements to its
								advertisers’ target audiences. Even though the Company does not
								disclose your personal information for these purposes without
								your consent, if you agrees on, or otherwise interact with an
								advertisement, the advertiser may assume that You meet its
								target criteria.
              </Typography>
						</Typography>
						<Typography className={classes.head}>
							Disclosure to Third Parties
            </Typography>
						<Typography>
							The Company shall be at liberty to disclose your information or
							part thereof to third party service providers who may facilitate
							the services, on our behalf. These service providers have access
							to your data only to perform the tasks as stipulated by the
							Company and are obligated not to disclose or use it for any other
							purpose. The Company may also share personal information with
							third party vendors, consultants, and other service providers who
							work for the Company, who are bound by contractual obligations to
							keep personal information confidential and use it only for the
							purposes for which the Company disclose it to them.
            </Typography>
						<Typography>
							In addition to the aforesaid, Partners of the Company may collect
							information with your consent from your mobile device like device
							location, device information (including storage, model, Wi-Fi,
							mobile network) transactional and promotional SMS, communication
							information to provide customized offerings.
            </Typography>
						<Typography className={classes.head}>Contact Us</Typography>
						<Typography>
							In the event you have any complaints or concerns with respect to
							the Website or if you have any questions about this Policy, please
							feel free to contact us on 97950-97250 (between 10 am - 6 pm) or
							reach out to our customer support at info@carboli.com.
            </Typography>
						<Typography className={classes.head}>Updates/Changes</Typography>
						<Typography>
							We may alter our Policy from time to time to incorporate necessary
							changes in technology, applicable law or any other variant. In any
							case, we reserve the right to change (at any point of time) the
							terms of this Policy or the Terms of Use. Any changes we make will
							be effective immediately on notice, which we may give by posting
							the new policy on the Site. Your use of the CarBoli App Services
							after such notice will be deemed acceptance of such changes. We
							may also make reasonable efforts to inform you via electronic
							mail. In any case, you are advised to review this Policy
							periodically on the Site to ensure that you are aware of the
							latest version.
            </Typography>
						<Typography className={classes.head}>
							Questions/Grievance Redressal
            </Typography>
						<Typography>
							In the event you have any grievance relating to the processing of
							Information provided by you, you may contact our Grievance
							Redressal Officer (“GRO”) Gaurav Grover
							at info@carboli.com with the following subject line “Attention:
							Grievance Redressal Officer” or write to us at the following
							address: Registered Office – B-9 (basement), Vardhman Rajdhani
							Plaza, New Rajdhani Enclave, Main Vikas Marg, Delhi -110092.
            </Typography>
						<Typography className={classes.head}>Disclaimer</Typography>
						<Typography>
							In case any personal information is shared by you with us, which
							is not requested by us during registration, (whether mandatorily
							or optionally), we will not be liable for any information security
							breach or disclosure in relation to such information. If you have
							any questions regarding this Policy or the protection of your
							personal information, please contact our data protection
							officer/grievance officer.
            </Typography>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default PrivacyPolicy;
