/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import CustomHeader from "../CustomHeader";
import {
	KeyboardArrowLeft
} from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
	headingText: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: "column",
		minHeight: "67vh",
		height: "100%",
		padding: "20px 20px",
		background: "transparent",
		boxShadow: "inherit",
		backgroundColor: "#F8F8F8",
	},
	text: {
		fontSize: 24,
		fontWeight: '500',
		"@media (max-width:768px)": {
			fontSize: 18,
			textAlign: 'center'
		},
	},
	button: {
		backgroundColor: "#1c1451",
		color: "#fff",
		padding: "6px 33px",
		fontSize: "17px",
		fontWeight: 600,
		minWidth: 130,
		minHeight: 41,
		'&:hover': {
			backgroundColor: '#1b154ed1',
		},
	},
	heading: {
		color: "#1B154E ",
		marginLeft: 8,
		margin: "auto",
		position: "relative",
		fontSize: 20,
		"@media (max-width:600px)": {
			fontSize: 18,
		},
		"@media (max-width:320px)": {
			fontSize: 15,
			marginLeft: 0,
		},
		"&::before": {
			content: '""',
			width: "100px",
			height: "3px",
			background: "#FFD13B",
			position: "absolute",
			left: 25,
			bottom: "0",
			"@media (max-width:767px)": {
				width: "87px",
			},
		},
	},
}));

const ContactDetail = () => {
	const theme = useTheme();
	const classes = useStyles(theme);
	const history = useHistory();

	return (
		<>
			<CustomHeader />
			<Box p={5} className={classes.policyContainer}>
				<Box mt={2} mb={2}>
					<Typography variant="h6" className={classes.heading}>
						<Link to="/" className="linktag">
							<KeyboardArrowLeft
								className={classes.arrowIcon}
								onClick={() => {
									history.push("/");
								}}
								style={{ marginTop: "-3px", cursor: "pointer" }}
							/>
							<span>Home </span>
						</Link>
            / Support
          </Typography>
				</Box>

			<Box className={classes.headingText}>
				<>
					<Typography className={classes.text}>
						Thank You for contacting with us, we will connect with you soon.
			</Typography>
					<Box mt={3}>
						<Button className={classes.button}
							onClick={() => { history.push('/') }}
						>
							Ok
            </Button>
					</Box>
				</>
			</Box>
			</Box>

		</>
	);
};

export default ContactDetail;
