/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Box, Typography } from "@material-ui/core";
import {
	KeyboardArrowLeft,
	AddLocation,
	ArrowForward,
} from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
} from "react-google-maps";

import CustomHeader from '../CustomHeader';

const useStyles = makeStyles((theme) => ({
	headingText: {
		color: "#1B154E ",
		marginLeft: 8,
		margin: "auto",
		position: "relative",
		fontSize: 20,
		"@media (max-width:600px)": {
			fontSize: 18,
		},
		"@media (max-width:320px)": {
			fontSize: 15,
			marginLeft: 0,
		},
		"&::before": {
			content: '""',
			width: "100px",
			height: "3px",
			background: "#FFD13B",
			position: "absolute",
			left: 25,
			bottom: "0",
			"@media (max-width:767px)": {
				width: "87px",
			},
		},
	},
	container: {
		width: "100%",
		minHeight: "67vh",
		height: "100%",
		padding: "20px 20px",
		background: "transparent",
		boxShadow: "inherit",
		backgroundColor: "#F8F8F8",
	},
	rootBox: {
		display: "flex",
		justifyContent: "space-between",
		"@media (max-width:959px)": {
			flexDirection: "column",
		},
	},
	map: {
		height: "auto",
		width: "70%",
		backgroundColor: "#fff",
		"@media (max-width:959px)": {
			width: "100%",
		},
	},
	arrowIcon: {
		"&:hover": {
			color: "#0d6efd",
		},
	},
	directions: {
		cursor: 'pointer',
		fontSize: '20px',
		fontWeight: '500',
		'& a': {
			textDecoration: 'none !important',
			color: "#1B154E ",
		}
	},
	logoImg: {
		'@media (max-width: 767px)': {
			marginLeft: '17px'
		}
	},
	support: {
		backgroundColor: '#1c1451',
		width: '100%',
		height: 110,
	}
}));

const Address = () => {
	const theme = useTheme();
	const classes = useStyles(theme);
	const history = useHistory();

	const MapWithAMarker = withScriptjs(
		withGoogleMap((props) => (
			<GoogleMap
				defaultZoom={17}
				defaultCenter={{ lat: 28.643159, lng: 77.297028 }}
			>
				<Marker position={{ lat: 28.643159, lng: 77.297028 }} />
			</GoogleMap>
		))
	);

	return (
		<Box>
			<CustomHeader/>
			<Box p={5}>
				<Box mt={2} mb={2}>
					<Typography variant="h6" className={classes.headingText}>
						<Link to="/" className="linktag">
							<KeyboardArrowLeft
								className={classes.arrowIcon}
								onClick={() => {
									history.push("/");
								}}
								style={{ marginTop: "-3px", cursor: "pointer" }}
							/>
							<span>Home </span>
						</Link>
            / Address
          </Typography>
				</Box>
				<Box className={classes.privacyPolicyBox}>
					<Box className={classes.container}>
						<Box className={classes.rootBox}>
							<Box pt={5}>
								<h5>Our Location</h5>
								<h5 className="mt-4">
									<AddLocation /> B-9, Basement
                </h5>
								<div className="address">
									<p>
										Vardhman Rajdhani Plaza, <br /> New Rajdhani Enclave, <br />
                    Main Vikas Marg DELHI - 110092
                  </p>
									<span className={classes.directions}>
										<a
											href="https://www.google.com/maps/place/CarBoli+India+Pvt+Ltd/@28.6434893,77.2951275,17z/data=!3m1!4b1!4m5!3m4!1s0x390cfb537bf2b929:0x6662a9793ea1769b!8m2!3d28.6434846!4d77.2973162"
											target="_blank"
										>
											Get Direction <ArrowForward />
										</a>
									</span>
								</div>
							</Box>
							<div className={classes.map}>
								<MapWithAMarker
									googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBKKNjRVZ6-dpLXsKp4ZEwXfw_E6_sPdCQ&v=3.exp&libraries=geometry,drawing,places"
									loadingElement={<div style={{ height: `100%` }} />}
									containerElement={<div style={{ height: `400px` }} />}
									mapElement={<div style={{ height: `100%` }} />}
								/>
							</div>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Address;
