import React, { useState, useEffect } from 'react'
import {
	Box,
	Typography,
	Button,
	Card,
	Dialog,
	DialogActions,
	DialogTitle,
} from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";

import ClockIcon from "../assets/images/wall-clock.svg";
import locationIcon from '../assets/images/addresslocation.svg'
import CalanderIcon from "../assets/images/calendar.svg";
import CImage from './CImage';
import firebase from '../config/Firebase'
import { appointmentStatusMail, createEvent, mobileSMS } from "../firebase/sendMail";

const useStyles = makeStyles((theme) => ({
	appointment: {
		marginTop: 20,
		padding: 12,
		backgroundColor: "#F8F8F8",
		'@media(max-width: 280px)': {
			padding: 5,
		}
	},
	firstCard: {
		padding: 12,
		backgroundColor: "#F8F8F8",
		'@media(max-width: 280px)': {
			padding: 5,
		}

	},
	headingText: {
		color: "#1B154E ",
		marginLeft: 8,
		margin: 'auto',
		fontSize: 20,
		fontWeight: '600',
		position: 'relative',
		'&::before': {
			content: '""',
			width: '180px',
			//top: 32,
			height: '3px',
			background: '#FFD13B',
			position: 'absolute',
			left: 0,
			bottom: '-2px',
		}
	},
	text: {
		color: "#1B154E ",
		marginLeft: 8,
		fontWeight: '500',
		marginBottom: 8,
		fontSize: 16
	},
	button: {
		background: "#1B154E",
		color: '#fff',
		'&:hover': {
			color: '#000'
		},
		'@media(max-width: 768px)': {
			fontSize: 12,
		},
		'@media(max-width: 350px)': {
			fontSize: 10,
		},
	},
	cancelButton: {
		color: 'red',
		background: "#E4E4E4",
		'@media(max-width: 768px)': {
			fontSize: 12,
			marginRight: 30
		},
		'@media(max-width: 350px)': {
			fontSize: 10,
			marginRight: 15
		},
	},
	Model: {
		color: "#1B154E ",
		fontSize: 20,
		fontWeight: '600',
		width: 'fit-content'
	},
	editModelData: {
		color: "#1B154E ",
		fontSize: 20,
		'@media(max-width: 767px)': {
			display: 'flex',
			flexDirection: 'row'
		},
	},
	dateBlock: {
		'@media (max-width:600px)': {
			width: '100%',
		},
	},
	cancelAppontment: {
		color: "red",
		marginLeft: 12,
		marginTop: 6,
		fontSize: 16,
		fontWeight: '600',

	},
	cancelIcon: {
		marginLeft: 9
	},
	locationIcon: {
		height: '15px !important',
		marginLeft: 9,
		marginTop: 8,
	},
	locationtext: {
		marginTop: 6,
		marginLeft: 8,
		width: 'fit-content',
		color: "#1B154E ",
		fontSize: 16,
		display: 'inline-block',
		fontWeight: '500'
	},
	appointmentContainer: {
		maxHeight: '55vh',
		overflow: 'auto',
		height: '64vh',
		'@media (max-width: 767px)': {
			height: 'inherit'
		}
	},
	image: {
		height: 'auto',
		width: '150px',

	},
	emptyText: {
		marginLeft: 10,
		marginTop: 50,
		fontSsize: 20
	},
	subContainer: {
		display: 'flex',
		'@media (max-width:768px)': {
			display: 'block'
		},
	},
	brandList: {
		'@media (max-width:768px)': {
			width: '100%'
		},
	},
	dateContainer: {
		marginTop: 8,
		'@media (max-width:768px)': {
			width: '100%',
			marginLeft: 8,
			marginTop: 0,
		},
	},
	backToAppoints: {
		display: 'flex',
		flex: 1,
		marginLeft: 16,
		marginBottom: 16
	},
	iconButton: {
		fontSize: 30,
		color: '#1B154E',
		cursor: 'pointer',
	},
	headTitle: {
		color: "#1B154E",
		marginLeft: 2,
		marginTop: 2,
		fontSize: 18,
		fontWeight: 600,
		fontFamily: 'Montserrat, SemiBold',
	},
	statusText: {
		marginLeft: 12,
		marginTop: 6,
		fontSize: 16,
		fontWeight: '600',
		width: 'fit-content',
	},
	pointer: {
		cursor: 'pointer',
	},
	statusLabel: {
		fontWeight: '600'
	}
}));
const AppointMentCard = (props) => {
	const theme = useTheme();
	const classes = useStyles(theme);
	const { appointments, regWhatsappNo } = props;
	const history = useHistory();
	const [view, setView] = useState(true)
	const [data, setData] = useState({})
	const [open, setOpen] = React.useState(false);
	const today = moment().format('YYYY-MM-DD');

	const appoint = (appointment) => {
		setData(appointment)
		setView(false)
	}

	const appointmentMake = (appointment) => {
		if (appointment?.isOther) {
			history.push({
				pathname: '/booking-form',
				state: {
					...appointment,
					mode: "edit"
				}
			})
		} else {
			history.push({
				pathname: '/booking',
				state: {
					brandName: { name: appointment.make },
					...appointment,
					mode: "edit"
				}
			})
		}
	}

	const appointmentModel = (appointment) => {
		if (appointment?.isOther) {
			history.push({
				pathname: '/booking-form',
				state: {
					...appointment,
					mode: "edit"
				}
			})
		} else {
			history.push({
				pathname: '/booking',
				state: {
					brandName: { name: appointment.make, img_url: '' },
					carName: { name: appointment.model },
					...appointment,
					mode: "edit"
				}
			})
		}
	}

	const appointmentYear = (appointment) => {
		if (appointment?.isOther) {
			history.push({
				pathname: '/booking-form',
				state: {
					...appointment,
					mode: "edit"
				}
			})
		} else {
			history.push({
				pathname: '/booking',
				state: {
					brandName: { name: appointment.make },
					carName: { name: appointment.model },
					selectedYear: appointment.year,
					...appointment,
					mode: "edit"
				}
			})
		}
	}

	const appointmentVariant = (appointment) => {
		if (appointment?.isOther) {
			history.push({
				pathname: '/booking-form',
				state: {
					...appointment,
					mode: "edit"
				}
			})
		} else {
			history.push({
				pathname: '/booking',
				state: {
					brandName: { name: appointment.make },
					carName: { name: appointment.model },
					selectedYear: appointment.year,
					selectedVariant: appointment.variant,
					...appointment,
					mode: "edit"
				}
			})
		}
	}

	const appintmentDateTime = (appointment) => {
		history.push({
			pathname: '/appointment',
			state: {
				...appointment,
				mode: "edit"
			}
		})
	}

	const editData = (data) => {
		history.push({
			pathname: '/appointment',
			state: {
				...data, mode: "edit"
			}
		})
	}

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const hanelcancel = async () => {
		setData(data.status = "cancel")
		handleClose()
		setView(true)
		if (regWhatsappNo.isEnable) {
			createEvent({
				"userId": data?.mobile,
				"phoneNumber": data?.mobile,
				"countryCode": "+91",
				"event": "Inspection Cancel",
				"traits": {
					"status": "ins_cancelled",
					"name": data?.name,
					"contact": "+919795097250",
					"car": `${data?.make} ${data?.model}`
				}
			});
		}
		if (data?.email) {
			await appointmentStatusMail(data?.email, data.status, data.firstName ? data.firstName : "");
		}
		firebase
			.database()
			.ref("appointments")
			.child(data.appointmentId)
			.set({
				...data,
			})
			.then((res) => {
				console.log('sucessfully');
			})
			.catch((err) => { console.log(err) });
			await mobileSMS(data.mobile, "cancel", data);
	}

	return (
		<Box style={{ marginTop: 10 }} >
			{ view ?
				<>
					<Box mt={-3} mb={2}>
						<Typography className={classes.headingText}>
							Manage Appointment
						</Typography>
					</Box>
					{appointments && appointments.length ?

						<Box className={classes.appointmentContainer}>
							{appointments && appointments.map((appointment, index, { length }) =>
							(
								<Card key={appointment.appointmentId}
									className={
										length - 1 === index ? classes.firstCard : classes.appointment}
									onClick={() => appoint(appointment)}
									style={{ cursor: 'pointer' }}
								>

									<Box width='100%' className={classes.subContainer} display='flex'>
										<Box width='60%' className={classes.brandList}>
											<Box display="flex" className={classes.editModelData} ml={1} mb={1}>
												<Box mr={1}>
													<Typography className={classes.Model}>{appointment.make}</Typography>
												</Box>
												<Box>
													<Typography className={classes.Model}>{appointment.model}</Typography>
												</Box>
											</Box>
											<Box display="flex">
												<Typography className={classes.text}>
													Car variant {`${appointment.oilVariant ? appointment.oilVariant : 'petrol'} ${appointment.variant}`}
												</Typography>
											</Box>
											<Box display='flex'>
												<Typography className={classes.text}>
													Registration year {appointment.year}
												</Typography>
											</Box>
										</Box>
										<Box width='40%' className={classes.dateContainer}>
											< Box display="flex" className={classes.dateBlock} >
												<Box
													style={{ cursor: 'pointer' }}

												>
													<CImage
														imgSrc={CalanderIcon}
													/>
												</Box>
												<Box>
													<Typography className={classes.text}>
														{moment(appointment.appointmentDate).format("dddd MMM DD, YYYY")}
													</Typography>
												</Box>
											</Box >
											<Box display="flex" className={classes.dateBlock} >
												<Box
													style={{ cursor: 'pointer' }}

												>
													<CImage
														imgSrc={ClockIcon}
													/>
												</Box>
												<Box display='flex'>
													<Typography className={classes.text} >
														{appointment.appointmentTime}
													</Typography>
												</Box>
											</Box>
										</Box>
									</Box>
									{appointment && appointment.address ?
										<Box display="flex" >
											<Box

												style={{ cursor: 'pointer' }}
											>
												<CImage
													imgSrc={locationIcon}
													style={classes.locationIcon}
												/>
											</Box>
											<Box display='flex'>
												<Typography
													variant="caption"
													className={classes.locationtext}
												>
													{appointment.address}
												</Typography>
											</Box>
										</Box> : null
									}
									{
										appointment && appointment.customAddress ?
											<Box display="flex">
												<Box>
													<Typography className={classes.locationtext}>
														Address
													</Typography>
												</Box>
												<Box>
													<Typography className={classes.locationtext}>
														{appointment.customAddress}
													</Typography>
												</Box>
											</Box>

											: null
									}
									<Box mb={2}>
										<Box display='flex'>
											<Box>
												<Typography
													className={`${classes.locationtext} ${classes.statusLabel}`}
												> Status </Typography>
											</Box>

											{appointment.status
												&&
												appointment.status === "cancel" ?
												<Box>
													<Typography className={classes.cancelAppontment}>Cancelled</Typography>
												</Box>
												: <Typography className={classes.statusText}>
													{appointment?.status === "new_lead" && <span> New Lead </span>}
													{appointment?.status === "inspection_scheduled" && <span> Inspection Scheduled </span>}
													{appointment?.status === "inspected" && <span> Inspected </span>}
													{appointment?.status === "price_quoted" && <span> Price Quoted </span>}
													{appointment?.status === "under_negotiation" && <span> Under Negotiation </span>}
													{appointment?.status === "success" && <span> Success </span>}
													{appointment?.status === "lost" && <span> Lost </span>}
													{appointment?.status === "inspection_rescheduled" && <span> Inspection Rescheduled </span>}
												</Typography>
											}
										</Box>
									</Box>
								</Card>
							)).reverse()
							}
						</Box>
						: <Typography className={classes.emptyText}>Till now you did not book any appointment</Typography>}
				</> :
				<>
					<Box mt={-3} mb={2}>
						<Typography variant="h6" className={classes.headingText}>
							Appointment View
						</Typography>
					</Box>
					<Box >
						<Box className={classes.backToAppoints}>
							<ChevronLeftIcon className={classes.iconButton} onClick={() => { setView(true) }} />
							<Typography className={classes.headTitle} onClick={() => { setView(true) }} style={{ cursor: 'pointer' }}>View All Appointments</Typography>
						</Box>
						<Card className={classes.appointment}>

							<Box width='100%' className={classes.subContainer} display='flex'>
								<Box width='60%' className={classes.brandList}>
									<Box display="flex" className={classes.editModelData} ml={1} mb={1}>
										<Box mr={1}>
											<Typography
												className={`${classes.Model} ${(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ? classes.pointer : null
													}`}

												onClick={
													(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ?
														() => appointmentMake(data) : null
												}
											>
												{data.make}
											</Typography>
										</Box>
										<Box>
											<Typography
												className={`${classes.Model} ${(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ? classes.pointer : null
													}`}
												onClick={
													(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ?
														() => appointmentModel(data) : null
												}
											>
												{data.model}
											</Typography>
										</Box>
									</Box>
									<Box display="flex">
										<Typography
											className={`${classes.text} ${(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ? classes.pointer : null
												}`}
											onClick={
												(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ?
													() => appointmentVariant(data) : null
											}
										>
											Car variant {`${data.oilVariant ? data.oilVariant : 'petrol'} ${data.variant}`}
										</Typography>
									</Box>
									<Box display='flex'>
										<Typography
											className={`${classes.text} ${(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ? classes.pointer : null
												}`}
											onClick={
												(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ?
													() => appointmentYear(data) : null
											}
										>
											Registration year {data.year}
										</Typography>
									</Box>
								</Box>
								<Box width='40%' className={classes.dateContainer}>
									< Box display="flex" className={classes.dateBlock} >
										<Box
											onClick={
												(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ?
													() => appintmentDateTime(data) : null
											}>
											<CImage
												imgSrc={CalanderIcon}
											/>
										</Box>
										<Box>
											<Typography
												className={`${classes.text} ${(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ? classes.pointer : null
													}`}
												onClick={
													(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ?
														() => appintmentDateTime(data) : null
												}
											>
												{moment(data.appointmentDate).format("dddd MMM DD, YYYY")}
											</Typography>
										</Box>
									</Box >
									<Box display="flex" className={classes.dateBlock} >
										<Box
											onClick={
												(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ?
													() => appintmentDateTime(data) : null
											}>
											<CImage
												imgSrc={ClockIcon}
											/>
										</Box>
										<Box display='flex'>
											<Typography
												className={`${classes.text} ${(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ? classes.pointer : null
													}`}
												onClick={
													(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ?
														() => appintmentDateTime(data) : null
												}
											>
												{data.appointmentTime}
											</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
							{data && data.address ?
								<Box display="flex" >
									<Box>
										<CImage
											imgSrc={locationIcon}
											onClick={
												(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ?
													() => appintmentDateTime(data) : null
											}
											style={`${classes.locationIcon} ${(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ? classes.pointer : null
												}`}
										/>
									</Box>
									<Box display='flex'>
										<Typography
											className={`${classes.locationtext} ${(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ? classes.pointer : null
												}`}
											onClick={
												(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ?
													() => appintmentDateTime(data) : null
											}
										>
											{data.address}
										</Typography>
									</Box>
								</Box> : null
							}
							{
								data && data.customAddress ?
									<Box display="flex"
									>
										<Box>
											<Typography
												className={`${classes.locationtext} ${(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ? classes.pointer : null
													}`}
												onClick={
													(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ?
														() => appintmentDateTime(data) : null
												}
											>
												Address
													</Typography>
										</Box>
										<Box>
											<Typography
												className={`${classes.locationtext} ${(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ? classes.pointer : null
													}`}
												onClick={
													(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ?
														() => appintmentDateTime(data) : null
												}
											>
												{data.customAddress}
											</Typography>
										</Box>
									</Box>

									: null
							}
							<Box mb={2}>
								<Box display='flex'>
									<Box>
										<Typography
											className={`${classes.locationtext} ${classes.statusLabel}`}
										> Status </Typography>
									</Box>

									{data.status
										&&
										data.status === "cancel" ?
										<Box>
											<Typography

												className={classes.cancelAppontment}>Cancelled</Typography>
										</Box>
										: <Typography className={classes.statusText}>
											{data?.status === "new_lead" && <span> New Lead </span>}
											{data?.status === "inspection_scheduled" && <span> Inspection Scheduled </span>}
											{data?.status === "inspected" && <span> Inspected </span>}
											{data?.status === "price_quoted" && <span> Price Quoted </span>}
											{data?.status === "under_negotiation" && <span> Under Negotiation </span>}
											{data?.status === "success" && <span> Success </span>}
											{data?.status === "lost" && <span> Lost </span>}
											{data?.status === "inspection_rescheduled" && <span> Inspection Rescheduled </span>}
										</Typography>
									}
								</Box>
							</Box>
							<Box display='flex' justifyContent='space-around'>
								{
									(data.status === "inspection_scheduled" && (data.appointmentDate >= today)) ?
										<>
											<Box>
												<Button
													onClick={handleClickOpen}
													className={classes.cancelButton}
												>Cancel Appointment</Button>
											</Box>
											<Box>
												<Button
													onClick={() => { editData(data) }}
													className={classes.button}
												>Edit Appointment</Button>
											</Box>
										</> : null
								}
							</Box>
							<Dialog
								open={open}
								onClose={handleClose}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description"
							>
								<DialogTitle id="alert-dialog-title" className={classes.text}>
									{"Are you sure you want to cancel the appointment?"}
								</DialogTitle>
								<DialogActions>
									<Button onClick={handleClose} color="primary">
										Disagree
         						 </Button>
									<Button onClick={hanelcancel} color="primary" autoFocus>
										Agree
          						</Button>
								</DialogActions>
							</Dialog>
						</Card>
					</Box>
				</>}
		</Box >
	)
}

export default AppointMentCard