import React from 'react'
import { Typography, Box, Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	detailText: {
		color: "#1B154E ",
		cursor: "pointer",
		fontSize: '17px',
		fontWeight: '500',
		'@media (max-width:600px)': {
			marginLeft: '-30px',
		},
		'@media (max-width:350px)': {
			fontSize: '14px',
		}
	},
	heading: {
		color: "#1B154E ",
		fontSize: '20',
		fontWeight: '600',
		'@media (max-width:600px)': {
			marginLeft: '-40px',
		}
	},
	submitDetail: {
		backgroundColor: "#1B154E ",
		color: "#fff",
		borderRadius:10,
		padding: '10px 40px 10px',
		'&:hover': {
			backgroundColor: '#1b154ed1',
		},
		'@media (max-width:600px)': {
			padding: '10px 20px 10px',
		},
		'@media (max-width:320px)': {
			fontSize:12,
			padding: '8px 10px 8px',

		},

	},
	detailCard: {
		width: '100%'
	},
	cardBox: {
		width: '100%',
	},
	topPadding: {
		paddingTop: '16px',
		paddingBottom: '16px',
	},
	submitDetailBox: {
		'@media (max-width:600px)': {
			display: 'flex',
			justifyContent: 'flex-start'
		}
	}
}));

const CarDetails = (props) => {
	const {
		heading = 'Review Your Car Details',
		brand = '',
		model = '',
		year = '',
		variant = '',
		oilVariant = '',
		appointmentData,
		handleSteps,
		selectedTransmissionType = '',
	} = props
	const history = useHistory();
	const theme = useTheme();
	const classes = useStyles(theme);
	const oil = props.oilVariant ? props.oilVariant : 'petrol'

	appointmentData && delete appointmentData.brandName;
	appointmentData && delete appointmentData.carName;
	appointmentData && delete appointmentData.selectedYear;
	appointmentData && delete appointmentData.selectedVariant;
	appointmentData && delete appointmentData.brand;

	const submitDetail = () => {
		history.push({
			pathname: '/appointment',
			state: {
				...appointmentData,
				make: props.brand,
				model: props.model,
				year: props.year,
				variant: props.variant,
				oilVariant: oil
			}
		})
	}

	const handelStep = (step) => {
		if (handleSteps) {
			handleSteps(step)
		}
	}

	return (
		<Box className={classes.cardBox}>
			<Box className={classes.topPadding}>
				<Typography className={classes.heading}>{heading}</Typography>
			</Box>
			<Box mb={3}
				onClick={() => { handelStep(1) }}
			>
				<Typography className={classes.detailText}
				>Car Brand {brand}</Typography>
			</Box>
			<Box mb={3}
				onClick={() => { handelStep(2) }}
			>
				<Typography className={classes.detailText}
				>Car Model {model}</Typography>
			</Box>
			<Box mb={3}
				onClick={() => { handelStep(3) }}
			>
				<Typography className={classes.detailText}
				>Registration Year {year}</Typography>
			</Box>
			<Box mb={3} onClick={() => { handelStep(4) }}	>
				<Typography className={classes.detailText}>Car Variant {oilVariant} {selectedTransmissionType} {variant}</Typography>
			</Box>
			<Box display='flex' justifyContent='center' mb={3} className={classes.submitDetailBox}>
				<Button onClick={submitDetail} className={classes.submitDetail}>Submit Details</Button>
			</Box>
		</Box>
	)
}

export default CarDetails
