/* eslint-disable array-callback-return */
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		flexWrap: "wrap",
		"& .MuiSelect-selectMenu": {
			fontFamily: "Montserrat, Regular",
			color: "#000000",
		},
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		minHeight: 50,
		top: -1,
	},
	timeSlots: {
		width: "103px",
		textAlign: "center",
		"@media (max-width:767px)": {
			textAlign: "left",
			width: "auto !important",
		},
	},
	time: {
		"@media (max-width:365px)": {
			fontSize: 12,
			width: "80px",
		},
	},
}));

const CustomSelect = (props) => {
	const classes = useStyles();
	const { defaultValue, label, onChange, error, helperText, onKeyUp, menuItems } = props;
	// const [value] = React.useState(defaultValue || null);

	return (
		<div className={classes.root}>
			<TextField
				select
				error={error}
				helperText={helperText}
				display="flex"
				label={label}
				flexdirection="row"
				id="standard-full-width"
				fullWidth
				className={classes.textField}
				variant="standard"
				value={defaultValue}
				onChange={onChange}
				margin="normal"
				inputProps={{ style: { fontSize: 14, color: '#000000' } }} // font size of input text
				InputLabelProps={{ style: { fontSize: 11, color: '#1B154E ', fontFamily: 'Montserrat, SemiBold' } }} // font size of input label

				onKeyUp={onKeyUp}
			>
				{menuItems?.map((e) => {
					return <MenuItem value={e.value}>{e.label}</MenuItem>;
				})}

			</TextField>
		</div>
	);
};
export default CustomSelect;
