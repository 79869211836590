/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button, Card } from "@material-ui/core";
import moment from "moment";
import { getLeadId } from "../firebase/method";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import AppointmentDate from "../atoms/AppointmentDate";
import AppointmentTime from "../atoms/AppointmentTime";
import CustomInput from "../atoms/CustomInput";
import CustomNumber from "../atoms/CustomNumberInput";
import firebase from "../config/Firebase";
import CImage from "../atoms/CImage";
import LocationSelector from "../atoms/LocationSelector";
import rightImg from "../assets/images/carManimg2.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
import Header from "../atoms/Header";
import {
	isMobileNumberValid,
	isNameValid,
	isEmailRequired,
	isRequired,
} from "../utils/validation";
import { fetchAppointments } from "../firebase/getAppointmentData";
import { appointmentStatusMail, createEvent, mobileSMS } from "../firebase/sendMail";
import { getWhatsappUsersDetails } from '../firebase/getWhatsappUsers';

const useStyles = makeStyles((theme) => ({
	text: {
		color: "#1B154E ",
		marginLeft: 8,
	},
	highlightText: {
		fontSize: "19px",
		paddingLeft: "6px",
		fontWeight: "600",
	},
	headingText: {
		color: "#1B154E ",
		marginLeft: 24,
		top: 20,
		margin: "auto",
		position: "relative",
		fontSize: 26,
		fontWeight: "600",
		"@media(max-width: 768px)": {
			marginLeft: "23px",
			fontSize: 20,
		},
		"&::before": {
			content: '""',
			width: "170px",
			height: "3px",
			background: "#FFD13B",
			position: "absolute",
			left: 0,
			bottom: "0",
		},
	},
	button: {
		background: "#1B154E",
		width: 200,
		borderRadius: 10,
		color: "#fff !important",
		"&:hover": {
			background: "#FFCF00",
		},
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "center",
		marginTop: 16,
	},
	imgMedia: {
		width: "100%",
		height: "auto",
		maxHeight: 445,
	},
	card: {
		backgroundColor: "#F8F8F8",
		position: "relative",
	},
	errormsg: {
		color: "red",
		marginLeft: 8,
		fontSize: 12,
	},
	appointmentSection: {
		padding: "20px",
		"@media(max-width: 768px)": {
			//flexDirection: 'column-reverse'
		},
	},
	formContainer: {
		"@media(max-width: 768px)": {
			padding: "0px",
			paddingBottom: "40px",
		},
		"@media (max-width:800px)": {
			marginBottom: 0,
		},
		"@media (min-width:1680px) and (max-width:3000px) ": {
			marginBottom: 180,
		},
	},
	dateContainer: {
		"@media(max-width: 767px)": {
			marginTop: -16,
			display: "flex",
			flexDirection: "column",
			"& div": {
				width: "100%",
				textAlign: "left",
			},
		},
	},
	locationBox: {
		marginTop: -8,
		marginBottom: 16,
		"@media(max-width: 650px)": {
			marginTop: 8,
		},
	},
	appTime: {
		"@media(max-width: 650px)": {
			marginTop: -8,
		},
	},
	appointmentDate: {
		"@media(max-width: 650px)": {
			marginTop: 16,
		},
	},
}));

const AppointmentForm = (props) => {
	const theme = useTheme();
	const history = useHistory();
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const location = useLocation();
	const classes = useStyles(theme);
	const [defaultValue] = useState(location && location.state);
	const [carDetail] = useState(location.state || null);
	const [form, setForm] = useState({
		firstName: "",
		lastName: "",
		mobile: '',
		email: "",
		appointmentDate: "",
		appointmentTime: "",
		address: "",
		customAddress: "",
		...carDetail,
	});
	const [records, setRecords] = useState(null);
	const [loading, setLoading] = useState(false);
	const [firstName, setFirstName] = useState({
		value: "",
		error: false,
		errorText: "",
	});
	const [lastName, setLastName] = useState({
		value: "",
		error: false,
		errorText: "",
	});
	const [customAddress, setCustomAddress] = useState({
		value: "",
		error: false,
		errorText: "",
	});
	const [email, setEmail] = useState({
		value: "",
		error: false,
		errorText: "",
	});
	const [mobile, setMobile] = useState({
		value: "",
		error: false,
		errorText: "",
	});
	const [appTime, setAppTime] = useState({
		value: "",
		error: false,
		errorText: "",
	});
	const [appDate, setAppDate] = useState({
		value: "",
		error: false,
		errorText: "",
	});
	const [locations, setLocation] = useState({
		value: "",
		error: false,
		errorText: "",
	});
	const [disabled, setDisabled] = useState(false)

	useEffect(() => {
		if (defaultValue) {
			setFirstName({ ...firstName, value: defaultValue?.firstName });
			setMobile({ ...mobile, value: defaultValue?.mobile });
			setEmail({ ...email, value: defaultValue?.email });
			setCustomAddress({
				...customAddress,
				value: defaultValue?.customAddress,
			});
			setAppDate({ ...appDate, value: defaultValue?.appointmentDate });
			setAppTime({ ...appTime, value: defaultValue?.appointmentTime });
			setLocation({ ...locations, value: defaultValue?.address });
			setLastName({ ...lastName, value: defaultValue?.lastName });
		}
	}, []);

	useEffect(() => {
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				setRecords(user);

				const mobileNo = user?.phoneNumber?.substring(3);
				setForm({
					...form,
					mobile: user?.phoneNumber?.substring(3)
				});
				setMobile({ ...mobile, value: mobileNo });
				if (location?.state?.mode !== "edit") {
					fetchAppointments().then((appointmentList) => {
						if (appointmentList) {
							let arr = [];
							appointmentList.forEach(e => {
								if (e.mobile === mobileNo) {
									arr.push(e);
								}
							})
							if (arr.length) {
								setFirstName({ ...firstName, value: arr[arr.length - 1]?.firstName });
								setLastName({ ...lastName, value: arr[arr.length - 1]?.lastName });
								setForm({
									...form, firstName: arr[arr.length - 1]?.firstName, lastName: arr[arr.length - 1]?.lastName,
									mobile: user?.phoneNumber?.substring(3)
								});
							}
						}
					});
				}
			}
		});
	}, []);

	const userLogin = () => {
		if (!checkError()) {
			return;
		}
		if (records) {
			handleBook();
		} else {
			history.push({
				pathname: "/login",
				state: { ...form },
			});
		}
	};

	const checkSinner = (e) => {
		if (e.keyCode === 38 || e.keyCode === 40) {
			e.preventDefault();
		}
	};

	const firstNameError = () => {
		const fnameError = isNameValid("firstName", firstName.value);
		setFirstName({
			...firstName,
			error: fnameError.error,
			errorText: fnameError.errorKey,
		});
	};

	const lastNameError = () => {
		const lnameError = isNameValid("lastName", lastName.value);
		setLastName({
			...lastName,
			error: lnameError.error,
			errorText: lnameError.errorKey,
		});
	};

	const emailError = () => {
		const emailError = isEmailRequired("email", email.value);
		setEmail({
			...email,
			error: emailError.error,
			errorText: emailError.errorKey,
		});
	};

	const phoneError = () => {
		const phoneError = isMobileNumberValid("mobile", mobile.value);
		setMobile({
			...mobile,
			error: phoneError.error,
			errorText: phoneError.errorKey,
		});
	};

	const dateError = (date) => {
		console.log("my date err");
		const dateError = isRequired("appointmentDate", date);
		setAppDate({
			...appDate,
			error: dateError.error,
			errorText: dateError.errorKey,
		});
	};

	const timeError = (value) => {
		const timeError = isRequired("appointmentTime", value);
		setAppTime({
			...appTime,
			value: value,
			error: timeError.error,
			errorText: timeError.errorKey,
		});
		setForm({ ...form, appointmentTime: value });
	};

	const checkError = () => {
		const emailError = isEmailRequired("email", email.value);
		setEmail({
			...email,
			error: emailError.error,
			errorText: emailError.errorKey,
		});
		const phoneError = isMobileNumberValid("mobile", mobile.value);
		setMobile({
			...mobile,
			error: phoneError.error,
			errorText: phoneError.errorKey,
		});
		const fnameError = isNameValid("firstName", firstName.value);
		setFirstName({
			...firstName,
			error: fnameError.error,
			errorText: fnameError.errorKey,
		});
		const lnameError = isNameValid("lastName", lastName.value);
		setLastName({
			...lastName,
			error: lnameError.error,
			errorText: lnameError.errorKey,
		});
		const dateError = isRequired("appointmentDate", appDate.value);
		setAppDate({
			...appDate,
			error: dateError.error,
			errorText: dateError.errorKey,
		});
		const timeError = isRequired("appointmentDate", appTime.value);
		setAppTime({
			...appTime,
			error: timeError.error,
			errorText: timeError.errorKey,
		});
		if (
			!fnameError.error &&
			!phoneError.error &&
			!emailError.error &&
			!dateError.error &&
			!lnameError.error &&
			!timeError.error
		) {
			return true;
		}
	};

	const getEmail = async (whatsapp) => {
		if (email.value) {
			if (defaultValue?.appointmentDate !== form.appointmentDate || defaultValue?.appointmentTime !== form.appointmentTime) {
				await appointmentStatusMail(email.value, "inspection_rescheduled", firstName.value ? firstName.value : ""
				);
			}
		}
		if (form.mobile && (Object.keys(whatsapp).length === 0 || !whatsapp.isEnable)) {
			await mobileSMS(form.mobile, "inspection_rescheduled", form);
		}
	}

	const newInsEmail = async (whatsapp) => {
		if (email.value) {
			await appointmentStatusMail(email.value, "inspection_scheduled", firstName.value ? firstName.value : "");
		}
		if (form.mobile && (Object.keys(whatsapp).length === 0 || !whatsapp.isEnable)) {
			await mobileSMS(form.mobile, "inspection_scheduled", form);
		}
	}

	const handleBook = async () => {
		setLoading(true);
		setDisabled(true);
		var leadId = null;
		leadId = await getLeadId();
		let data = { ...form };
		var whatsapp = {};
		await getWhatsappUsersDetails(data.mobile).then(data => {
			whatsapp = data;
		})
		const appointmentId = defaultValue && defaultValue.appointmentId;
		const userId = records && records.uid;
		var url = "appointments/" + appointmentId;
		delete data.appointmentId;
		if (location.state.mode === "edit") {
			delete data.mode;
			firebase
				.database()
				.ref(url)
				.set({
					...data,
				})
				.then((res) => {
					setLoading(false);
					history.push({
						pathname: "/confirmation",
						state: { form: form, mode: "edit" },
					});
					if (whatsapp.isEnable) {
						if (defaultValue?.appointmentDate !== form.appointmentDate || defaultValue?.appointmentTime !== form.appointmentTime) {
							createEvent({
								"userId": form?.mobile,
								"phoneNumber": form?.mobile,
								"countryCode": "+91",
								"event": "Inspection Reschedule",
								"traits": {
									"status": "ins_resch",
									"name": form?.firstName,
									"car": `${form?.make} ${form?.model}`,
									"location": form?.address,
									"time": `${form?.appointmentDate} ${form?.appointmentTime}`,
									"contact": "+919795097250"
								}
							});
						}
					}
					getEmail(whatsapp);
					setDisabled(false);
				})
				.catch((err) => {
					console.log(err);
					setDisabled(false);
				});
		} else {
			if (data) {
				data.leadID = leadId;
				data.createdAt = moment().format();
				data.updatedAt = moment().format();
				data.leadDate = moment().format("YYYY-MM-DD");
				data.userId = userId;
				data.status = "inspection_scheduled";
			}
			firebase
				.database()
				.ref("appointments")
				.push({
					...data,
				})
				.then((res) => {
					setLoading(false);
					history.push({
						pathname: "/confirmation",
						state: { form: form },
					});
					if (whatsapp?.isEnable) {
						createEvent({
							"userId": form.mobile,
							"phoneNumber": form.mobile,
							"countryCode": "+91",
							"event": "Inspection Scheduled",
							"traits": {
								"status": "ins_sch",
								"name": firstName.value,
								"car": `${form.make} ${form.model}`,
								"location": form.address,
								"time": `${form.appointmentDate} ${form.appointmentTime}`,
								"contact": "+919795097250"
							}
						});
					}
					newInsEmail(whatsapp);
					setDisabled(false);
				})
				.catch((err) => {
					console.log(err);
					setDisabled(false);
				});
		}
	};

	const handleLocationSelect = (address) => {
		setLocation({ ...locations, value: address });
		setForm({ ...form, address: address });
	};

	const handelDateChange = (date) => {
		const dateError = isRequired("appointmentDate", date);
		const formatDate = moment(date).format("YYYY-MM-DD");
		setAppDate({
			...appDate,
			value: formatDate,
			error: dateError.error,
			errorText: dateError.errorKey,
		});
		setForm({ ...form, appointmentDate: formatDate });
		if (formatDate == moment().format("YYYY-MM-DD")) {
			if (moment().format("HH:mm") > moment().format("15:00")) {
				setAppTime({
					...appTime,
					value: "",
				});
			}
		}
		if (formatDate >= moment().format("YYYY-MM-DD")) {
			if (!appTime.value) {
				setAppTime({
					...appTime,
					value: defaultValue?.appointmentTime,
				});
			}
		}
	};

	return (
		<>
			<Header />
			<Box
				p={5}
				pl={10}
				bgcolor="background.paper"
				mb={3}
				className={classes.formContainer}
			>
				<Box mb={2}>
					<Typography variant="h6" className={classes.headingText}>
						Appointment
          </Typography>
				</Box>
				<Grid
					container
					className={classes.appointmentSection}
					spacing={isSmall ? 0 : 4}
				>
					<Grid item xs={12} sm={12} md={6}>
						<Card className={classes.card}>
							<Box p={3}>
								<Box mb={1}>
									<Typography className={classes.text}>
										Just one more step to
                    <Typography
											variant="caption"
											className={classes.highlightText}
										>
											schedule car's inspection
                    </Typography>
									</Typography>
								</Box>
								<Box mb={2}>
									<CustomInput
										name="First Name"
										label="First Name"
										error={firstName.error}
										helperText={firstName.errorText || ""}
										onChange={(e) => {
											setFirstName({ ...firstName, value: e.target.value });
											setForm({ ...form, firstName: e.target.value });
										}}
										defaultValue={firstName.value}
										onKeyUp={firstNameError}
									/>
								</Box>
								<Box mb={2}>
									<CustomInput
										name="Last Name"
										label="Last Name"
										error={lastName.error}
										helperText={lastName.errorText || ""}
										onChange={(e) => {
											setLastName({ ...lastName, value: e.target.value });
											setForm({ ...form, lastName: e.target.value });
										}}
										defaultValue={lastName.value}
										onKeyUp={lastNameError}
									/>
								</Box>
								<Box mb={2}>
									<CustomNumber
										name="mobile"
										label="Mobile"
										setMobile
										error={mobile.error}
										helperText={mobile.errorText || ""}
										onChange={(e) => {
											setMobile({ ...mobile, value: e.target.value });
											setForm({ ...form, mobile: e.target.value });
										}}
										defaultValue={mobile.value}
										onKeyDown={(e) => checkSinner(e)}
										onKeyUp={phoneError}
									/>
								</Box>
								<Box mb={2}>
									<CustomInput
										name="email"
										label="Email"
										error={email.error}
										helperText={email.errorText || ""}
										onChange={(e) => {
											setEmail({ ...email, value: e.target.value });
											setForm({ ...form, email: e.target.value });
										}}
										defaultValue={email.value}
										onKeyUp={emailError}
									/>
								</Box>
								<Box>
									<Box
										width="100%"
										display="flex"
										className={classes.dateContainer}
									>
										<Box width="48%" className={classes.appointmentDate}>
											<Box>
												<AppointmentDate
													name="appointmentDate"
													onChange={handelDateChange}
													defaultValue={defaultValue?.appointmentDate || ""}
													dates={appDate.value}
													error={appDate.error}
													helperText={appDate.errorText || ""}
													onKeyUp={dateError}
												/>
											</Box>
										</Box>
										<Box width="4%" />
										<Box width="48%" className={classes.appTime}>
											<Box>
												<AppointmentTime
													appointmentDate={appDate.value}
													name="appointmentTime"
													onChange={(e) => timeError(e.target.value)}
													defaultValue={
														defaultValue && defaultValue.appointmentTime
													}
													time={appTime.value}
													error={appTime.error}
													helperText={appTime.errorText || ""}
													onKeyUp={(e) => {
														timeError(e.target.value);
													}}
												/>
											</Box>
										</Box>
									</Box>
								</Box>
								<Box mb={1} className={classes.locationBox}>
									<LocationSelector
										handleSelect={handleLocationSelect}
										value={defaultValue && defaultValue.address}
									/>
								</Box>
								<Box>
									<CustomInput
										name="customAddress"
										onChange={(e) => {
											setCustomAddress({
												...customAddress,
												value: e.target.value,
											});
											setForm({ ...form, customAddress: e.target.value });
										}}
										label="Address"
										defaultValue={customAddress.value}
									/>
								</Box>
								<Box className={classes.buttonContainer}>
									<Button
										onClick={() => userLogin()}
										className={classes.button}
										disabled={disabled}
									>
										{loading ? (
											<CircularProgress size={22} style={{ color: "#FFCF00" }} />
										) : (
												<>
													{location.state && location.state.mode === "edit"
														? "Update Appointment"
														: "Book Appointment"}
												</>
											)}
									</Button>
								</Box>
								<Typography className="text-center mt-2">
									Free Inspection. Cancel Anytime
                </Typography>
							</Box>
						</Card>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<CImage imgSrc={rightImg} style={classes.imgMedia} />
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default AppointmentForm;
