import firebase from "../config/Firebase/index";

export const getCarData = async (query = 'cars') => {
	let Brands = {};
	return await firebase
	.database()
	.ref()
	.child(query)
	.get()
	.then(function (data) {
	  if (data.exists()) {
		Brands = data.val();
		return Brands;
	  }
	})
	.catch(function (error) {
		console.error('==error===', error);
	});
  }

  export const getVariantData = async () => {
	let variants = [];
	return await firebase
	.database()
	.ref()
	.child("carVariants")
	.get()
	.then(function (data) {
	  if (data.exists()) {
		variants = extractVariantsData(data.val());
		return variants;
	  }
	})
	.catch(function (error) {
		console.error('==error===', error);
	});
  }
  export const extractVariantsData = (data) => {
	let apps = [];
	for (let key in data) {
		data[key].variantId = key;
		apps.push(data[key]);
	}
	return apps || [];
};
