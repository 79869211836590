import firebase from "../config/Firebase/index";

export const fetchWhatsappUser = async () => {
	let userList = [];
	return await firebase
		.database()
		.ref()
		.child("whatsAppUpdate")
		.get()
		.then(function (data) {
			if (data.exists()) {
				userList = extractWhatsappUser(data.val());
				return userList;
			}
			else {
				console.log("No data available");
			}
		})
		.catch(function (error) {
			console.error(error);
		});
}

export const extractWhatsappUser = (data) => {
	let apps = [];
	for (let key in data) {
		data[key].userId = key;
		apps.push(data[key]);
	}
	return apps || [];
};



export const getWhatsappUsersDetails = async (mobileNo) => {
	let users = [];
	return await firebase.database()
		.ref("whatsAppUpdate")
		.orderByChild("mobile")
		.equalTo(mobileNo)
		.once('value')
		.then((snapshot) => {
			users = extractWhatsappUsersDetails(snapshot.val());
			return users[0];
		});
}

export const extractWhatsappUsersDetails = (data) => {
	let apps = [];
	for (let key in data) {
		data[key].userId = key;
		apps.push(data[key]);
	}
	return apps;
};