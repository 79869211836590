/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
	Box,
	Typography,
	Grid,
	Button,
	Card
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useLocation } from "react-router-dom";
import moment from "moment";

import CalanderIcon from "../assets/images/calendar.svg";
import ClockIcon from "../assets/images/wall-clock.svg";
import CImage from '../atoms/CImage';
import { useHistory } from "react-router-dom";
import locationIcon from '../assets/images/addresslocation.svg'
import rightImg from '../assets/images/mancarimg.svg'
import firebase from '../config/Firebase'
import Header from "../atoms/Header";

const useStyles = makeStyles((theme) => ({
	text: {
		color: "#1B154E ",
		fontSize: 24,
		textAlign: 'center',
		fontWeight: '600',
		'@media(max-width: 768px)': {
			fontSize: 20,
		},
	},
	headingText: {
		color: "#1B154E ",
		marginLeft: 24,
		top: 20,
		margin: 'auto',
		position: 'relative',
		fontSize: 26,
		fontWeight: '600',
		'@media(max-width: 768px)': {
			fontSize: 20,
			marginLeft: 23,

		},
		'&::before': {
			content: '""',
			width: '200px',
			height: '3px',
			background: '#FFD13B',
			position: 'absolute',
			left: 0,
			bottom: '0',
		}
	},
	button: {
		background: "#1B154E",
		color: "#fff",
		borderRadius: 10,

	},
	highlightText: {
		fontSize: 19,
		fontWeight: '500',
		'@media(max-width: 768px)': {
			fontSize: 16,
			fontWeight: '500'
		},

	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'center'
	},
	heading: {
		fontSize: 16,
		color: "#1B154E ",
		fontWeight: '500',
	},
	data: {
		fontSize: 13,
		color: "#1B154E ",
		fontWeight: '400'
	},
	appdate: {
		fontSize: 16,
		color: "#1B154E ",
		fontWeight: '500',
		'@media(max-width: 768px)': {
			fontSize: 16,
		},
	},
	dateContainer: {
		'@media(max-width: 550px)': {
			flexDirection: 'column',
		},
	},
	dateBox: {
		'@media(max-width: 767px)': {
			width: '100% !important',
			paddingBottom: 10
		},
	},
	calender: {
		marginLeft: 20
	},
	imgMedia: {
		width: '100%',
		height: 'auto',
		maxHeight: 445

	},
	card: {
		backgroundColor: "#F8F8F8",
		padding: 24
	},
	locationIcon: {
		height: 17,
		marginLeft: 20
	},
	gridContainer: {
		marginBottom: 30
	},
	formContainer: {
		'@media(max-width: 768px)': {
			padding: '0px',
			paddingBottom: '40px',
		},
		'@media (max-width:800px)': {
			marginBottom: 0,
		},
		'@media (min-width:1680px) and (max-width:3000px) ': {
			marginBottom: 180,
		},
	},
	appointmentSection: {
		padding: '20px',
	},
	rightSideContainer: {
		'@media(max-width: 768px)': {
			marginTop: '10px'

		}
	}
}));

const BookingConfirmation = (props) => {
	const theme = useTheme();
	const classes = useStyles(theme);
	const location = useLocation();
	const history = useHistory();
	const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
	const [data] = useState(location.state && location.state.form)
	const calender = CalanderIcon;
	const clock = ClockIcon;

	useEffect(() => {
		firebase.auth().onAuthStateChanged(
			user => {
				if (!user) {
					history.push('/')
				}
			})
	}, [])

	const manageAppointment = () => {
		history.push('/manage-appointment')
	}

	return (
		<>
			<Header />

			<Box p={5} pl={10} bgcolor="background.paper" mb={5} className={classes.formContainer}>
				{location.state && location.state.form ?
					<>
						<Box mb={2}>
							<Typography variant="h6" className={classes.headingText}>Appointment Confirmation</Typography>
						</Box>
						<Grid container className={classes.appointmentSection} spacing={isSmall ? 0 : 4}>
							<Grid item xs={12} sm={12} md={6}>
								<Card className={classes.card}>
									<Box mb={2}>
										<Typography variant="h6" className={classes.text}>
											Congratulations!!
								<Typography className={classes.highlightText}>
												Your car's inspection {location.state && location.state.mode === "edit" ? "updated" : "is scheduled"} successfully
								</Typography>
										</Typography>
									</Box>
									<Box mb={2}>
										<Typography className={classes.heading}>Name</Typography>

										<Typography className={classes.data}>
											{`${data?.firstName} ${data?.lastName}`}
										</Typography>

									</Box>
									<Box mb={2}>
										<Typography className={classes.heading}>Mobile</Typography>
										<Typography className={classes.data}>{data && data.mobile}</Typography>
									</Box>
									{
										data && data.email &&
										<Box mb={2}>
											<Typography className={classes.heading}>Email</Typography>
											<Typography className={classes.data}>{data && data.email}</Typography>
										</Box>
									}
									<Box mb={.5}>
										<Typography className={classes.appdate}>Appointment Date & Time</Typography>
									</Box>
									<Box mb={2} display='flex' width="100%" className={classes.dateContainer}>
										<Box display='flex' width="45%" justifyContent="space-between" className={classes.dateBox}>
											<Box  >
												<Typography className={classes.data}>{moment(data && data.appointmentDate).format("dddd MMM Do YYYY")}</Typography>
											</Box>
											<Box>
												<CImage
													style={classes.calender}
													imgSrc={calender}
												/>

											</Box>
										</Box>
										<Box width='10%' />
										<Box display='flex' width="45%" justifyContent="space-between" className={classes.dateBox}>
											<Box >
												<Typography className={classes.data}>{data.appointmentTime}</Typography>
											</Box>
											<Box>
												<CImage
													imgSrc={clock}
												/>
											</Box>
										</Box>
									</Box>
									{
										data && data.address ?
											<Box mb={2}>
												<Typography className={classes.heading}>Location</Typography>
												<Box width='100%' display='flex' justifyContent="space-between">
													<Box>
														<Typography className={classes.data}>{data && data.address}</Typography>
													</Box>
													<Box>
														<CImage
															imgSrc={locationIcon}
															style={classes.locationIcon}
														/>
													</Box>
												</Box>
											</Box> : null
									}
									{
										data && data.customAddress ?
											<Box mb={2}>
												<Typography className={classes.heading}>Custom Address</Typography>
												<Box>
													<Typography className={classes.locationtext}>
														{data.customAddress}
													</Typography>
												</Box>
											</Box>
											: null
									}
									<Box className={classes.buttonContainer} mt={1}>
										<Button className={classes.button} onClick={manageAppointment}>
											Manage Appointment
										</Button>
									</Box>
								</Card>
							</Grid>
							<Grid item xs={12} sm={12} md={6} className={classes.rightSideContainer}>
								<CImage
									imgSrc={rightImg}
									style={classes.imgMedia}
								/>
							</Grid>
						</Grid>
					</> : (<Box mb={11}><Typography>Please enter car Details</Typography></Box>)}
			</Box>
		</>

	)
}

export default BookingConfirmation