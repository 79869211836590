import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore

const config = {
  apiKey: "AIzaSyB7WqRiffopbA_naASOw0TdsSulYP1LMb4",
  authDomain: "car-boli.firebaseapp.com",
  databaseURL: "https://car-boli-default-rtdb.firebaseio.com",
  projectId: "car-boli",
  storageBucket: "car-boli.appspot.com",
  messagingSenderId: "102348854230",
  appId: "1:102348854230:web:efb4d4eb12d534fe988a77",
  measurementId: "G-9NTV6MMESP"
};
firebase.initializeApp(config);
firebase.auth().setPersistence(
  firebase.auth.Auth.Persistence.LOCAL
);
firebase.firestore().enablePersistence();

export default firebase;