import firebase from "../config/Firebase/index";

export const fetchNewsLetters = async () => {
	let newsList = [];
	return await firebase
		.database()
		.ref()
		.child("newsLetters")
		.get()
		.then(function (data) {
			if (data.exists()) {
				newsList = extractNewsLetters(data.val());
				return newsList;
			}
			else {
				console.log("No data available");
			}
		})
		.catch(function (error) {
			console.error(error);
		});
}

export const extractNewsLetters = (data) => {
	let apps = [];
	for (let key in data) {
		data[key].newsId = key;
		apps.push(data[key]);
	}
	return apps || [];
};